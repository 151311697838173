export enum AirErrorKind {
    Authenticate = "Authenticate",
    SignIn = "Sign in",
    SignOut = "Sign out",
    ValidateEvent = "Validate event",
    ConfigureEvent = "Configure event",
    FetchCognitiveMetadata = "Fetch cognitive metadata",
    FetchIndexedLocations = "Fetch indexed locations",
    FetchTagsGroups = "Fetch tags group",
    FetchTagsGroupById = "Fetch tags group by ID",
    AddTagsGroup = "Add tags group",
    AddOrUpdateTag = "Update tag",
    MergeTags = "Merge tags",
    FetchCollections = "Fetch collections",
    FetchCollectionById = "Fetch collection by ID",
    CreateCollection = "Create collection",
    CreateServiceAccount = "Create service account",
    AddToCollection = "Add to collection",
    EditCollection = "Edit collection",
    EditServiceAccount = "Edit service account",
    EditDownloadLocations = "Edit download location",
    DeleteCollection = "Delete collection",
    DeleteServiceAccount = "Delete service account",
    DeleteFromCollection = "Delete from collection",
    SearchUser = "Search user",
    PrepareFilesToShow = "Prepare files to show",
    FetchFiles = "Fetch files",
    FetchFilesByPath = "Fetch files by path",
    FetchSubscriptions = "Fetch subscriptions",
    FetchStorageAccount = "Fetch storage account",
    FetchStorageAccounts = "Fetch storage accounts",
    FetchDownloadLocations = "Fetch download locations",
    FetchDownloadLocationsAzure = "Fetch Azure download locations",
    FetchDownloadLocationAzure = "Fetch Azure download location",
    EditDownloadLocationAzure = "Edit Azure download location",
    AddDownloadLocationAzure = "Add Azure download location",
    SortFiles = "Sort files",
    SortSearchedFiles = "Sort searched files",
    GetSharingLink = "Get sharing link",
    GetItemPermissions = "Get item permissions",
    GetTagGroups = "Get tag groups",
    GetTagsGroups = "Get tags group",
    UpdateOrderTagsGroups = "Update order tags groups",
    EditTagsGroup = "Edit tags group",
    GetIndexedLocation = "Get indexed location",
    RePermission = "Re-permission",
    DownloadFiles = "Download files",
    DownloadFilesToDesktop = "Download files to desktop",
    ChangeTier = "Change tier",
    EditTags = "Edit tags",
    EditBatchTags = "Edit batch tags",
    EditIndexedLocation = "Edit indexed location",
    AddIndexedLocation = "Add indexed location",
    FetchFacets = "Fetch facets",
    FetchLatestJobs = "Fetch latest jobs",
    FetchAllJobs = "Fetch all jobs",
    ViewJobs = "View jobs",
    DownloadByLink = "Download files to desktop by link",
    Search = "Search",
    SearchTags = "Search tags",
    CheckAccess = "Check access",
    Authorization = "Authorization",
    Details = "Details",
    FetchConfig = "Fetch config",
    FetchBrandigConfig = "Fetch brandig config",
    Copy = "Copy to clipboard",
    FetchLocations = "Fetch upload locations",
    FetchUploaderFeatures = "Fetch uploader features",
    AddData = "Add data",
    Uploading = "Uploading",
    GetConfig = "Get config",
    FetchAirArchiveConfig = "Fetch air archive config",
    UpdateAirArchiveConfig = "Update air archive config",
    AddAdminToken = "Add admin token",
    ListAdminTokens = "List admin tokens",
    ViewAdminTokens = "View admin token",
    RemoveAdminTokens = "Remove admin tokens",
    CloneAdminTokens = "Clone admin tokens",
    FilesGetTiers = "Check files tier",

    // sharing
    SharingCreateErr = "Share",
    SharingGetSharingDetailsErr = "Get sharing details",
    SharingGetFilesErr = "Get files",
    SharingGetDownloadLinkErr = "Get download link",
    SharingGetFileDetailsErr = "Get file details",
    // preview
    GetPreview = "Get preview",
    UpdatePreview = "Update preview",
    SharingUpdateSettings = "Update settings",

    // admin
    AdminUpdateConfig = "Update config",
    AdminUpdateShareLinkUIConfig = "Update share link UI config",
    AdminUpdateBrandingConfig = "Update brandig config",
}

export interface AirErrorInfo {
    message: string;
    date: Date;
}

export class AirError {
    private readonly _errorKind: AirErrorKind;
    private readonly _info: AirErrorInfo;

    constructor(errorKind: AirErrorKind, info: AirErrorInfo) {
        this._errorKind = errorKind;
        this._info = info;
    }

    get errorKind(): AirErrorKind {
        return this._errorKind;
    }

    get info(): AirErrorInfo {
        return this._info;
    }

    get message(): string {
        return this._info.message;
    }

    toString(): string {
        return `${this._errorKind}: ${this._info.message}`;
    }
}
