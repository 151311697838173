import React, { useEffect } from "react";
import { Settings32Regular } from "@fluentui/react-icons";
import {
    makeStyles,
    tokens,
    shorthands,
    mergeClasses,
    Link,
} from "@fluentui/react-components";
import IndexedLocations from "./IndexedLocations/IndexedLocations";
import DownloadLocations from "./DownloadLocations/DownloadLocations";
import Branding from "./Branding/Branding";
import Tags from "./Tags/Tags";
import { state } from "../../../state/stateAdapter";

import "./admin.scss";
import { useStorage } from "../../../../application/useCases/useStorage";
import { AdminActiveMenuKind } from "../../../models";
import { router } from "../../../router";
import { adminRouteListener } from "./adminRouteListener";
import { adminManager } from "./adminManager";
import UploaderSettings from "./UploaderSettings/UploaderSettings";
import AirArchive from "./AirArchive/AirArchive";
import Preview from "./Preview/Preview";
// import PublicAPI from "./PublicAPI/PublicAPI";
import Sharing from "./Sharing/Sharing";

export const useStyles = makeStyles({
    admin: {
        backgroundColor: tokens.colorNeutralBackground3,
        ...shorthands.padding(0),
    },
    headerIcon: {
        backgroundColor: tokens.colorBrandBackground,
        color: tokens.colorBrandBackgroundInverted,
    },
    nav: {
        ...shorthands.borderRight("1px", "solid", tokens.colorNeutralStroke1),
        paddingTop: "20px",
    },
    navLinkActive: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    navLink: {
        display: "block",
        textDecorationLine: "none",
        backgroundColor: tokens.colorNeutralBackground3,
        ...shorthands.borderRadius("4px"),
        ...shorthands.padding("6px"),
        ...shorthands.margin(0, "5px"),
        "&:hover": {
            textDecorationLine: "none",
            backgroundColor: tokens.colorNeutralBackground2,
        },
        "&:active": {
            textDecorationLine: "none",
        },
    },
});

const navigationList = [
    {
        id: AdminActiveMenuKind.Branding,
        name: "Branding",
    },
    {
        id: AdminActiveMenuKind.IndexedLocations,
        name: "Indexed Locations",
    },
    {
        id: AdminActiveMenuKind.DownloadLoactions,
        name: "Download Locations",
    },
    {
        id: AdminActiveMenuKind.Tags,
        name: "Tags",
    },
    {
        id: AdminActiveMenuKind.Uploader,
        name: "Uploader",
    },
    {
        id: AdminActiveMenuKind.AirArchive,
        name: "Extract archives",
    },
    // {
    //     id: AdminActiveMenuKind.PublicAPI,
    //     name: "Public API",
    // },
    {
        id: AdminActiveMenuKind.Preview,
        name: "File preview encoder",
    },
    {
        id: AdminActiveMenuKind.Sharing,
        name: "Sharing",
    },
];

const navigationListIds = navigationList.map(i => i.id);

export function getStatusField(
    name: string,
    validation: Map<string, boolean | undefined>,
): "success" | "warning" | "none" | "error" | undefined {
    return validation.get(name) !== undefined
        ? validation.get(name)
            ? "success"
            : "error"
        : "none";
}

function Admin(): JSX.Element {
    const activeMenu = state.useState(useStorage.adminMiniApp.activeMenu);
    const classes = useStyles();

    useEffect(() => {
        state.commandBar.actionsFactory.set(() => (
            <header className="admin__header">
                <Settings32Regular
                    className={mergeClasses(
                        classes.headerIcon,
                        "admin__header-icon",
                    )}
                />
                <span className="admin__header-title">Settings</span>
            </header>
        ));

        return () => {
            state.commandBar.actionsFactory.set(null);
        };
    }, [classes.headerIcon]);

    useEffect(() => {
        router.routeListenersManager.add(adminRouteListener);
        adminManager.init().then(() => {
            useStorage.adminMiniApp.isStateInit.set(true);
        });

        return () => {
            router.routeListenersManager.remove(adminRouteListener);
            state.commandBar.actionsFactory.set(null);
            useStorage.adminMiniApp.isStateInit.set(false);
        };
    }, []);

    useEffect(() => {
        if (!navigationListIds.includes(activeMenu)) {
            const href = router.createRoute({
                search: {
                    items: [
                        {
                            key: "activeMenu",
                            value: AdminActiveMenuKind.Branding,
                        },
                    ],
                    protect: [],
                },
            });

            router.navigateToHref(href);
            useStorage.adminMiniApp.activeMenu.set(
                AdminActiveMenuKind.Branding,
            );
        }
    }, [activeMenu]);

    return (
        <div className={mergeClasses(classes.admin, "admin")}>
            <nav className={classes.nav}>
                {navigationList.map(i => (
                    <Link
                        key={i.id}
                        as="a"
                        href={router.createRoute({
                            search: {
                                items: [
                                    {
                                        key: "activeMenu",
                                        value: i.id,
                                    },
                                ],
                                protect: [],
                            },
                        })}
                        appearance="subtle"
                        data-route
                        className={mergeClasses(
                            classes.navLink,
                            activeMenu === i.id ? classes.navLinkActive : "",
                        )}
                    >
                        {i.name}
                    </Link>
                ))}
            </nav>

            <main className="admin__main">
                {activeMenu === AdminActiveMenuKind.Branding ? (
                    <Branding />
                ) : null}
                {activeMenu === AdminActiveMenuKind.IndexedLocations ? (
                    <IndexedLocations />
                ) : null}
                {activeMenu === AdminActiveMenuKind.DownloadLoactions ? (
                    <DownloadLocations />
                ) : null}
                {activeMenu === AdminActiveMenuKind.Tags ? <Tags /> : null}
                {activeMenu === AdminActiveMenuKind.Uploader ? (
                    <UploaderSettings />
                ) : null}
                {activeMenu === AdminActiveMenuKind.AirArchive ? (
                    <AirArchive />
                ) : null}
                {/* {activeMenu === AdminActiveMenuKind.PublicAPI ? (
                    <PublicAPI />
                ) : null} */}
                {activeMenu === AdminActiveMenuKind.Preview ? (
                    <Preview />
                ) : null}
                {activeMenu === AdminActiveMenuKind.Sharing ? (
                    <Sharing />
                ) : null}
            </main>
        </div>
    );
}

export default Admin;
