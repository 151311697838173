import {
    AppearanceInsight,
    KeyFrameInsight,
} from "../../../application/cognitiveMetadata/cognitiveMetadataPort";
import { RefObject } from "react";

export enum VideoExtendedViewTabKind {
    Insights,
    Timeline,
}

export type ExtendedViewData = {
    value: string;
    description: string | null;
    appearances: AppearanceInsight[];
    url?: string | null;
    image?: string | null;
    title?: string | null;
    tags?: string[] | null;
    keyFrames?: KeyFrameInsight[] | null;
};

export type ExtendedViewSectionParams = {
    duration: number;
    title: string;
    videoRef: RefObject<HTMLVideoElement>;
    data?: ExtendedViewData[];
};
