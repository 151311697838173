import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
    Badge,
    Card,
    Field,
    Text,
    Input,
    InputOnChangeData,
    Select,
    SelectOnChangeData,
    makeStyles,
    Button,
    SkeletonItem,
    Switch,
    Tooltip,
} from "@fluentui/react-components";
import {
    TextBulletListSquareEditRegular,
    ClipboardTextEditFilled,
    bundleIcon,
    SaveRegular,
    DismissFilled,
    CheckmarkFilled,
} from "@fluentui/react-icons";
import SelectUsersField from "../../../../../components/Form/SelectUsersField/SelectUsersField";
import AdminLayout from "../../AdminLayout/AdminLayout";
import { useCommonFluentuiStyles } from "../../../../../styles/griffel";
import { useIndexedLocation } from "../../../../../../application/useCases/useIndexedLocation";
import { state } from "../../../../../state/stateAdapter";
import { useStorage } from "../../../../../../application/useCases/useStorage";
import Loader from "../../../../../components/Loader/Loader";
import { router } from "../../../../../router";
import { AdminActiveMenuKind } from "../../../../../models";
import { protectedDataParamsKeys } from "../../../../../../domain/dataParams/dataParams";
import {
    IndexedLocation,
    IndexedLocationPermission,
} from "../../../../../../application/indexedLocation/indexedLocation";
import { IndexedLocationValidateEventRequest } from "../../../../../../application/indexedLocation/indexedLocationPort";
import { validateExistedIndexedLocation } from "../validateIndexedLocation";
import { enableActiveContentIndexingHint } from "../shared";

const useStyles = makeStyles({
    status: {
        position: "relative",
        display: "grid",
        columnGap: "16px",
        gridTemplateColumns: "24px auto 96px",
        alignItems: "center",
        minHeight: "32px",
    },
});

export const EditIcon = bundleIcon(
    ClipboardTextEditFilled,
    TextBulletListSquareEditRegular,
);

function isEqual(initData?: IndexedLocation, data?: IndexedLocation): boolean {
    if (data === undefined && initData === undefined) {
        return true;
    }
    if (data === undefined || initData === undefined) {
        return false;
    }

    return JSON.stringify(data) === JSON.stringify(initData);
}

function IndexedLocationEdit(): JSX.Element {
    const commonClasses = useCommonFluentuiStyles();
    const classes = useStyles();
    const id = state.useState(useStorage.adminMiniApp.id);
    const [isQueryInProgress, setIsQueryInProgress] = useState<boolean>(false);
    const [isValidateInProgress, setIsValidateInProgress] =
        useState<boolean>(false);
    const [isConfigureInProgress, setIsConfigureInProgress] =
        useState<boolean>(false);
    const [data, setData] = useState<IndexedLocation>();
    const [status, setStatus] = useState<IndexedLocationValidateEventRequest>();
    const [initData, setInitData] = useState<IndexedLocation>();
    const [formKey, setFormKey] = useState<number>(0);
    const isFormValid = validateExistedIndexedLocation(data);

    const onChange = useCallback(
        (
            e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
            d: InputOnChangeData | SelectOnChangeData,
        ): void => {
            const name = e.target.name as keyof IndexedLocation;

            if (data !== undefined && data[name] !== d.value) {
                setData({ ...data, [name]: d.value });
            }
        },
        [data],
    );

    const updateData = useCallback(
        (name: string, value: string | number | undefined | boolean): void => {
            if (
                data !== undefined &&
                data[name as keyof IndexedLocation] !== value
            ) {
                setData({ ...data, [name]: value });
            }
        },
        [data],
    );

    const updatePermission = useCallback(
        (name: string, users: IndexedLocationPermission[]): void => {
            if (data !== undefined && data.permissions !== undefined) {
                setData({
                    ...data,
                    permissions: {
                        ...data.permissions,
                        [name]: users,
                    },
                });
            }
        },
        [data],
    );

    const validateEvent = useCallback(async (): Promise<void> => {
        if (id) {
            setIsValidateInProgress(true);
            const res = await useIndexedLocation.validateEvent({
                locationID: parseInt(id),
            });

            if (res !== null && res !== undefined) {
                setStatus({ ...res });
            } else {
                setStatus({
                    isValid: false,
                    error: "Validation error",
                });
            }
            setIsValidateInProgress(false);
        }
    }, [id]);

    const configureEvents = useCallback(async (): Promise<void> => {
        if (!status?.isValid && id !== undefined && id !== null) {
            setIsConfigureInProgress(true);
            const res = await useIndexedLocation.configureEvent({
                locationID: parseInt(id),
            });

            if (res.status === 200) {
                setStatus({
                    isValid: true,
                    error: null,
                });
            } else {
                let message = "Something went wrong ";

                if (res.status === 403) {
                    message = "You don't have permission in Azure";
                }

                if (res.status === 404) {
                    message = "Event grid not found";
                }

                setStatus({
                    isValid: false,
                    error: message,
                });
            }

            setIsConfigureInProgress(false);
        }
    }, [id, status?.isValid]);

    const saveChanges = useCallback(async (): Promise<void> => {
        if (isFormValid && data !== undefined) {
            try {
                setIsQueryInProgress(true);
                await useIndexedLocation.editIndexedLocation(data);
                router.goTo({
                    search: {
                        items: [
                            {
                                key: "activeMenu",
                                value: AdminActiveMenuKind.IndexedLocations,
                            },
                        ],
                        protect: [...protectedDataParamsKeys],
                    },
                });
            } catch (error) {
                setIsQueryInProgress(false);
            }
        }
    }, [data, isFormValid]);

    const discardChanges = useCallback(async (): Promise<void> => {
        if (initData !== undefined) {
            setData({ ...initData });
            setFormKey(formKey + 1);
        }
    }, [formKey, initData]);

    useEffect(() => {
        if (id && data === undefined) {
            setIsQueryInProgress(true);
            useIndexedLocation
                .getIndexedLocation({
                    locationID: parseInt(id),
                })
                .then(res => {
                    if (res !== null && res !== undefined) {
                        setData({ ...res });
                        setInitData({ ...res });
                        setIsQueryInProgress(false);
                    }
                });

            validateEvent();
        }
    }, [id, data, validateEvent]);

    if (data === undefined || isQueryInProgress) {
        return (
            <div className="admin__loader">
                <Loader text="Fetching indexed location..." />
            </div>
        );
    }

    return (
        <AdminLayout
            title={data.title}
            backLink={{
                href: router.createRoute({
                    search: {
                        items: [
                            {
                                key: "activeMenu",
                                value: AdminActiveMenuKind.IndexedLocations,
                            },
                        ],
                        protect: [...protectedDataParamsKeys],
                    },
                }),
                title: "Back to Indexed Locations",
            }}
            buttons={[
                {
                    title: "Save changes",
                    onClick: saveChanges,
                    icon: <SaveRegular />,
                    appearance: "primary",
                    disabled: !isFormValid || isEqual(initData, data),
                },
                {
                    title: "Discard changes",
                    onClick: discardChanges,
                    appearance: "subtle",
                    disabled: isEqual(initData, data),
                },
                {
                    title: "Validate",
                    onClick: validateEvent,
                    appearance: "subtle",
                    disabled: isConfigureInProgress || isValidateInProgress,
                },
            ]}
        >
            <form className="admin__form" key={formKey}>
                {status && (
                    <Card orientation="horizontal">
                        <div className={classes.status}>
                            {isConfigureInProgress || isValidateInProgress ? (
                                <>
                                    <SkeletonItem shape="circle" size={24} />
                                    <SkeletonItem size={24} />
                                    <SkeletonItem size={24} />
                                </>
                            ) : (
                                <>
                                    {status.isValid ? (
                                        <Badge
                                            color="success"
                                            shape="circular"
                                            icon={<CheckmarkFilled />}
                                        />
                                    ) : (
                                        <Badge
                                            color="danger"
                                            shape="circular"
                                            icon={<DismissFilled />}
                                        />
                                    )}
                                    <Text weight="semibold">
                                        {status.error !== null
                                            ? status.error
                                            : "This location setup is fully completed"}
                                    </Text>

                                    {!status.isValid ? (
                                        <Button
                                            onClick={configureEvents}
                                            disabled={isConfigureInProgress}
                                        >
                                            Configure
                                        </Button>
                                    ) : null}
                                </>
                            )}
                        </div>
                    </Card>
                )}
                <Tooltip
                    content={enableActiveContentIndexingHint}
                    relationship="description"
                >
                    <Switch
                        className={commonClasses.field}
                        checked={data.isIndexingEnabled}
                        onChange={(_e, data): void => {
                            updateData("isIndexingEnabled", data.checked);
                        }}
                        label={`Enable active content indexing`}
                    />
                </Tooltip>
                <Field
                    label="Location name"
                    required
                    className={commonClasses.field}
                >
                    <Input
                        placeholder="Location name"
                        size="large"
                        name="title"
                        value={data?.title}
                        type="text"
                        onChange={onChange}
                        autoComplete="off"
                    />
                </Field>

                <Field label="Subscription" className={commonClasses.field}>
                    <Select
                        size="large"
                        name="subscription"
                        value={data?.subscription ?? ""}
                        disabled
                    >
                        <option>{data?.subscription}</option>
                    </Select>
                </Field>

                <Field label="Storage account" className={commonClasses.field}>
                    <Select
                        size="large"
                        name="storageName"
                        value={data?.storageName}
                        placeholder="Select storage account"
                        disabled
                    >
                        <option>{data?.storageName}</option>
                    </Select>
                </Field>

                <Field label="Access key" className={commonClasses.field}>
                    <Input
                        placeholder="Acsess key"
                        size="large"
                        name="acsessKey"
                        value={data?.accessKey}
                        type="text"
                        disabled
                    />
                </Field>

                <Field label="Container name" className={commonClasses.field}>
                    <Select
                        size="large"
                        name="containerName"
                        placeholder="Container name"
                        disabled
                        value={data?.containerName}
                    >
                        <option>{data?.containerName}</option>
                    </Select>
                </Field>

                <Field
                    label="Permissions for Search"
                    className={commonClasses.field}
                >
                    <SelectUsersField
                        data={data?.permissions.search}
                        onChange={(users): void => {
                            updatePermission("search", users);
                        }}
                    />
                </Field>

                {/* <Field
                    label="Permissions for Download"
                    className={commonClasses.field}
                >
                    <SelectUsersField
                        data={data?.permissions.download}
                        onChange={(users): void => {
                            updatePermission("download", users);
                        }}
                    />
                </Field> */}

                <Field
                    label="Full control  / Re-permission"
                    className={commonClasses.field}
                >
                    <SelectUsersField
                        data={data.permissions.rePermission}
                        onChange={(users): void => {
                            updatePermission("rePermission", users);
                        }}
                    />
                </Field>

                <Field
                    label="Change storage tier"
                    className={commonClasses.field}
                >
                    <SelectUsersField
                        data={data.permissions.changeStorageTier}
                        onChange={(users): void => {
                            updatePermission("changeStorageTier", users);
                        }}
                    />
                </Field>

                <Field label="Upload" className={commonClasses.field}>
                    <SelectUsersField
                        data={data.permissions.upload}
                        onChange={(users): void => {
                            updatePermission("upload", users);
                        }}
                    />
                </Field>

                <Field label="Delete" className={commonClasses.field}>
                    <SelectUsersField
                        data={data.permissions.delete}
                        onChange={(users): void => {
                            updatePermission("delete", users);
                        }}
                    />
                </Field>
            </form>
        </AdminLayout>
    );
}

export default IndexedLocationEdit;
