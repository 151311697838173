import React, { useState, RefObject, useCallback, useMemo } from "react";
import {
    Button,
    Tooltip,
    makeStyles,
    mergeClasses,
    shorthands,
    tokens,
} from "@fluentui/react-components";
import { Previous16Regular, Next16Regular } from "@fluentui/react-icons";
import {
    getSeconds,
    onJumpToTime,
    formatSeconds,
    useCurrentTime,
} from "../utils";
import "./seekBar.css";

const useStyles = makeStyles({
    timeline: {
        backgroundColor: tokens.colorNeutralStencil1Alpha,
        ":hover .seek-bar__tooltip:after": {
            ...shorthands.borderColor(
                tokens.colorNeutralBackground1,
                "transparent",
                "transparent",
                "transparent",
            ),
        },
    },
    time: {
        backgroundColor: tokens.colorNeutralStrokeAccessible,
    },
});

type SeekBarProps = {
    videoRef: RefObject<HTMLVideoElement>;
    appearances: {
        startTime: string;
        endTime: string;
        color?: string;
    }[];
    duration: number;
};

function getWidth(
    duration: number,
    startTime: string,
    endTime: string,
): string {
    return `${Math.max(
        (100 * (getSeconds(endTime) - getSeconds(startTime))) / duration,
        0.1,
    )}%`;
}

function SeekBar(props: SeekBarProps): JSX.Element {
    const classes = useStyles();
    const [ref, setRef] = React.useState<HTMLElement | null>();
    const [showCurrentTime, setShowCurrentTime] = useState<boolean>(false);
    const [currentIndex, setCurentIndex] = useState<number>(0);
    const [seekTime, setSeekTime] = useState<number>(0);
    const currentTime = useCurrentTime(props.videoRef, showCurrentTime);
    const timeTips = useMemo(
        () =>
            props.appearances.map(i =>
                parseFloat(getSeconds(i.startTime).toFixed(6)),
            ),
        [props.appearances],
    );

    const appearanceStyles = useMemo(
        () =>
            props.appearances.map(appearance => ({
                width: getWidth(
                    props.duration,
                    appearance.startTime,
                    appearance.endTime,
                ),
                left: `${
                    (getSeconds(appearance.startTime) * 100) / props.duration
                }%`,
                backgroundColor: appearance.color,
            })),
        [props.appearances, props.duration],
    );

    const onPlayNext = useCallback(
        (e: React.MouseEvent<HTMLElement>): void => {
            let index = 0;
            if (timeTips[timeTips.length - 1] > currentTime) {
                index =
                    timeTips.findIndex(
                        i => i !== currentIndex && i > currentTime,
                    ) || 0;
            }
            onJumpToTime(
                e,
                parseFloat(timeTips[index].toFixed(6)),
                props.videoRef,
            );
            setCurentIndex(index);
        },
        [currentIndex, currentTime, props.videoRef, timeTips],
    );

    const onPlayPrev = useCallback(
        (e: React.MouseEvent<HTMLElement>): void => {
            let index = timeTips.length - 1;
            for (let i = 0; i <= timeTips.length; i++) {
                if (timeTips[i] <= currentTime) {
                    if (timeTips[i - 1] !== undefined) {
                        index = i - 1;
                    }
                }
            }
            onJumpToTime(
                e,
                parseFloat(timeTips[index].toFixed(6)),
                props.videoRef,
            );
            setCurentIndex(index);
        },
        [currentTime, props.videoRef, timeTips],
    );

    const showTime = useCallback(
        (e: React.MouseEvent<HTMLElement>): void => {
            const left =
                Math.floor(
                    e.pageX - e.currentTarget.getBoundingClientRect().x,
                ) + 1;

            setSeekTime((props.duration / e.currentTarget.clientWidth) * left);
        },
        [props.duration],
    );

    const onClickTimeLine = useCallback(
        (e: React.MouseEvent<HTMLElement>): void => {
            onJumpToTime(e, seekTime, props.videoRef);
        },
        [props.videoRef, seekTime],
    );

    return (
        <div
            className="seek-bar"
            onFocus={(): void => setShowCurrentTime(true)}
            onMouseEnter={(): void => setShowCurrentTime(true)}
            onBlur={(): void => setShowCurrentTime(false)}
            onMouseLeave={(): void => setShowCurrentTime(false)}
        >
            <Tooltip
                withArrow
                mountNode={ref}
                content={formatSeconds(seekTime)}
                relationship="label"
                positioning={{ target: ref }}
            >
                <div>
                    <div
                        role="presentation"
                        className={mergeClasses(
                            classes.timeline,
                            "seek-bar__timeline",
                        )}
                        onMouseMove={showTime}
                        onClick={onClickTimeLine}
                    >
                        <div
                            ref={setRef}
                            className="seek-bar__tooltip"
                            style={{
                                left: `${(seekTime * 100) / props.duration}%`,
                            }}
                        ></div>
                        {appearanceStyles.map((style, index) => (
                            <div
                                key={index}
                                role="presentation"
                                className={mergeClasses(
                                    classes.time,
                                    "seek-bar__time",
                                )}
                                style={style}
                            />
                        ))}
                    </div>
                    <div
                        className="seek-bar__progress"
                        style={{
                            width: `${(currentTime * 100) / props.duration}%`,
                        }}
                    />
                </div>
            </Tooltip>
            <div className="seek-bar__btns">
                <Button
                    size="small"
                    appearance="transparent"
                    onClick={onPlayPrev}
                    icon={<Previous16Regular />}
                >
                    Play previous
                </Button>
                <Button
                    size="small"
                    appearance="transparent"
                    onClick={onPlayNext}
                    icon={<Next16Regular />}
                >
                    Play next
                </Button>
            </div>
        </div>
    );
}

export default SeekBar;
