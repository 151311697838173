import React, { Fragment } from "react";
import {
    Link,
    Text,
    Menu,
    MenuTrigger,
    MenuPopover,
    MenuList,
    MenuItem,
    Button,
    Overflow,
    OverflowItem,
    useIsOverflowItemVisible,
    useOverflowMenu,
} from "@fluentui/react-components";
import { MoreHorizontal20Filled } from "@fluentui/react-icons";
import { router } from "../../router";
import { protectedDataParamsKeys } from "../../../domain/dataParams/dataParams";
import {
    BreadcrumbsSeparator,
    OverflowMenuDivider,
    useBreadcrumbsSharedStyles,
} from "./breadcrumbsShared";

type FilesHierarchy = {
    hierarchy: string;
    title: string;
};

type BreadcrumbsProps = {
    paramKey: string;
    filesHierarchy: FilesHierarchy[];
    disabled: boolean;
};

export type BreadcrumbsItem = {
    id: string;
    name: string;
    path: string;
    disabled?: boolean;
    active?: boolean;
};

function makeBredcrumbsFromPath(
    filesHierarchy: FilesHierarchy[],
    disabled: boolean,
): BreadcrumbsItem[] {
    let items: BreadcrumbsItem[] = [];

    if (filesHierarchy.length === 0) {
        items = [];
    } else {
        items.push({
            name: "Root",
            path: "",
            disabled: disabled,
            id: "root",
        });
        filesHierarchy.forEach((i, index) => {
            items.push({
                name: i.title,
                path: i.hierarchy,
                disabled: disabled,
                id: i.hierarchy + index,
            });
        });
    }

    return items;
}

function HierarchyBreadcrumbs(props: BreadcrumbsProps): JSX.Element {
    const classes = useBreadcrumbsSharedStyles();
    const items = makeBredcrumbsFromPath(props.filesHierarchy, props.disabled);

    return (
        <Overflow padding={40}>
            <div>
                {items.map((i, index) => {
                    const isLast =
                        items.length > 0 && index === items.length - 1;
                    return (
                        <OverflowItem key={i.id} id={i.id} groupId={i.id}>
                            <span className={classes.item}>
                                <BreadcrumbLink
                                    {...i}
                                    active={isLast}
                                    paramKey={props.paramKey}
                                />
                                {!isLast && (
                                    <BreadcrumbsSeparator
                                        disabled={props.disabled}
                                    />
                                )}
                            </span>
                        </OverflowItem>
                    );
                })}
                <OverflowMenu items={items} paramKey={props.paramKey} />
            </div>
        </Overflow>
    );
}

const OverflowMenu: React.FC<{
    items: BreadcrumbsItem[];
    paramKey: string;
}> = props => {
    const { ref, isOverflowing } = useOverflowMenu<HTMLButtonElement>();

    if (!isOverflowing) {
        return null;
    }

    return (
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                <Button
                    ref={ref}
                    icon={<MoreHorizontal20Filled />}
                    aria-label="More items"
                    appearance="subtle"
                />
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    {props.items.map((item, index) => {
                        const isLast =
                            props.items.length > 0 &&
                            index === props.items.length - 1;

                        return (
                            <Fragment key={item.id}>
                                <OverflowMenuItem
                                    id={item.id}
                                    item={item}
                                    paramKey={props.paramKey}
                                />
                                {!isLast && (
                                    <OverflowMenuDivider id={item.id} />
                                )}
                            </Fragment>
                        );
                    })}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};

type BreadcrumbLinkProps = BreadcrumbsItem & { paramKey: string };

function BreadcrumbLink(props: BreadcrumbLinkProps): JSX.Element {
    const classes = useBreadcrumbsSharedStyles();

    if (props.active) {
        return <Text weight="bold">{props.name}</Text>;
    }

    return (
        <Link
            as="a"
            href={router.createRoute({
                search: {
                    items: [
                        {
                            key: props.paramKey,
                            value: props.path,
                        },
                    ],
                    protect: [...protectedDataParamsKeys, "viewMode"],
                },
            })}
            appearance="subtle"
            className={classes.link}
            data-route
            disabled={props.disabled}
        >
            {props.name}
        </Link>
    );
}

const OverflowMenuItem: React.FC<{
    id: string;
    item: BreadcrumbsItem;
    paramKey: string;
}> = props => {
    const { id } = props;
    const isVisible = useIsOverflowItemVisible(id);

    if (isVisible) {
        return null;
    }

    return (
        <MenuItem>
            <BreadcrumbLink {...props.item} paramKey={props.paramKey} />
        </MenuItem>
    );
};

export default HierarchyBreadcrumbs;
