import {
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Text,
    Input,
    Label,
    Option,
    Combobox,
    ComboboxProps,
} from "@fluentui/react-components";
import React, { useCallback, useEffect, useState } from "react";
import {
    AirFile,
    isFile,
    sanitizeFilename,
} from "../../../../../domain/airFile/airFile";
import "./downloadDialog.css";
import { useSharepoint } from "../../../../../application/useCases/useSharepoint";
import { state } from "../../../../state/stateAdapter";
import { useStorage } from "../../../../../application/useCases/useStorage";
import Loader from "../../../../components/Loader/Loader";
import {
    SharepointMainData,
    SharepointSiteDocumentLibrary,
} from "../../../../../application/sharepoint/sharepointPort";
import { useJobs } from "../../../../../application/useCases/useJobs";
import { DownloadLocation } from "../../../../../domain/downloadLocation/downloadLocation";
import { useFiles } from "../../../../../application/useCases/useFiles";
import { formatSize } from "../../../../../utility";
import { ContainerAirFileType } from "../../../../../domain/airFile/airFileType";
import { defaultDataParams } from "../../../../../domain/dataParams/dataParams";
import {
    FilesGetTiersResult,
    TierInfo,
} from "../../../../../application/files/filesPort";

type DownloadDialogProps = {
    files: AirFile[];
    filesSize: string;
    downloadLocation: DownloadLocation;
    isOpen: boolean;
    onClose?: () => void;
};

type DownloadDialogContentProps = {
    files: AirFile[];
    filesSize: string;
    downloadLocation: DownloadLocation;
    onClose: () => void;
    tiers: FilesGetTiersResult;
};

type DownloadDialogConfirmationProps = {
    files: AirFile[];
    filesSize: string;
    downloadLocation: DownloadLocation;
    onClose: () => void;
    tiers: FilesGetTiersResult;
    totalFiles: number;
    formatedTotalSize: string;
};

type DownloadDialogSharepointProps = {
    files: AirFile[];
    filesSize: string;
    downloadLocation: DownloadLocation;
    onClose: () => void;
    tiers: FilesGetTiersResult;
    totalFiles: number;
    formatedTotalSize: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function downloadToDesktop(files: AirFile[]): Promise<void> {
    if (
        files.length > 1 ||
        (!isFile(files[0]) &&
            files[0].fileType !== ContainerAirFileType.ExtractedArchive)
    ) {
        const res = await useFiles.downloadToDesktop({
            ids: files.map(i => i.id),
        });

        if (res) {
            const fileName = sanitizeFilename(res.contentDisposition);

            const url = URL.createObjectURL(res.blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = fileName;
            link.click();

            URL.revokeObjectURL(url);
        }
    } else {
        const res = await useFiles.downloadToDesktopSingle({
            id: files[0].id,
            skipRehydration: false,
        });

        if (res) {
            const url = res.link;
            const link = document.createElement("a");
            link.href = url;
            link.download = files[0].title;
            link.click();
        }
    }
}

async function download(
    files: AirFile[],
    downloadLocation: DownloadLocation,
    skipRehydration: boolean = false,
): Promise<void> {
    if (downloadLocation.type === "common") {
        await useFiles.download({
            ids: files.map(i => i.id),
            destination: downloadLocation.id,
            type: downloadLocation.type,
            skipRehydration,
        });
    } else if (downloadLocation.type === "desktop") {
        if (
            files.length === 1 &&
            isFile(files[0]) &&
            files[0].storageTier?.toLowerCase() !== "archive"
        ) {
            const res = await useFiles.downloadToDesktopSingle({
                id: files[0].id,
                skipRehydration,
            });

            if (res) {
                const anchor = document.createElement("a");
                anchor.href = res.link;
                anchor.setAttribute("download", "");
                anchor.target = "_blank";
                anchor.style.display = "none";
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
            }
        } else {
            await useFiles.download({
                ids: files.map(i => i.id),
                destination: downloadLocation.id,
                type: downloadLocation.type,
                skipRehydration,
            });
        }
    }

    useStorage.jobs.dataParams.set({ ...defaultDataParams });
    await useJobs.fetchAll({
        limit: defaultDataParams.pageSize,
        offset: 0,
    });
}

function generateWarnArchiveTierText(tierInfo: TierInfo): string {
    return `The selection has ${tierInfo.files} file(s) sized ${formatSize(
        tierInfo.size,
    )} in Archive storage tier to rehydrate. Rehydration takes up to 24 hours implies costs as per Azure pricing.`;
}

function DownloadDialogConfirmation(
    props: DownloadDialogConfirmationProps,
): JSX.Element {
    const onDownload = useCallback(
        async (skipRehydration: boolean = false): Promise<void> => {
            props.onClose();

            setTimeout(() => {
                download(props.files, props.downloadLocation, skipRehydration);
            }, 0);
        },
        [props],
    );

    return (
        <>
            <DialogContent>
                <div className="app-dialog__content">
                    <Text>
                        You are about to download {props.totalFiles} file(s)
                        (total size: {props.formatedTotalSize}) to{" "}
                        {props.downloadLocation.title}.
                    </Text>

                    <Accordion collapsible>
                        <AccordionItem value="1">
                            <AccordionHeader>Selected items: </AccordionHeader>
                            <AccordionPanel>
                                <div className="dialog-details">
                                    {props.files.map(i => (
                                        <Text key={i.id}>{i.title}</Text>
                                    ))}
                                </div>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>

                    {props.tiers.tiers.Archive ? (
                        <Text>
                            {generateWarnArchiveTierText(
                                props.tiers.tiers.Archive,
                            )}
                        </Text>
                    ) : null}
                </div>
            </DialogContent>
            <DialogActions>
                <div className="app-dialog__actions-btns">
                    <Button
                        appearance="secondary"
                        onClick={props.onClose}
                        className="app-dialog__action-btn"
                    >
                        Cancel
                    </Button>

                    {props.files.length > 1 && props.tiers.tiers.Archive ? (
                        <>
                            <Button
                                appearance="secondary"
                                onClick={(): void => {
                                    onDownload(true);
                                }}
                                className="app-dialog__action-btn"
                            >
                                Download non-archive assets only
                            </Button>

                            <Button
                                appearance="primary"
                                onClick={(): void => {
                                    onDownload();
                                }}
                                className="app-dialog__action-btn"
                            >
                                Rehydrate all and download
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                appearance="primary"
                                onClick={(): void => {
                                    onDownload();
                                }}
                                className="app-dialog__action-btn"
                            >
                                Confirm
                            </Button>
                        </>
                    )}
                </div>
            </DialogActions>
        </>
    );
}

type ParsedUrl = {
    host: string;
    siteName?: string;
    documentLibrary?: string;
};

function DownloadDialogSharepoint(
    props: DownloadDialogSharepointProps,
): JSX.Element {
    const [parsedUrl, setParsedUrl] = useState<ParsedUrl | null>(null);
    const [url, setUrl] = useState<string | null>(null);
    const [documentLibrary, setDocumentLibrary] = useState<Omit<
        SharepointMainData,
        "sharePointSiteId"
    > | null>(null);
    const isQueryInProgress = state.useState(
        useStorage.sharepoint.isQueryInProgress,
    );
    const siteData = state.useState(useStorage.sharepoint.siteData);
    const [matchingDocumentLibraries, setMatchingDocumentLibraries] = useState<
        SharepointSiteDocumentLibrary[]
    >([]);
    const [customDocumentLibraryName, setCustomDocumentLibraryName] = useState<
        string | null
    >(null);

    const parseUrl = useCallback((url: string): void => {
        if (!url) {
            setUrl(null);
            setParsedUrl(null);
        }

        try {
            const u = new URL(url);
            const { host } = u;
            const splitted = decodeURI(u.pathname).split("/");

            const index = splitted.findIndex(value => value === "sites");

            if (index === -1) {
                if (host.length === 0) {
                    setUrl(null);
                    setParsedUrl(null);
                } else {
                    setUrl(u.toString());
                    setParsedUrl({
                        host,
                    });
                }
            }

            const siteName = splitted[index + 1];
            const documentLibrary = splitted[index + 2];

            const parsedUrl: ParsedUrl = {} as ParsedUrl;

            if (host && host.length > 0) {
                parsedUrl.host = host;
            }

            if (siteName && siteName.length > 0) {
                parsedUrl.siteName = siteName;
            }

            if (documentLibrary && documentLibrary.length > 0) {
                parsedUrl.documentLibrary = documentLibrary;
            }

            if (Object.keys(parsedUrl).length > 0) {
                setUrl(u.toString());
                setParsedUrl(parsedUrl);
            } else {
                setUrl(null);
                setParsedUrl(null);
            }
        } catch {
            setUrl(null);
            setParsedUrl(null);
        }
    }, []);

    const onDownloadToSharepoint = async (
        skipRehydration: boolean = false,
    ): Promise<void> => {
        props.onClose();

        setTimeout(async () => {
            if (siteData !== null && siteData !== undefined) {
                const selectedDownload: SharepointMainData = {
                    sharePointSiteId: siteData.id,
                };

                if (documentLibrary) {
                    if (
                        documentLibrary.sharePointDocumentLibraryId ===
                            undefined ||
                        documentLibrary.sharePointDocumentLibraryId.length > 0
                    ) {
                        selectedDownload.sharePointDocumentLibraryId =
                            documentLibrary.sharePointDocumentLibraryId;
                    }
                    selectedDownload.sharePointDocumentLibraryName =
                        documentLibrary.sharePointDocumentLibraryName;
                }

                await useFiles.download({
                    ids: props.files.map(i => i.id),
                    destination: props.downloadLocation.id,
                    type: props.downloadLocation.type,
                    ...selectedDownload,
                    skipRehydration,
                });

                useStorage.jobs.dataParams.set({ ...defaultDataParams });
                await useJobs.fetchAll({
                    limit: defaultDataParams.pageSize,
                    offset: 0,
                });
            }
        }, 0);
    };

    const onDocumentLibraryChange: ComboboxProps["onChange"] = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const value = event.target.value.trim();
        const matches =
            siteData === undefined || siteData === null
                ? []
                : siteData.list.filter(
                      option =>
                          option.displayName
                              .toLowerCase()
                              .indexOf(value.toLowerCase()) === 0,
                  );

        setMatchingDocumentLibraries(matches);

        if (value.length && matches.length < 1) {
            setCustomDocumentLibraryName(value);
        } else {
            setCustomDocumentLibraryName(null);
        }
    };

    useEffect(() => {
        return (): void => {
            useStorage.sharepoint.siteData.set(null);
        };
    }, []);

    return (
        <>
            <DialogContent>
                {isQueryInProgress ? (
                    <div className="app-dialog__content">
                        <Loader text="Loading..." />
                    </div>
                ) : (
                    <div className="app-dialog__content">
                        <div className="app-dialog__section">
                            <div className="app-dialog__row-row">
                                <Label
                                    required
                                    htmlFor={"sharepoint-site-input"}
                                >
                                    Sharepoint site
                                </Label>
                                <Input
                                    required
                                    type="text"
                                    id={"sharepoint-site-input"}
                                    placeholder="put the link to sharepoint site"
                                    defaultValue={url ?? ""}
                                    onChange={(_e, data): void => {
                                        parseUrl(data.value);

                                        if (siteData !== null) {
                                            useStorage.sharepoint.siteData.set(
                                                null,
                                            );
                                        }

                                        if (documentLibrary !== null) {
                                            setDocumentLibrary(null);
                                        }
                                    }}
                                />
                            </div>
                            {parsedUrl && (
                                <div className="parsed-url">
                                    <Text>Host: {parsedUrl.host}</Text>
                                    {parsedUrl.siteName && (
                                        <Text>Site: {parsedUrl.siteName}</Text>
                                    )}
                                    {parsedUrl.documentLibrary && (
                                        <Text>
                                            Document library:{" "}
                                            {parsedUrl.documentLibrary}
                                        </Text>
                                    )}
                                </div>
                            )}
                            <Button
                                className="btn_fit"
                                disabled={url === null}
                                onClick={(): void => {
                                    if (url !== null) {
                                        useSharepoint.checkAccess({
                                            url: new URL(url),
                                        });
                                    }
                                }}
                            >
                                Check access to sharepoint site
                            </Button>
                        </div>

                        {siteData !== null && siteData !== undefined ? (
                            siteData.hasAccess ? (
                                <div className="app-dialog__section">
                                    <div className="app-dialog__row-row">
                                        <Label
                                            required
                                            htmlFor={
                                                "document-library-dropdown"
                                            }
                                        >
                                            Document library
                                        </Label>
                                        <Combobox
                                            freeform
                                            placeholder="Please write or select"
                                            id="document-library-dropdown"
                                            value={
                                                documentLibrary?.sharePointDocumentLibraryName
                                            }
                                            onOptionSelect={(
                                                _e,
                                                data,
                                            ): void => {
                                                setDocumentLibrary({
                                                    sharePointDocumentLibraryId:
                                                        data.optionValue,
                                                    sharePointDocumentLibraryName:
                                                        data.optionText,
                                                });
                                            }}
                                            onChange={onDocumentLibraryChange}
                                        >
                                            {customDocumentLibraryName !==
                                                null &&
                                            customDocumentLibraryName.length >
                                                0 ? (
                                                <Option
                                                    key="freeform"
                                                    text={
                                                        customDocumentLibraryName
                                                    }
                                                    value=""
                                                >
                                                    Add new document library:
                                                    {customDocumentLibraryName}
                                                </Option>
                                            ) : null}
                                            {matchingDocumentLibraries.length ===
                                                0 &&
                                            (customDocumentLibraryName ===
                                                null ||
                                                customDocumentLibraryName.length ===
                                                    0)
                                                ? siteData.list.map(option => (
                                                      <Option
                                                          value={
                                                              option.id ??
                                                              option.displayName
                                                          }
                                                          key={option.id}
                                                      >
                                                          {option.displayName}
                                                      </Option>
                                                  ))
                                                : matchingDocumentLibraries.map(
                                                      option => (
                                                          <Option
                                                              value={
                                                                  option.id ??
                                                                  option.displayName
                                                              }
                                                              key={option.id}
                                                          >
                                                              {
                                                                  option.displayName
                                                              }
                                                          </Option>
                                                      ),
                                                  )}
                                        </Combobox>
                                    </div>
                                </div>
                            ) : (
                                <Text>No access</Text>
                            )
                        ) : null}

                        {documentLibrary !== null ? (
                            <div className="app-dialog__section">
                                <Text>
                                    You going to download {props.totalFiles}{" "}
                                    file(s) ({props.formatedTotalSize}) to{" "}
                                    {props.downloadLocation.title}.
                                </Text>

                                <Accordion collapsible>
                                    <AccordionItem value="1">
                                        <AccordionHeader>Files</AccordionHeader>
                                        <AccordionPanel>
                                            <div className="dialog-details">
                                                {props.files.map(i => (
                                                    <Text key={i.id}>
                                                        {i.title}
                                                    </Text>
                                                ))}
                                            </div>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>

                                {props.tiers.tiers.Archive ? (
                                    <Text>
                                        {generateWarnArchiveTierText(
                                            props.tiers.tiers.Archive,
                                        )}
                                    </Text>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <div className="app-dialog__actions-btns">
                    <Button
                        appearance="secondary"
                        onClick={props.onClose}
                        className="app-dialog__action-btn"
                    >
                        Cancel
                    </Button>

                    {props.tiers.tiers.Archive ? (
                        <>
                            <Button
                                appearance="secondary"
                                disabled={
                                    url === null || documentLibrary === null
                                }
                                onClick={(): void => {
                                    onDownloadToSharepoint(true);
                                }}
                                className="app-dialog__action-btn"
                            >
                                Download non-archive assets only
                            </Button>

                            <Button
                                appearance="primary"
                                disabled={
                                    url === null || documentLibrary === null
                                }
                                onClick={(): void => {
                                    onDownloadToSharepoint();
                                }}
                                className="app-dialog__action-btn"
                            >
                                Rehydrate all and download
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                appearance="primary"
                                disabled={
                                    url === null || documentLibrary === null
                                }
                                onClick={(): void => {
                                    onDownloadToSharepoint();
                                }}
                                className="app-dialog__action-btn"
                            >
                                Confirm
                            </Button>
                        </>
                    )}
                </div>
            </DialogActions>
        </>
    );
}

function DownloadDialogContent(props: DownloadDialogContentProps): JSX.Element {
    let totalFiles = 0;
    let totalSize = 0;

    const tiers = props.tiers.tiers;

    for (const tier of Object.keys(tiers) as (keyof typeof tiers)[]) {
        const tierInfo = tiers[tier];
        if (tierInfo) {
            totalFiles += tierInfo.files;
            totalSize += tierInfo.size;
        }
    }

    const formatedTotalSize = formatSize(totalSize);

    switch (props.downloadLocation.type.toLowerCase()) {
        case "common":
        case "desktop":
            return (
                <DownloadDialogConfirmation
                    files={props.files}
                    filesSize={props.filesSize}
                    downloadLocation={props.downloadLocation}
                    onClose={props.onClose}
                    tiers={props.tiers}
                    totalFiles={totalFiles}
                    formatedTotalSize={formatedTotalSize}
                />
            );

        case "sharepoint":
            return (
                <DownloadDialogSharepoint
                    files={props.files}
                    filesSize={props.filesSize}
                    downloadLocation={props.downloadLocation}
                    onClose={props.onClose}
                    tiers={props.tiers}
                    totalFiles={totalFiles}
                    formatedTotalSize={formatedTotalSize}
                />
            );

        default:
            return <></>;
    }
}

function DownloadDialog(props: DownloadDialogProps): JSX.Element {
    const isGetTiersQueryInProgress = state.useState(
        useStorage.files.isGetTiersQueryInProgress,
    );
    const [tiers, setTiers] = useState<FilesGetTiersResult | null>(null);

    useEffect(() => {
        useFiles.getTiers({ ids: props.files.map(i => i.id) }).then(r => {
            if (r) {
                setTiers(r);
            }
        });
    }, [props.files]);

    const onClose = useCallback(() => {
        state.appDialog.set(null);
        if (props.onClose) {
            props.onClose();
        }
    }, [props]);

    if (isGetTiersQueryInProgress && (tiers === undefined || tiers === null)) {
        return (
            <Dialog
                onOpenChange={(_e, data): void => {
                    if (!data.open && !isGetTiersQueryInProgress) {
                        onClose();
                    }
                }}
                open={props.isOpen}
            >
                <DialogSurface
                    onClick={(e): void => {
                        e.stopPropagation();
                    }}
                >
                    <Loader text="Loading tiers data..." />
                </DialogSurface>
            </Dialog>
        );
    }

    if (tiers === undefined || tiers === null) {
        return (
            <Dialog
                onOpenChange={(_e, data): void => {
                    if (!data.open && !isGetTiersQueryInProgress) {
                        onClose();
                    }
                }}
                open={props.isOpen}
            >
                <DialogSurface
                    onClick={(e): void => {
                        e.stopPropagation();
                    }}
                >
                    <DialogBody>
                        <DialogTitle>Download</DialogTitle>
                        <DialogContent>
                            <div className="app-dialog__content">
                                <Text>
                                    Could not get information about storage
                                    tiers
                                </Text>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                appearance="primary"
                                onClick={props.onClose}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        );
    }

    return (
        <Dialog
            onOpenChange={(_e, data): void => {
                if (!data.open && !isGetTiersQueryInProgress) {
                    onClose();
                }
            }}
            open={props.isOpen}
        >
            <DialogSurface
                style={{
                    width: "max-content",
                    maxWidth: "calc(100dvw - 40px)",
                    minWidth: "200px",
                }}
                onClick={(e): void => {
                    e.stopPropagation();
                }}
            >
                {isGetTiersQueryInProgress ? (
                    <Loader text="Loading tiers data..." />
                ) : (
                    <DialogBody
                        style={{
                            maxHeight: "calc(-48px + 100vh)",
                            boxSizing: "border-box",
                            width: "100%",
                            display: "block",
                        }}
                    >
                        <DialogTitle>Download</DialogTitle>
                        <DownloadDialogContent
                            downloadLocation={props.downloadLocation}
                            files={props.files}
                            filesSize={props.filesSize}
                            onClose={onClose}
                            tiers={tiers}
                        />
                    </DialogBody>
                )}
            </DialogSurface>
        </Dialog>
    );
}

export function openDownloadDialog(
    downloadLocation: DownloadLocation,
    filesSelectionFiles: AirFile[],
    filesSelectionFilesSize: number,
): void {
    state.appDialog.set(() => (
        <DownloadDialog
            downloadLocation={downloadLocation}
            isOpen={true}
            files={filesSelectionFiles}
            filesSize={formatSize(filesSelectionFilesSize)}
            onClose={(): void => {
                useStorage.fileExplorerMiniApp.selectedDownloadLocation.set(
                    null,
                );
                state.appDialog.set(null);
            }}
        />
    ));
}

export default DownloadDialog;
