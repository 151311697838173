import React, { RefObject } from "react";

import ExtendedViewTags from "./ExtendedViewTags/ExtendedViewTags";
import ExtendedViewGroupTags from "./ExtendedViewGroupTags/ExtendedViewGroupTags";
import ExtendedViewPeople from "./ExtendedViewPeople/ExtendedViewPeople";
import Emotions from "./Emotions/Emotions";
import ExtendedViewTopics from "./ExtendedViewTopics/ExtendedViewTopics";
import { Subtitle1 } from "@fluentui/react-components";
import { Insights } from "../../../../../application/cognitiveMetadata/cognitiveMetadataPort";
import { ExtendedViewData } from "../../models";
import { state } from "../../../../state/stateAdapter";
import { videoExtendedLocalState } from "../videoExtendedViewLocalState";

type InsightsProps = {
    data: Insights;
    duration: number;
    videoRef: RefObject<HTMLVideoElement>;
};

function InsightsTab(props: InsightsProps): JSX.Element | null {
    const viewInsights = state.useState(videoExtendedLocalState.viewInsights);

    const insightsList = [...viewInsights];

    if (props.videoRef === null || props.data === null) {
        return <Subtitle1>No insights found for this video</Subtitle1>;
    }

    return (
        <div className="extended-view__insights">
            {insightsList.map(listItem => {
                const insightsListKey = listItem[0];
                const insightsListItem = listItem[1];

                if (insightsListKey === "faces" && props.data.faces) {
                    return (
                        <ExtendedViewPeople
                            key={insightsListKey}
                            title={insightsListItem.text}
                            data={props.data.faces}
                            videoRef={props.videoRef}
                            duration={props.duration}
                        />
                    );
                }

                if (insightsListKey === "keywords" && props.data.keywords) {
                    return (
                        <ExtendedViewTags
                            key={insightsListKey}
                            title="Keywords"
                            data={props.data.keywords}
                            videoRef={props.videoRef}
                            duration={props.duration}
                        />
                    );
                }

                if (insightsListKey === "labels") {
                    return (
                        <ExtendedViewTags
                            key={insightsListKey}
                            title="Labels"
                            data={props.data.labels}
                            videoRef={props.videoRef}
                            duration={props.duration}
                        />
                    );
                }

                if (insightsListKey === "namedEntities") {
                    return (
                        <ExtendedViewGroupTags
                            key={insightsListKey}
                            title="Named entities"
                            groups={[
                                {
                                    title: "People",
                                    data: props.data.namedPeople ?? [],
                                    countOverflow: 0,
                                    isOverflow: true,
                                },
                                {
                                    title: "Locations",
                                    data: props.data.namedLocations ?? [],
                                    countOverflow: 0,
                                    isOverflow: true,
                                },
                                {
                                    title: "Brands",
                                    data: props.data.brands ?? [],
                                    countOverflow: 0,
                                    isOverflow: true,
                                },
                            ]}
                            videoRef={props.videoRef}
                            duration={props.duration}
                        />
                    );
                }

                if (insightsListKey === "emotions" && props.data.emotions) {
                    return (
                        <Emotions
                            key={insightsListKey}
                            title="Emotions"
                            data={props.data.emotions}
                            videoRef={props.videoRef}
                            duration={props.duration}
                        />
                    );
                }

                if (insightsListKey === "sentiments" && props.data.sentiments) {
                    return (
                        <Emotions
                            key={insightsListKey}
                            title="Sentiments"
                            data={props.data.sentiments.filter(
                                (i: ExtendedViewData) => i.value !== "Neutral",
                            )}
                            videoRef={props.videoRef}
                            duration={props.duration}
                        />
                    );
                }

                if (
                    insightsListKey === "topics" &&
                    props.data.topicsIab &&
                    props.data.topicsIptc
                ) {
                    return (
                        <ExtendedViewTopics
                            key={insightsListKey}
                            title="Topics"
                            iab={props.data.topicsIab}
                            iptc={props.data.topicsIptc}
                            videoRef={props.videoRef}
                            duration={props.duration}
                        />
                    );
                }

                if (
                    insightsListKey === "audioEffects" &&
                    props.data.audioEffects
                ) {
                    return (
                        <ExtendedViewTags
                            key={insightsListKey}
                            title="Audio effects"
                            data={props.data.audioEffects}
                            videoRef={props.videoRef}
                            duration={props.duration}
                        />
                    );
                }

                if (props.data.custom) {
                    return (
                        <ExtendedViewTags
                            key={insightsListKey}
                            title={insightsListItem.text}
                            data={props.data.custom.common[insightsListKey]}
                            videoRef={props.videoRef}
                            duration={props.duration}
                        />
                    );
                }
            })}
        </div>
    );
}

export default InsightsTab;
