import {
    TabList,
    Tab,
    makeStyles,
    Link,
    shorthands,
} from "@fluentui/react-components";
import {
    FolderOpenFilled,
    FolderOpenRegular,
    CollectionsFilled,
    CollectionsRegular,
    bundleIcon,
    CloudArrowUpRegular,
    CloudArrowUpFilled,
    SettingsRegular,
    SettingsFilled,
    TextBoxSettingsFilled,
    TextBoxSettingsRegular,
} from "@fluentui/react-icons";
import React from "react";
import { router, routes } from "../../router";
import { state } from "../../state/stateAdapter";
import { MiniAppKind } from "../../../domain/miniApp/miniApp";
import { useStorage } from "../../../application/useCases/useStorage";
import useIsMobileView from "../../utility/useIsMobileView";
import { hasUserAdminPermissions } from "../../../domain/user/user";

const useStyles = makeStyles({
    pageNavigation: {
        ...shorthands.transition("width", "0.3s", "easy"),
    },
    tabList: { height: "calc(100dvh - 48px - 8px)" },
    spacer: { flexShrink: 1, flexGrow: 1 },
});

const FolderOpenIcon = bundleIcon(FolderOpenFilled, FolderOpenRegular);
const CollectionsIcon = bundleIcon(CollectionsFilled, CollectionsRegular);
const UploaderIcon = bundleIcon(CloudArrowUpFilled, CloudArrowUpRegular);
const AdminIcon = bundleIcon(SettingsFilled, SettingsRegular);
const JobsIcon = bundleIcon(TextBoxSettingsFilled, TextBoxSettingsRegular);

function getFileExplorerUrl(): string {
    const context = useStorage.fileExplorerMiniApp.context.get();

    if (context && context.length > 0) {
        return context;
    } else {
        return router.createRoute({ page: routes.explorer });
    }
}

function getCollecttionUrl(): string {
    const context = useStorage.collectionsMiniApp.context.get();

    if (context && context.length > 0) {
        return context;
    } else {
        return router.createRoute({ page: routes.collections });
    }
}

function getUploaderUrl(): string {
    const context = useStorage.uploaderMiniApp.context.get();

    if (context && context.length > 0) {
        return context;
    } else {
        return router.createRoute({ page: routes.uploader });
    }
}

function AppNavigation(): JSX.Element {
    const activeMiniApp = state.useState(useStorage.appCommon.activeMiniApp);
    const isMobileView = useIsMobileView();
    const classes = useStyles();
    const user = state.unwrap(useStorage.authorization.user);

    return (
        <aside className={"app__navigation " + classes.pageNavigation}>
            <TabList
                defaultSelectedValue={MiniAppKind.Explorer}
                vertical={!isMobileView}
                size={isMobileView ? "medium" : "large"}
                appearance="subtle"
                selectedValue={activeMiniApp}
                className={classes.tabList}
            >
                <Link
                    as="a"
                    appearance="subtle"
                    href={getFileExplorerUrl()}
                    data-route
                >
                    <Tab
                        icon={<FolderOpenIcon />}
                        value={MiniAppKind.Explorer}
                        aria-label="Explorer"
                        title="File explorer"
                    />
                </Link>
                <Link
                    as="a"
                    appearance="subtle"
                    href={getCollecttionUrl()}
                    data-route
                >
                    <Tab
                        icon={<CollectionsIcon />}
                        value={MiniAppKind.Collections}
                        aria-label="Collections"
                        title="Collections"
                    />
                </Link>
                <Link
                    as="a"
                    appearance="subtle"
                    href={getUploaderUrl()}
                    data-route
                >
                    <Tab
                        icon={<UploaderIcon />}
                        value={MiniAppKind.Uploader}
                        aria-label="Uploader"
                        title="Uploader"
                    />
                </Link>
                <Link
                    as="a"
                    appearance="subtle"
                    href={router.createRoute({ page: routes.jobs })}
                    data-route
                >
                    <Tab
                        icon={<JobsIcon />}
                        value={MiniAppKind.Jobs}
                        aria-label="Jobs"
                        title="Jobs"
                    />
                </Link>

                <div className={classes.spacer}></div>

                {user !== undefined &&
                user !== null &&
                hasUserAdminPermissions(user) ? (
                    <Link
                        as="a"
                        appearance="subtle"
                        href={router.createRoute({ page: "/admin" })}
                        data-route
                    >
                        <Tab
                            icon={<AdminIcon />}
                            value={MiniAppKind.Admin}
                            aria-label="AIR Settings"
                            title="AIR Settings"
                        />
                    </Link>
                ) : null}
            </TabList>
        </aside>
    );
}

export default AppNavigation;
