import React from "react";
import ExtendedViewSection from "../ExtendedViewSection/ExtendedViewSection";
import { ExtendedViewData, ExtendedViewSectionParams } from "../../../models";
import Tag from "../../../../Tag/Tag";

function ExtendedViewTags(
    props: ExtendedViewSectionParams,
): JSX.Element | null {
    return (
        <ExtendedViewSection
            title={props.title}
            data={props.data}
            duration={props.duration}
            videoRef={props.videoRef}
            renderItem={(
                i: ExtendedViewData,
                activeItem: ExtendedViewData,
                setActiveItem: (i: ExtendedViewData) => void,
            ): JSX.Element => (
                <Tag
                    item={i}
                    isActive={
                        activeItem !== undefined && i.value === activeItem.value
                    }
                    onClick={(): void => {
                        setActiveItem(i);
                    }}
                />
            )}
        />
    );
}

export default ExtendedViewTags;
