import { StoragePort } from "../../../../application/ports";
import { useStorage } from "../../../../application/useCases/useStorage";
import { Filters } from "../../../../domain/filter/filters";
import { FilesViewModeKind } from "../../../models";
import { router, routes } from "../../../router";
import { defaultDataParams } from "../../../../domain/dataParams/dataParams";
import { KeyValueObj } from "../../../../domain/types/types";
import { useEventBus } from "../../../../application/useCases/useEventBus";
import { UIEvents } from "../../../uiEvents";
import { FileExplorerUrlParams, FilterData } from "../shared/types";

class FileExplorerManager {
    private readonly _filterPrefix: string;
    private readonly _filterRegexp: RegExp;
    private readonly _storagePort: StoragePort;

    constructor(storagePort: StoragePort, filterPrefix = "filter_") {
        this._storagePort = storagePort;
        this._filterPrefix = filterPrefix;

        this._filterRegexp = new RegExp(
            `^${this._filterPrefix}((\\w+\\.\\w+)|\\w+)(?:\\[\\d+\\])?`,
        );
    }

    private _prevFileExplorerUrlParams: FileExplorerUrlParams = {
        activeDirectory: null,
        activeItem: null,
        filters: null,
        filtersParams: null,
        filtersParamsStr: null,
        ...defaultDataParams,
        viewMode: FilesViewModeKind.List,
        query: null,
        selection: null,
    };

    private _toFilterParams(filters: Filters, withPrefix = false): KeyValueObj {
        const filterParams: KeyValueObj = {};

        filters.forEach((value: string[], key: string) => {
            value.forEach((item, index) => {
                const newKey = withPrefix
                    ? `${this._filterPrefix}${key}[${index}]`
                    : `${key}[${index}]`;
                filterParams[newKey] = item;
            });
        });

        return filterParams;
    }

    private _isFilterKey(key: string): boolean {
        return this._filterRegexp.test(key);
    }

    parseUrl(url: URL): FileExplorerUrlParams {
        const searchParams = url.searchParams;
        const filters: Filters = new Map();
        const matches: string[] = [];

        searchParams.forEach((value, key) => {
            const match = this._filterRegexp.exec(key);
            if (match) {
                const filterGroup = match[1];
                if (!filters.has(filterGroup)) {
                    matches.push(filterGroup);
                    filters.set(filterGroup, []);
                }

                filters.get(filterGroup)!.push(value);
            }
        });

        const pageSize = searchParams.get("pageSize");
        const page = searchParams.get("page");
        const sortKey = searchParams.get("sortKey");
        const isSortDesc = searchParams.get("isSortDesc");
        const viewMode = searchParams.get("viewMode");
        const query = searchParams.get("query");
        const selection = searchParams.get("selection");

        return {
            activeDirectory: searchParams.get("path"),
            activeItem: searchParams.get("activeItem"),
            filters: filters.size > 0 ? filters : null,
            filtersParams: this._toFilterParams(filters),
            filtersParamsStr:
                filters.size > 0
                    ? JSON.stringify(this._toFilterParams(filters))
                    : null,
            pageSize: pageSize
                ? parseInt(pageSize)
                : defaultDataParams.pageSize,
            page: page ? parseInt(page) : defaultDataParams.page,
            sortKey: sortKey ?? undefined,
            isSortDesc: isSortDesc !== null ? isSortDesc === "true" : false,
            viewMode:
                viewMode !== null ? parseInt(viewMode) : FilesViewModeKind.List,
            query,
            selection: selection ? selection.split(",") : null,
        };
    }

    updateParams(url: URL): void {
        const urlParams = this.parseUrl(url);

        if (url.pathname === routes.explorer) {
            this.updateContext(url.href);

            if (
                urlParams.activeDirectory !==
                this._prevFileExplorerUrlParams.activeDirectory
            ) {
                useEventBus.emit(UIEvents.FileExplorerChangeActiveDirectory, {
                    newActiveDirectory: urlParams.activeDirectory,
                });
            }

            if (
                urlParams.filtersParamsStr !==
                this._prevFileExplorerUrlParams.filtersParamsStr
            ) {
                if (
                    urlParams.filtersParamsStr === null ||
                    urlParams.filtersParamsStr.length === 0
                ) {
                    useEventBus.emit(UIEvents.FileExplorerResetFilters, {});
                } else {
                    useEventBus.emit(UIEvents.FileExplorerChangeFilters, {
                        newFilters: urlParams.filters,
                    });
                }
            }

            if (
                urlParams.sortKey !== this._prevFileExplorerUrlParams.sortKey ||
                urlParams.isSortDesc !==
                    this._prevFileExplorerUrlParams.isSortDesc
            ) {
                useEventBus.emit(UIEvents.FileExplorerChangeSort, {
                    sortKey: urlParams.sortKey,
                    isSortDesc: urlParams.isSortDesc,
                });
            }

            if (urlParams.query !== this._prevFileExplorerUrlParams.query) {
                useEventBus.emit(UIEvents.FileExplorerChangeSearchQuery, {
                    query: urlParams.query,
                });
            }

            if (
                urlParams.page !== this._prevFileExplorerUrlParams.page ||
                urlParams.pageSize !== this._prevFileExplorerUrlParams.pageSize
            ) {
                useEventBus.emit(UIEvents.FileExplorerChangePagination, {
                    page: urlParams.page,
                    pageSize: urlParams.pageSize,
                });
            }

            if (
                urlParams.viewMode !== this._prevFileExplorerUrlParams.viewMode
            ) {
                useEventBus.emit(UIEvents.FileExplorerChangeViewMode, {
                    mode: urlParams.viewMode,
                });
            }

            if (
                urlParams.activeItem !==
                this._prevFileExplorerUrlParams.activeItem
            ) {
                useEventBus.emit(UIEvents.FileExplorerChangeActiveItem, {
                    activeItem: urlParams.activeItem,
                });
            }

            useEventBus.emit(UIEvents.FileExplorerChangeSelection, {
                selection: urlParams.selection,
                files: useStorage.files.files.get(),
            });

            // updating prev
            this._prevFileExplorerUrlParams.activeDirectory =
                urlParams.activeDirectory;

            this._prevFileExplorerUrlParams.filtersParamsStr =
                urlParams.filtersParamsStr;

            this._prevFileExplorerUrlParams.sortKey = urlParams.sortKey;

            this._prevFileExplorerUrlParams.isSortDesc = urlParams.isSortDesc;

            this._prevFileExplorerUrlParams.query = urlParams.query;

            this._prevFileExplorerUrlParams.page = urlParams.page;

            this._prevFileExplorerUrlParams.pageSize = urlParams.pageSize;

            this._prevFileExplorerUrlParams.viewMode = urlParams.viewMode;

            this._prevFileExplorerUrlParams.activeItem = urlParams.activeItem;

            this._prevFileExplorerUrlParams.selection = urlParams.selection;
        }
    }

    async updateUrlParams(): Promise<FileExplorerUrlParams> {
        const urlParams = this.parseUrl(router.url);
        this._prevFileExplorerUrlParams = urlParams;

        return urlParams;
    }

    async updateFilters(filtersToUpdate: FilterData[]): Promise<void> {
        const url = router.url;
        const urlParams = this.parseUrl(router.url);
        const filters = urlParams.filters ?? new Map();

        for (const filterData of filtersToUpdate) {
            const { filterGroup, filterName } = filterData;

            const filterValues = filters.get(filterGroup) || [];

            const filterIndex = filterValues.indexOf(`${filterName}`);
            if (filterIndex === -1) {
                filterValues.push(filterName);
            } else {
                filterValues.splice(filterIndex, 1);
            }

            filters.set(filterGroup, filterValues);

            if (filters.get(filterGroup)!.length === 0) {
                filters.delete(filterGroup);
            }
        }

        const keysToRemove: string[] = [];

        url.searchParams.forEach((_value, key) => {
            if (this._isFilterKey(key)) {
                keysToRemove.push(key);
            }
        });

        keysToRemove.forEach(key => {
            url.searchParams.delete(key);
        });

        const filterParams = this._toFilterParams(filters, true);

        for (const name in filterParams) {
            const value = filterParams[name];
            if (value !== undefined && value !== null) {
                url.searchParams.set(name, `${value}`);
            }
        }

        router.updateSearchParams(url.searchParams);
    }

    clearAllFilters(): void {
        const url = router.url;

        Array.from(url.searchParams.keys()).forEach(key => {
            if (this._isFilterKey(key)) {
                url.searchParams.delete(key);
            }
        });

        router.updateSearchParams(url.searchParams);
    }

    removeFilter(filterGroup: string): void {
        router.removeSearchParam(`${this._filterPrefix}${filterGroup}`);
    }

    async updateDateFilter(
        filterGroup: string,
        filterName: string,
    ): Promise<void> {
        const url = router.url;

        url.searchParams.set(
            `${this._filterPrefix}${filterGroup}[0]`,
            filterName,
        );
        router.updateSearchParams(url.searchParams);
    }

    updateContext(href: string): void {
        this._storagePort.fileExplorerMiniApp.context.set(href);
    }
}

export const fileExplorerManager = new FileExplorerManager(useStorage);
