import React, { SyntheticEvent, useCallback } from "react";
import {
    TableColumnDefinition,
    createTableColumn,
    TableCellLayout,
    Link,
    TableCellActions,
    Text,
    Button,
} from "@fluentui/react-components";
import {
    DocumentLockRegular,
    FolderArrowLeftRegular,
    InfoRegular,
    ShareRegular,
} from "@fluentui/react-icons";
import {
    AirFile,
    isContainer,
    isFile,
} from "../../../../../domain/airFile/airFile";
import { router } from "../../../../router";
import { state } from "../../../../state/stateAdapter";
import { formatDate } from "../../../../utility/formatters";
import FilesTable from "../../../../components/FilesTable/FilesTable";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { FilesViewModeKind } from "../../../../models";
import Tiles from "../../../../components/Tiles/Tiles";
import IconItem from "../../../../components/IconItem/IconItem";
import { onOpenPermissions } from "../../shared/PermissionsDialog/PermissionsDialog";
import { openSharingLinkDialog } from "../../shared/SharingLinkDialog/SharingLinkDialog";
import { protectedDataParamsKeys } from "../../../../../domain/dataParams/dataParams";
import { formatSize } from "../../../../../utility";
import { AirErrorKind } from "../../../../../application/airError/airError";
import Loader from "../../../../components/Loader/Loader";
import { ContainerAirFileType } from "../../../../../domain/airFile/airFileType";
import { RouteParamsSearch } from "../../../../router/urlEntities";
import { hasUserPermissionToRepermissionThisFile } from "../../../../../domain/user/user";

function onAllSelect(
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent,
    items?: unknown[],
): void {
    const target = e.target as HTMLInputElement | null;

    if (target?.tagName === "INPUT" && items) {
        if (target.checked) {
            const selection = (items as AirFile[])
                .map(file => file.hierarchy)
                .join(",");
            router.setSearchParams([{ key: "selection", value: selection }]);
        } else {
            router.removeSearchParam("selection");
        }
    }
}

function onSelect(e: SyntheticEvent, item: unknown): void {
    const target = e.target as HTMLElement | null;
    const airFile = item as AirFile;

    if (target && target.tagName === "INPUT") {
        const selectionStr = router
            .getUrlEntities()
            .searchParams.get("selection");
        const selectionArr = selectionStr ? selectionStr.split(",") : [];

        const index = selectionArr.indexOf(airFile.hierarchy);

        if (index > -1) {
            selectionArr.splice(index, 1);
        } else {
            selectionArr.push(airFile.hierarchy);
        }

        if (selectionArr.length) {
            router.setSearchParams([
                { key: "selection", value: selectionArr.join(",") },
            ]);
        } else {
            router.removeSearchParam("selection");
        }
    }
}

function onSingleSelect(
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
    item: unknown,
): void {
    const target = e.target as HTMLElement | null;

    if (target && target.tagName !== "INPUT") {
        router.setSearchParams([
            { key: "selection", value: (item as AirFile).hierarchy },
        ]);
    }
}

function onShareLink(item: AirFile): void {
    useStorage.files.sharingItem.set(item);
    openSharingLinkDialog(item);
}

enum ColumnsId {
    Title = "title",
    FileType = "fileType",
    StorageTier = "storageTier",
    Size = "size",
    DateUpdated = "dateUpdated",
}

const columnSizes = new Map<ColumnsId, number>([
    [ColumnsId.FileType, 140],
    [ColumnsId.StorageTier, 120],
    [ColumnsId.Size, 140],
    [ColumnsId.DateUpdated, 120],
]);

const baseColumns: TableColumnDefinition<AirFile>[] = [
    createTableColumn<AirFile>({
        columnId: ColumnsId.Title,
        renderHeaderCell: () => (
            <TableCellLayout>
                <Text>Title</Text>
            </TableCellLayout>
        ),
        renderCell: item => {
            const isItemFile = isFile(item);
            const isExtractedArchive =
                item.fileType === ContainerAirFileType.ExtractedArchive;
            const isSharingDisabled =
                isItemFile || isExtractedArchive
                    ? false
                    : item.storageTier !== null &&
                      item.storageTier.toLowerCase() === "archive";

            let cellActionsCount: number = 0;
            const searchParams = router.getUrlEntities().searchParams;
            const activeFilters = useStorage.files.activeFilters.get();

            const showGoToDestinationBtn =
                searchParams.has("query") ||
                (activeFilters !== null &&
                    activeFilters !== undefined &&
                    activeFilters.size > 0);

            if (!isContainer(item)) {
                cellActionsCount++;
            }

            if (isItemFile) {
                cellActionsCount += 2;
            }

            const user = useStorage.authorization.user.get();

            const shareLinkUiConfiguration =
                useStorage.config.config.get().features
                    .shareLinkUiConfiguration;

            const isAtLeastOneSharingOptionEnabled =
                shareLinkUiConfiguration.domainOnly ||
                shareLinkUiConfiguration.password ||
                shareLinkUiConfiguration.plainLink;

            const isSharingEnabled =
                isAtLeastOneSharingOptionEnabled &&
                shareLinkUiConfiguration.enabled &&
                user &&
                user.downloadLocations.length > 0;

            return (
                <>
                    <TableCellLayout
                        truncate
                        className="cell-layout"
                        style={{
                            paddingRight: `${32 * cellActionsCount}px`,
                        }}
                        media={
                            <IconItem
                                filePath={item.filePath}
                                fileType={item.fileType}
                                isFile={isItemFile}
                                className="image_table-cell"
                            />
                        }
                    >
                        {isItemFile ? (
                            <Link
                                appearance="subtle"
                                href={router.createRoute({
                                    search: {
                                        items: [
                                            {
                                                key: "activeItem",
                                                value: item.id.toString(),
                                            },
                                        ],
                                    },
                                })}
                                data-route
                            >
                                <Text truncate block wrap={false}>
                                    {item.title}
                                </Text>
                            </Link>
                        ) : (
                            <Link
                                as="a"
                                href={router.createRoute({
                                    search: {
                                        items: [
                                            {
                                                key: "path",
                                                value: item.hierarchy,
                                            },
                                        ],
                                        protect: [
                                            ...protectedDataParamsKeys,
                                            "viewMode",
                                        ],
                                    },
                                })}
                                appearance="subtle"
                                data-route
                            >
                                <Text truncate block wrap={false}>
                                    {item.title}
                                </Text>
                            </Link>
                        )}
                    </TableCellLayout>

                    <TableCellActions className="cell-action">
                        {showGoToDestinationBtn ? (
                            <Button
                                aria-label="Go to destination"
                                appearance="subtle"
                                icon={<FolderArrowLeftRegular />}
                                title="Go to destination"
                                as="a"
                                href={router.createRoute({
                                    page: "explorer",
                                    search: {
                                        items: [
                                            {
                                                key: "path",
                                                value: item.parentHierarchy,
                                            },
                                            {
                                                key: "activeItem",
                                                value: item.id.toString(),
                                            },
                                        ],
                                        protect: [
                                            ...protectedDataParamsKeys,
                                            "viewMode",
                                        ],
                                    },
                                })}
                                data-route
                            />
                        ) : null}

                        {!isContainer(item) &&
                        hasUserPermissionToRepermissionThisFile(
                            item,
                            useStorage.authorization.user.get()!,
                        ) ? (
                            <Button
                                aria-label="Permissions"
                                appearance="subtle"
                                icon={<DocumentLockRegular />}
                                onClick={(): void => onOpenPermissions(item)}
                                title="Edit permissions"
                            />
                        ) : (
                            <></>
                        )}

                        {isItemFile ? (
                            <Button
                                as="a"
                                icon={<InfoRegular />}
                                appearance="subtle"
                                href={router.createRoute({
                                    search: {
                                        items: [
                                            {
                                                key: "activeItem",
                                                value: item.id.toString(),
                                            },
                                        ],
                                    },
                                })}
                                data-route
                                title="File details"
                            />
                        ) : (
                            <></>
                        )}

                        {isSharingEnabled &&
                        (!isContainer(item) || isExtractedArchive) ? (
                            <Button
                                icon={<ShareRegular />}
                                appearance="subtle"
                                disabled={isSharingDisabled}
                                onClick={(): void => onShareLink(item)}
                                title={
                                    isSharingDisabled
                                        ? `You cannot share items with "${item.storageTier}" storage tier`
                                        : "Share"
                                }
                            />
                        ) : (
                            <></>
                        )}
                    </TableCellActions>
                </>
            );
        },
    }),
    createTableColumn<AirFile>({
        columnId: ColumnsId.FileType,
        renderHeaderCell: () => {
            return "Type";
        },
        renderCell: item => (
            <Text truncate block wrap={false} title={item.fileType}>
                {item.fileType}
            </Text>
        ),
    }),
];

const allCollumns: TableColumnDefinition<AirFile>[] = [
    ...baseColumns,
    createTableColumn<AirFile>({
        columnId: ColumnsId.StorageTier,
        renderHeaderCell: () => {
            return "Storage tier";
        },
        renderCell: item => (
            <Text
                truncate
                block
                wrap={false}
                title={
                    item.storageTier || `No storage tier (${item.storageTier})`
                }
            >
                {item.storageTier ? item.storageTier : ""}
            </Text>
        ),
    }),
    createTableColumn<AirFile>({
        columnId: ColumnsId.Size,
        renderHeaderCell: () => {
            return "Size";
        },
        renderCell: item => (
            <Text truncate block wrap={false} title={item.fileSize?.toString()}>
                {formatSize(item.fileSize ?? 0)}
            </Text>
        ),
    }),
    createTableColumn<AirFile>({
        columnId: ColumnsId.DateUpdated,
        renderHeaderCell: () => {
            return "Last modified";
        },
        renderCell: item => (
            <Text
                truncate
                block
                wrap={false}
                title={formatDate(item.dateUpdated) || undefined}
            >
                {formatDate(item.dateUpdated)}
            </Text>
        ),
    }),
];

function FileExplorerContent(): JSX.Element {
    const activeDirectory = state.useState(
        useStorage.fileExplorerMiniApp.activeDirectory,
    );
    const filesToShow = state.useState(useStorage.files.filesToShow);
    const errors = state.useState(useStorage.airErrors);
    const isFilesQueryInProgress = state.useState(
        useStorage.files.isQueryInProgress,
    );

    const error =
        errors.get(AirErrorKind.PrepareFilesToShow) ??
        errors.get(AirErrorKind.FetchFiles) ??
        errors.get(AirErrorKind.FetchFilesByPath) ??
        errors.get(AirErrorKind.SortFiles) ??
        errors.get(AirErrorKind.SortSearchedFiles) ??
        null;

    const filesSelection = state.useState(
        useStorage.fileExplorerMiniApp.selection,
    );
    const filesViewMode = state.useState(
        state.fileExplorerMiniApp.filesViewMode,
    );
    const { isSortDesc, sortKey } = state.useState(
        useStorage.fileExplorerMiniApp.dataParams,
    );

    const isStorageMode = filesToShow.some(
        i => i.fileType === "storage" || i.fileType === "container",
    );

    const columns = isStorageMode ? baseColumns : allCollumns;

    const createLink = useCallback((item: AirFile, isFile: boolean): string => {
        const linkParams: RouteParamsSearch = isFile
            ? {
                  items: [
                      {
                          key: "activeItem",
                          value: item.id.toString(),
                      },
                  ],
              }
            : {
                  items: [
                      {
                          key: "path",
                          value: item.hierarchy,
                      },
                  ],
                  protect: [...protectedDataParamsKeys, "viewMode"],
              };
        const link = router.createRoute({ search: linkParams });

        return link;
    }, []);

    if (isFilesQueryInProgress) {
        return <Loader text="Loading..." />;
    }

    if (filesViewMode === FilesViewModeKind.Tiles) {
        return (
            <Tiles
                isQueryInProgress={false}
                error={error ? error.info.message : undefined}
                activeDirectory={activeDirectory || ""}
                files={filesToShow}
                filesSelection={filesSelection as Map<string, unknown>}
                onSelect={onSelect}
                onSingleSelect={onSingleSelect}
                isFile={isFile}
                selectable={!isStorageMode}
                createLink={createLink}
            />
        );
    }

    return (
        <FilesTable
            isQueryInProgress={false}
            error={error ? error.info.message : undefined}
            files={filesToShow}
            columns={columns as TableColumnDefinition<unknown>[]}
            selection={{
                filesSelection,
                onAllSelect,
                onSelect,
            }}
            sorting={
                isStorageMode
                    ? undefined
                    : {
                          isSortDesc,
                          sortKey: sortKey ?? "",
                      }
            }
            columnSizes={columnSizes}
        />
    );
}

export default FileExplorerContent;
