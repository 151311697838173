import {
    bundleIcon,
    FolderSyncFilled,
    FolderSyncRegular,
    FolderAddFilled,
    FolderAddRegular,
    FolderFilled,
    FolderRegular,
    CloudArchiveFilled,
    CloudArchiveRegular,
    AlertOffFilled,
    AlertOffRegular,
} from "@fluentui/react-icons";

export const RefreshFolderIcon = bundleIcon(
    FolderSyncFilled,
    FolderSyncRegular,
);
export const NewFolderIcon = bundleIcon(FolderAddFilled, FolderAddRegular);
export const FolderIcon = bundleIcon(FolderFilled, FolderRegular);
export const LocationIcon = bundleIcon(CloudArchiveFilled, CloudArchiveRegular);
export const AlertOff = bundleIcon(AlertOffFilled, AlertOffRegular);
