import React from "react";
import { state } from "../../../state/stateAdapter";
import { useStorage } from "../../../../application/useCases/useStorage";
import Loader from "../../../components/Loader/Loader";
import ShareAccessContent from "../ShareAccessContent/ShareAccessContent";
import HierarchyBreadcrumbs from "../../../components/Breadcrumbs/HierarchyBreadcrumbs";
import shareAccessState from "../../shareAccessAppState";
import { AirFile } from "../../../../domain/airFile/airFile";

type ShareAccessFileExplorerProps = {
    filesToShow: AirFile[];
};

function ShareAccessFileExplorer(
    props: ShareAccessFileExplorerProps,
): JSX.Element {
    const isGetFilesQueryInProgress = state.useState(
        useStorage.sharing.isGetFilesQueryInProgress,
    );
    const breadcrumbsPath = state.useState(shareAccessState.breadcrumbsPath);

    if (isGetFilesQueryInProgress) {
        return (
            <div className="app_loading">
                <Loader text="Loading..." />
            </div>
        );
    }

    return (
        <div className="layout">
            <header className="layout__container">
                <HierarchyBreadcrumbs
                    filesHierarchy={breadcrumbsPath}
                    disabled={isGetFilesQueryInProgress}
                    paramKey="path"
                />
            </header>

            <div className="layout__container">
                <ShareAccessContent files={props.filesToShow} />
            </div>
        </div>
    );
}

export default ShareAccessFileExplorer;
