import { useFiles } from "../../../../application/useCases/useFiles";
import { useStorage } from "../../../../application/useCases/useStorage";
import {
    AirFile,
    SortKey,
    createBreadcrumbs,
    prepareFilesToShow,
    sortFilesByType,
} from "../../../../domain/airFile/airFile";
import { defaultDataParams } from "../../../../domain/dataParams/dataParams";
import { Filters } from "../../../../domain/filter/filters";
import { state } from "../../../state/stateAdapter";
import filtersTab from "./FileExplorerFilters/fileExplorerFiltersTab";
import { fileExplorerLocalState } from "./fileExplorerLocalState";
import { fileExplorerManager } from "./fileExplorerManager";
import { Option } from "../../../../domain/types/types";
import fileDetailsTab from "../../../components/FileDetails/fileDetailsTab";
import { appManager } from "../../../appManager/appManager";
import fileExplorerFiltersTab from "./FileExplorerFilters/fileExplorerFiltersTab";
import { FilesViewModeKind } from "../../../models";
import { calculateOffset } from "../../../../utility";
import { router } from "../../../router";

export function changeActiveDirectoryEventHandler(data: {
    newActiveDirectory: string | null;
}): void {
    useStorage.fileExplorerMiniApp.activeDirectory.set(data.newActiveDirectory);
    useStorage.fileExplorerMiniApp.selection.set(new Map());

    const fetchData = async (path: string | null): Promise<void> => {
        await useFiles.fetch({ folderHierarchy: path ?? undefined });
    };

    setTimeout(async () => {
        await fetchData(data.newActiveDirectory ?? null);

        if (
            data.newActiveDirectory === null ||
            data.newActiveDirectory === undefined
        ) {
            fileExplorerLocalState.breadcrumbsPath.set([]);
        } else {
            const files = useStorage.files.files.get();
            const breadcrumbs = createBreadcrumbs(
                data.newActiveDirectory,
                files,
            );
            fileExplorerLocalState.breadcrumbsPath.set(breadcrumbs);
        }

        if (state.appPanel.tabs.get().has(filtersTab.id)) {
            await useFiles.search({
                path: data.newActiveDirectory ?? "",
            });
        }
    }, 10);
}

export async function changeFiltersEventHandler(data: {
    newFilters: Filters | null;
}): Promise<void> {
    useStorage.files.activeFilters.set(data.newFilters);

    useStorage.fileExplorerMiniApp.selection.set(new Map());

    const activeDirectory =
        useStorage.fileExplorerMiniApp.activeDirectory.get();
    const dataParams = useStorage.fileExplorerMiniApp.dataParams.get();
    const query = useStorage.files.searchQuery.get();

    const offset =
        dataParams.page !== null && dataParams.pageSize !== null
            ? calculateOffset(dataParams.page, dataParams.pageSize)
            : 0;

    await useFiles.search({
        path: activeDirectory ?? "",
        query: query ?? undefined,
        limit: dataParams.pageSize ?? undefined,
        offset,
        sortKey: dataParams.sortKey ?? undefined,
        isSortDesc: dataParams.isSortDesc ?? undefined,
        filters: data.newFilters ?? undefined,
    });
}

export async function sortEventHandler(data: {
    sortKey: string | undefined;
    isSortDesc: boolean;
}): Promise<void> {
    const activeFilters = useStorage.files.activeFilters.get();
    const dataParams = useStorage.fileExplorerMiniApp.dataParams.get();
    const query = useStorage.files.searchQuery.get();

    useStorage.fileExplorerMiniApp.selection.set(new Map());
    useStorage.fileExplorerMiniApp.dataParams.update(s => {
        s.sortKey = data.sortKey;
        s.isSortDesc = data.isSortDesc;
    });

    if (
        (query !== null && query !== undefined && query.length > 0) ||
        (activeFilters !== null &&
            activeFilters !== undefined &&
            activeFilters.size > 0)
    ) {
        refreshEventHandler();
    } else {
        const sorted = data.sortKey
            ? sortFilesByType(
                  state.unwrap(useStorage.files.validFiles),
                  data.sortKey as SortKey,
                  data.isSortDesc,
              )
            : state.unwrap(useStorage.files.validFiles);

        useStorage.files.filesToShow.set(
            prepareFilesToShow(
                sorted,
                activeFilters,
                dataParams.page,
                dataParams.pageSize,
            ),
        );
    }
}

export async function refreshEventHandler(): Promise<void> {
    useStorage.fileExplorerMiniApp.selection.set(new Map());

    const activeDirectory =
        useStorage.fileExplorerMiniApp.activeDirectory.get();
    const activeFilters = useStorage.files.activeFilters.get();
    const dataParams = useStorage.fileExplorerMiniApp.dataParams.get();
    const query = useStorage.files.searchQuery.get();

    await useFiles.fetch({
        folderHierarchy: activeDirectory ?? undefined,
    });

    if (activeDirectory === null || activeDirectory === undefined) {
        fileExplorerLocalState.breadcrumbsPath.set([]);
    } else {
        const files = useStorage.files.files.get();
        const breadcrumbs = createBreadcrumbs(activeDirectory, files);
        fileExplorerLocalState.breadcrumbsPath.set(breadcrumbs);
    }

    if (
        (query !== null && query !== undefined && query.length > 0) ||
        (activeFilters !== null &&
            activeFilters !== undefined &&
            activeFilters.size > 0)
    ) {
        const offset =
            dataParams.page !== null && dataParams.pageSize !== null
                ? calculateOffset(dataParams.page, dataParams.pageSize)
                : 0;

        await useFiles.search({
            path: activeDirectory ?? "",
            query: query ?? undefined,
            limit: dataParams.pageSize ?? undefined,
            offset,
            sortKey: dataParams.sortKey ?? undefined,
            isSortDesc: dataParams.isSortDesc ?? undefined,
            filters: activeFilters ?? undefined,
        });
    } else {
        if (state.appPanel.tabs.get().has(filtersTab.id)) {
            await useFiles.search({
                path: activeDirectory ?? "",
            });
        }

        if (
            dataParams.isSortDesc !== defaultDataParams.isSortDesc ||
            dataParams.page !== defaultDataParams.page ||
            dataParams.pageSize !== defaultDataParams.pageSize ||
            dataParams.sortKey !== defaultDataParams.sortKey
        ) {
            await sortEventHandler({
                isSortDesc: dataParams.isSortDesc,
                sortKey: dataParams.sortKey,
            });
        }
    }

    await changeSelectionEventHandler({
        files: null,
        selection: null,
    });
}

export async function initEventHandler(): Promise<void> {
    const urlParams = await fileExplorerManager.updateUrlParams();

    if (useStorage.files.searchQuery.get() !== urlParams.query) {
        useStorage.files.searchQuery.set(urlParams.query);
    }

    if (
        useStorage.fileExplorerMiniApp.activeDirectory.get() !==
        urlParams.activeDirectory
    ) {
        useStorage.fileExplorerMiniApp.activeDirectory.set(
            urlParams.activeDirectory,
        );
    }

    if (
        useStorage.fileExplorerMiniApp.activeItem.get() !== urlParams.activeItem
    ) {
        useStorage.fileExplorerMiniApp.activeItem.set(urlParams.activeItem);
    }

    if (urlParams.activeItem && urlParams.activeItem.length) {
        if (!state.appPanel.tabs.get().has(fileDetailsTab.id)) {
            appManager.addTab(fileDetailsTab);
        }
    } else {
        if (state.appPanel.tabs.get().has(fileDetailsTab.id)) {
            appManager.deleteTab(fileDetailsTab.id);
        }
    }

    if (
        urlParams.filters !== null &&
        urlParams.filters.size > 0 &&
        !state.appPanel.tabs.get().has(fileExplorerFiltersTab.id)
    ) {
        appManager.addTab(fileExplorerFiltersTab);
    }

    useStorage.fileExplorerMiniApp.dataParams.set({
        pageSize: urlParams.pageSize,
        sortKey: urlParams.sortKey,
        isSortDesc: urlParams.isSortDesc,
        page: urlParams.page,
    });
    useStorage.fileExplorerMiniApp.activeItem.set(urlParams.activeItem);
    useStorage.fileExplorerMiniApp.activeDirectory.set(
        urlParams.activeDirectory,
    );
    useStorage.files.activeFilters.set(urlParams.filters);
    state.fileExplorerMiniApp.filesViewMode.set(urlParams.viewMode);

    await refreshEventHandler();
}

export async function changeSearchQueryEventHandler(data: {
    query: Option<string>;
}): Promise<void> {
    useStorage.files.searchQuery.set(data.query);

    await refreshEventHandler();
}

export async function resetFiltersEventHandler(): Promise<void> {
    useStorage.files.activeFilters.set(null);
    useStorage.fileExplorerMiniApp.selection.set(new Map());

    await refreshEventHandler();
}

export async function changePaginationEventHandler(data: {
    page: number;
    pageSize: number;
}): Promise<void> {
    useStorage.fileExplorerMiniApp.selection.set(new Map());
    useStorage.fileExplorerMiniApp.dataParams.update(s => {
        s.page = data.page;
        s.pageSize = data.pageSize;
    });

    await refreshEventHandler();
}

export async function changeViewModeEventHandler(data: {
    mode: FilesViewModeKind;
}): Promise<void> {
    state.fileExplorerMiniApp.filesViewMode.set(data.mode);
}

export async function changeActiveItemEventHandler(data: {
    activeItem: Option<string>;
}): Promise<void> {
    useStorage.fileExplorerMiniApp.activeItem.set(data.activeItem);

    if (data.activeItem && data.activeItem.length) {
        if (!state.appPanel.tabs.get().has(fileDetailsTab.id)) {
            appManager.addTab(fileDetailsTab);
        }
    } else {
        if (state.appPanel.tabs.get().has(fileDetailsTab.id)) {
            appManager.deleteTab(fileDetailsTab.id);
        }
    }
}

export async function changeSelectionEventHandler(data: {
    files: Option<AirFile[]>;
    selection: Option<string[]>;
}): Promise<void> {
    let files: AirFile[] = [];

    if (data.files !== null && data.files !== undefined) {
        files = data.files;
    } else {
        files = useStorage.files.filesToShow.get();
    }

    let urlSelection: string[] = [];

    if (data.selection !== null && data.selection !== undefined) {
        urlSelection = data.selection;
    } else {
        const selectionStr = router
            .getUrlEntities()
            .searchParams.get("selection");
        urlSelection = selectionStr ? selectionStr.split(",") : [];
    }

    if (urlSelection === null) {
        useStorage.fileExplorerMiniApp.selection.set(new Map());
    } else {
        const selection = new Map();
        urlSelection.forEach(i => {
            const file = files.find(f => f.hierarchy === i);
            if (file) {
                selection.set(file.hierarchy, file);
            }
        });
        useStorage.fileExplorerMiniApp.selection.set(selection);
    }
}
