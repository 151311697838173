import {
    PreviewBaseInfo,
    PreviewPort,
} from "../../application/preview/previewPort";
import { apiCall, handleNotOk, validateRes } from "../api/api";

export class PreviewAdapter implements PreviewPort {
    async getPreview(): Promise<PreviewBaseInfo> {
        const res = await apiCall("/api/admin/features/preview", {
            method: "GET",
        });

        return validateRes(res);
    }

    async updatePreview(previewBaseInfo: PreviewBaseInfo): Promise<void> {
        const res = await apiCall("/api/admin/features/preview", {
            method: "PUT",
            body: JSON.stringify(previewBaseInfo),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        });

        handleNotOk(res);
    }
}
