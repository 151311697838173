import React from "react";
import { PanelTab } from "../../models";
import AppNotifications from "./AppNotifications";

const appNotificationsTab: PanelTab = {
    id: "notifications",
    title: "Notifications",
    content: <AppNotifications />,
};

export default appNotificationsTab;
