import { RouteListenersManager } from "./routeListenersManager";
import { RouteParams, SearchParams, UrlEntities } from "./urlEntities";

export class Router<
    T extends {
        [key: string]: string;
    },
> {
    private readonly _appRoutes: T;
    private readonly _routesList: string[];
    private readonly _routeListenersManager: RouteListenersManager;

    constructor(appRoutes: T) {
        this._handleClick = this._handleClick.bind(this);
        this._updateHistory = this._updateHistory.bind(this);
        this._route = this._route.bind(this);
        this._retainSearch = this._retainSearch.bind(this);
        this.goTo = this.goTo.bind(this);
        this.goToWithHash = this.goToWithHash.bind(this);
        this.createRoute = this.createRoute.bind(this);
        this.redirect = this.redirect.bind(this);
        this.clearSearchByKey = this.clearSearchByKey.bind(this);
        this.isRouteExist = this.isRouteExist.bind(this);
        this.setSearchParams = this.setSearchParams.bind(this);
        this.removeSearchParam = this.removeSearchParam.bind(this);
        this.updateSearchParams = this.updateSearchParams.bind(this);
        this.getUrlEntities = this.getUrlEntities.bind(this);
        this.navigateToHref = this.navigateToHref.bind(this);

        this._appRoutes = appRoutes;
        this._routesList = Object.values(this._appRoutes);
        this._routeListenersManager = new RouteListenersManager();

        window.addEventListener("popstate", () => {
            this._routeListenersManager.notify(this.url);
        });

        document.addEventListener("click", this._handleClick);
    }

    get url(): URL {
        return new URL(window.location.href);
    }

    get routes(): T {
        return this._appRoutes;
    }

    get routesList(): string[] {
        return this._routesList;
    }

    get routeListenersManager(): RouteListenersManager {
        return this._routeListenersManager;
    }

    private _handleClick(event: MouseEvent): void {
        const target = event.target as HTMLElement;

        if (target) {
            const link = target.closest("a");
            if (
                link &&
                link.dataset.route &&
                !event.metaKey &&
                !event.ctrlKey &&
                !event.shiftKey &&
                !event.altKey
            ) {
                event.preventDefault();

                const href = link.getAttribute("href");

                if (href && href.length) {
                    this._route(new URL(href));
                }
            }
        }
    }

    private _updateHistory(
        url: URL,
        methodName: "pushState" | "replaceState",
        notify: boolean = false,
    ): void {
        window.history[methodName](
            {
                host: url.host,
                hostname: url.hostname,
                href: url.href,
                origin: url.origin,
                pathname: url.pathname,
                search: url.search,
            },
            "",
            url.href,
        );

        if (notify) {
            this._routeListenersManager.notify(url);
        }
    }

    private _route(url: URL): void {
        this._updateHistory(url, "pushState", true);
    }

    private _retainSearch(
        url: URL,
        routeParamsSearchKey: string,
        protect?: string[],
    ): void {
        if (protect !== undefined && protect.length > 0) {
            Array.from(url.searchParams.keys()).forEach(key => {
                if (!protect.includes(key) && key !== routeParamsSearchKey) {
                    url.searchParams.delete(key);
                }
            });
        } else {
            url.search = url.searchParams.get(routeParamsSearchKey) ?? "";
        }
    }

    goTo(routeParams: RouteParams): void {
        const href = this.createRoute(routeParams);
        this._route(new URL(href));
    }

    goToWithHash(routeParams: RouteParams, hash: string): void {
        const href = this.createRoute(routeParams);
        const url = new URL(href);
        url.hash = hash;
        this._route(url);
    }

    createRoute(routeParams: RouteParams): string {
        const url = this.url;

        if (routeParams.page && routeParams.page !== url.pathname) {
            url.pathname = routeParams.page;
        }

        const items = routeParams.search?.items ?? [];
        const append = routeParams.search?.append ?? false;
        const protect = routeParams.search?.protect;

        if (
            routeParams.page !== undefined ||
            (protect !== undefined && protect.length === 0)
        ) {
            url.search = "";
        } else if (protect !== undefined && protect.length > 0) {
            url.searchParams.forEach((_, key) => {
                this._retainSearch(url, key, protect);
            });
        }

        items.forEach(item => {
            if (item.value.length > 0) {
                if (append) {
                    url.searchParams.append(item.key, item.value);
                } else {
                    url.searchParams.set(item.key, item.value);
                }
            } else {
                url.searchParams.delete(item.key);
            }
        });

        url.hash = routeParams.hash ?? "";
        return url.href;
    }

    redirect(dest: string, params?: Record<string, string>): void {
        const url = new URL(window.location.origin + dest);

        if (params !== undefined) {
            Object.keys(params).forEach(key => {
                url.searchParams.append(key, params[key]);
            });
        }

        this._updateHistory(url, "replaceState", true);
    }

    clearSearchByKey(key: string): void {
        const url = this.url;
        url.searchParams.delete(key);
        this._route(url);
    }

    isRouteExist(key: string): boolean {
        const url = this.url;
        return url.searchParams.has(key);
    }

    setSearchParams(params: SearchParams[]): void {
        const url = this.url;

        for (const param of params) {
            url.searchParams.set(param.key, param.value);
        }

        this._route(url);
    }

    removeSearchParam(key: string, silent = false): void {
        const url = this.url;
        url.searchParams.delete(key);

        if (silent) {
            this._updateHistory(url, "pushState");
        } else {
            this._route(url);
        }
    }

    updateSearchParams(searchParams: URLSearchParams, silent = false): void {
        const url = this.url;
        url.search = searchParams.toString();

        if (silent) {
            this._updateHistory(url, "pushState");
        } else {
            this._route(url);
        }
    }

    getUrlEntities(): UrlEntities {
        const url = this.url;

        return {
            searchParams: url.searchParams,
            hash: url.hash.slice(1).split("/"),
        };
    }

    navigateToHref(href: string): void {
        this._updateHistory(new URL(href), "replaceState");
    }
}
