import {
    Button,
    Caption1,
    Checkbox,
    Field,
    Text,
    Link,
    makeStyles,
    shorthands,
    tokens,
    mergeClasses,
} from "@fluentui/react-components";
import { v4 } from "uuid";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { ArrowResetRegular } from "@fluentui/react-icons";
import React, { useCallback, useEffect } from "react";
import { useFacets } from "../../../../../application/useCases/useFacets";
import { state } from "../../../../state/stateAdapter";
import "./fileExplorerFilters.scss";
import Loader from "../../../../components/Loader/Loader";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { fileExplorerManager } from "../fileExplorerManager";
import {
    Facet,
    FacetValue,
    getFacetName,
} from "../../../../../domain/facet/facet";
import { router } from "../../../../router";
import { Filters } from "../../../../../domain/filter/filters";
import {
    dataParamsKeys,
    defaultDataParams,
} from "../../../../../domain/dataParams/dataParams";
import { useFiles } from "../../../../../application/useCases/useFiles";
import { Option } from "../../../../../domain/types/types";
import FileExplorerFiltersMorePanel from "./FileExplorerFiltersMorePanel/FileExplorerFiltersMorePanel";
import { FilterData } from "../../shared/types";

const useStyles = makeStyles({
    groupHeader: {
        ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStroke1),
        marginBottom: "10px",
        paddingBottom: "10px",
    },
    checkbox: {
        display: "grid",
    },
    moreBtn: {
        fontSize: "12px",
        "&:hover": {
            textDecorationLine: "none",
        },
    },
});

const countShownRows = 6;

type FilterProps = {
    facetItem: Facet;
    facetValue: FacetValue;
    toggleFilter: (
        filterGroupName: string | null,
        filterName: string | number | null,
    ) => void;
    activeFilters: Option<Filters>;
};

function Filter(props: FilterProps): JSX.Element {
    const classes = useStyles();
    if (
        props.facetItem.type === "EnumFacetSetting" ||
        props.facetItem.type === "RangeEnumFacetSetting"
    ) {
        return (
            <>
                {props.facetValue.values
                    .slice(0, countShownRows)
                    .map((i, index) => (
                        <Checkbox
                            key={i.name + index + i.count}
                            className={mergeClasses(
                                classes.checkbox,
                                "filters__checkbox",
                            )}
                            label={`${i.name} (${i.count})`}
                            disabled={i.count === 0}
                            onChange={(): void => {
                                const filterName =
                                    i.id !== undefined && i.id !== null
                                        ? i.id
                                        : i.name;
                                props.toggleFilter(
                                    props.facetItem.name,
                                    filterName,
                                );
                            }}
                            checked={
                                props.activeFilters !== null &&
                                props.activeFilters !== undefined &&
                                props.activeFilters.has(
                                    props.facetItem.name ?? "",
                                )
                                    ? props.activeFilters
                                          .get(props.facetItem.name ?? "")
                                          ?.includes(getFacetName(i))
                                    : false
                            }
                        />
                    ))}
            </>
        );
    }

    if (props.facetItem.type === "RangeFacetSetting") {
        const from =
            props.facetValue.from !== null
                ? new Date(props.facetValue.from)
                : undefined;
        const to =
            props.facetValue.to !== null
                ? new Date(props.facetValue.to)
                : undefined;

        const filterGroupFrom = `${props.facetItem.name}.from`;
        const filterGroupTo = `${props.facetItem.name}.to`;

        const formatDate = (date: Date): string => {
            const year = date.getFullYear();
            let month: number | string = date.getMonth() + 1;
            let day: number | string = date.getDate();

            if (month < 10) {
                month = "0" + month;
            }
            if (day < 10) {
                day = "0" + day;
            }

            return year + "/" + month + "/" + day;
        };

        const onDateFromSelect = (date: Date | null | undefined): void => {
            if (date) {
                fileExplorerManager.updateDateFilter(
                    filterGroupFrom,
                    formatDate(date),
                );
            }
        };

        const onDateToSelect = (date: Date | null | undefined): void => {
            if (date) {
                fileExplorerManager.updateDateFilter(
                    filterGroupTo,
                    formatDate(date),
                );
            }
        };

        const valueFrom =
            props.activeFilters !== null && props.activeFilters !== undefined
                ? (props.activeFilters.get(filterGroupFrom) ?? [])[0]
                : undefined;

        const valueTo =
            props.activeFilters !== null && props.activeFilters !== undefined
                ? (props.activeFilters.get(filterGroupTo) ?? [])[0]
                : undefined;

        return (
            <div className="filters__range">
                <Field>
                    <DatePicker
                        minDate={from}
                        maxDate={to}
                        placeholder="Select a date from..."
                        value={valueFrom ? new Date(valueFrom) : null}
                        onSelectDate={onDateFromSelect}
                    />
                </Field>

                <Field>
                    <DatePicker
                        minDate={from}
                        maxDate={to}
                        placeholder="Select a date to..."
                        value={valueTo ? new Date(valueTo) : null}
                        onSelectDate={onDateToSelect}
                    />
                </Field>
            </div>
        );
    }

    return <></>;
}

function updateFilters(filtersToUpdate: FilterData[]): void {
    router.removeSearchParam(dataParamsKeys.page, true);
    useStorage.fileExplorerMiniApp.dataParams.update(s => {
        s.page = defaultDataParams.page;
    });
    fileExplorerManager.updateFilters(filtersToUpdate);
}

function FileExplorerFilters(): JSX.Element | null {
    const classes = useStyles();
    const facets = state.useState(useStorage.facets.facets);
    const activeFilters = state.useState(useStorage.files.activeFilters);
    const isFacetsQueryInProgress = state.useState(
        useStorage.facets.isQueryInProgress,
    );
    const isFilesQueryInProgress = state.useState(
        useStorage.files.isQueryInProgress,
    );
    const searchResult = state.useState(useStorage.files.searchResult);

    const openDialog = (name: string | null): void => {
        if (searchResult !== null && searchResult !== undefined) {
            const dialogFacetItem = facets.find(f => f.name === name);
            const dialogfacetValues = searchResult.facets.find(
                f => f.name === name,
            );
            const dialogCheckedValues =
                activeFilters !== null &&
                activeFilters !== undefined &&
                activeFilters.has(name ?? "")
                    ? activeFilters.get(name ?? "")
                    : [];

            const isOpenMorePanel =
                name !== null &&
                dialogCheckedValues !== undefined &&
                dialogFacetItem !== undefined &&
                dialogfacetValues !== undefined;

            if (isOpenMorePanel) {
                state.appDialogPanel.set(() => (
                    <FileExplorerFiltersMorePanel
                        key={name}
                        activeDialog={name}
                        facetItem={dialogFacetItem!}
                        facetValues={dialogfacetValues!}
                        closeDialog={closeDialog}
                        applyDialog={updateFilters}
                        values={dialogCheckedValues!}
                    />
                ));
            }
        }
    };

    const closeDialog = (): void => {
        state.appDialogPanel.set(null);
    };

    const toggleFilter = useCallback(
        (
            filterGroup: string | null,
            filterName: string | number | null,
        ): void => {
            if (filterGroup !== null && filterName !== null) {
                updateFilters([{ filterGroup, filterName }]);
            }
        },
        [],
    );

    const onFiltersClear = (): void => {
        router.removeSearchParam("page", true);
        fileExplorerManager.clearAllFilters();
    };

    useEffect(() => {
        if (useStorage.facets.facets.get().length === 0) {
            useFacets.fetch();
        }
    }, []);

    useEffect(() => {
        const activeFilters = useStorage.files.activeFilters.get();
        const searchQuery = useStorage.files.searchQuery.get();

        if (
            (activeFilters === null ||
                activeFilters === undefined ||
                activeFilters.size === 0) &&
            (searchQuery === null ||
                searchQuery === undefined ||
                searchQuery.length === 0)
        ) {
            useFiles.search({
                query: searchQuery ?? undefined,
                path:
                    useStorage.fileExplorerMiniApp.activeDirectory.get() ??
                    undefined,
                limit: useStorage.fileExplorerMiniApp.dataParams.get().pageSize,
                offset:
                    (useStorage.fileExplorerMiniApp.dataParams.get().page - 1) *
                    useStorage.fileExplorerMiniApp.dataParams.get().pageSize,
                sortKey:
                    useStorage.fileExplorerMiniApp.dataParams.get().sortKey,
                isSortDesc:
                    useStorage.fileExplorerMiniApp.dataParams.get().isSortDesc,
            });
        }
    }, []);

    if (isFacetsQueryInProgress) {
        return <Loader text="Getting facets..." />;
    }

    if (isFilesQueryInProgress) {
        return <Loader text="Getting filters..." />;
    }

    if (!searchResult) {
        return <Text>No search results</Text>;
    }

    return (
        <div className="filters">
            <header className={classes.groupHeader}>
                <Button
                    appearance="subtle"
                    icon={<ArrowResetRegular />}
                    disabled={
                        activeFilters === null ||
                        activeFilters === undefined ||
                        activeFilters.size === 0
                    }
                    onClick={onFiltersClear}
                >
                    Reset all filters
                </Button>
            </header>

            {facets.map(facetItem => {
                const facetValue = searchResult.facets.find(
                    f => f.name === facetItem.name,
                );

                if (facetValue) {
                    const countFacetValue = facetValue.values
                        ? facetValue.values.length
                        : 0;

                    return (
                        <div
                            key={facetItem.title}
                            className="filters__group-container"
                        >
                            <header className={classes.groupHeader}>
                                <Text weight="medium">{facetItem.title}</Text>
                            </header>
                            <div className={`filters__items-container`}>
                                <Filter
                                    key={v4()}
                                    facetItem={facetItem}
                                    facetValue={facetValue}
                                    toggleFilter={toggleFilter}
                                    activeFilters={activeFilters}
                                />
                            </div>
                            {countFacetValue > 6 ? (
                                <div className="filters__more">
                                    <Link
                                        inline
                                        as="button"
                                        className={classes.moreBtn}
                                        onClick={(): void => {
                                            openDialog(facetItem.name);
                                        }}
                                    >
                                        Show all
                                    </Link>
                                    <Caption1>
                                        {countFacetValue - countShownRows} more
                                    </Caption1>
                                </div>
                            ) : null}
                        </div>
                    );
                }
            })}
        </div>
    );
}

export default FileExplorerFilters;
