import React, {
    useState,
    RefObject,
    useMemo,
    useCallback,
    useEffect,
} from "react";
import SeekBar from "../../../SeekBar/SeekBar";
import { Subtitle1, Caption1, Button } from "@fluentui/react-components";
import { ChevronDownRegular, ChevronUpRegular } from "@fluentui/react-icons";
import ExtendedViewDescription from "../ExtendedViewDescription/ExtendedViewDescription";
import ExtendedViewOverflow from "../ExtendedViewOverflow/ExtendedViewOverflow";
import ExtendedViewEmptySearch from "../ExtendedViewEmptySearch/ExtendedViewEmptySearch";
import { ExtendedViewData } from "../../../models";
import { searchData, sortUniqData } from "../../../utils";
import Tag from "../../../../Tag/Tag";
import { state } from "../../../../../state/stateAdapter";
import { videoExtendedLocalState } from "../../videoExtendedViewLocalState";
import { useViewExtendedStyles } from "../../VideoExtendedView";

export type ExtendedViewGroupProps = {
    title: string;
    data: ExtendedViewData[];
    isOverflow: boolean;
    countOverflow: number;
};

export type ExtendedViewGroupTagsProps = {
    duration: number;
    title: string;
    videoRef: RefObject<HTMLVideoElement>;
    groups: ExtendedViewGroupProps[];
};

function getFistItemFromGroups(
    groups: ExtendedViewGroupProps[],
): ExtendedViewData | undefined {
    if (groups.length === 0) {
        return undefined;
    }

    const indexGroup = groups.findIndex(g => g.data.length > 0);

    if (indexGroup !== -1) {
        return groups[indexGroup].data[0];
    }

    return undefined;
}

function ExtendedViewGroupTags(
    props: ExtendedViewGroupTagsProps,
): JSX.Element | null {
    const classesViewExtended = useViewExtendedStyles();
    const [showAll, setShowAll] = useState(false);
    const query = state.useState(videoExtendedLocalState.query);
    const [totalOverflowCount, setTotalOverflowCount] = useState(0);
    const sortedGroups = useMemo(
        () =>
            props.groups.map(g => ({
                ...g,
                data: sortUniqData(g.data),
            })),
        [props.groups],
    );
    const groups = sortedGroups.map(g => ({
        ...g,
        data: searchData(g.data, query),
    }));
    const totalCount = groups.reduce((prev, cur) => prev + cur.data.length, 0);
    const groupStateInit = groups.map(g => ({
        title: g.title,
        isOverflow: true,
        countOverflow: 0,
    }));
    const [groupState, setGroupState] = useState(groupStateInit);
    const [tempQuery, setTempQuery] = useState(query);
    const [activeItem, setActiveItem] = useState<ExtendedViewData | undefined>(
        getFistItemFromGroups(groups),
    );

    const toggleOverflow = useCallback((): void => {
        const newGroupState = groupState.map(g => ({
            ...g,
            isOverflow: showAll,
        }));

        setGroupState(newGroupState);
        setShowAll(!showAll);
    }, [showAll, groupState]);

    const toggleOverflowGroup = useCallback(
        (indexGroup: number): void => {
            const newGroupState = [...groupState];
            newGroupState[indexGroup].isOverflow =
                !groupState[indexGroup].isOverflow;

            const allShowed = newGroupState.reduce(
                (p, g) =>
                    (p && g.countOverflow === 0) || g.isOverflow === false,
                true,
            );

            setGroupState(newGroupState);
            if (allShowed) {
                setShowAll(true);
            }
        },
        [groupState],
    );

    const onSetHiddenCount = useCallback(
        (count: number, indexGroup: number): void => {
            const prevCount = groupState[indexGroup].countOverflow;

            if (count !== prevCount) {
                const newGroupState = [...groupState];
                groupState[indexGroup].countOverflow = count;
                groupState[indexGroup].isOverflow = true;

                setGroupState(newGroupState);
                setTotalOverflowCount(
                    newGroupState.reduce((p, c) => p + c.countOverflow, 0),
                );
            }
        },
        [groupState],
    );

    useEffect(() => {
        if (tempQuery !== query && groups.length > 0) {
            setActiveItem(getFistItemFromGroups(groups));
            setTempQuery(query);
        }
    }, [tempQuery, groups, query]);

    if (props.groups.length && query.length > 0 && totalCount === 0) {
        return <ExtendedViewEmptySearch title={props.title} />;
    }

    if (groups.length === 0 || totalCount === 0) {
        return null;
    }

    return (
        <div className="extended-view__section">
            <div className="extended-view__section-title">
                <Subtitle1 className={classesViewExtended.title}>
                    {totalCount} {props.title}
                </Subtitle1>
                {totalOverflowCount > 0 && (
                    <Button
                        onClick={toggleOverflow}
                        appearance="transparent"
                        icon={
                            showAll ? (
                                <ChevronUpRegular />
                            ) : (
                                <ChevronDownRegular />
                            )
                        }
                    />
                )}
            </div>
            {groups.map((group, indexGroup) =>
                group.data.length > 0 ? (
                    <div
                        className="extended-view__group"
                        key={group.title + indexGroup}
                    >
                        <Caption1>
                            {group.data.length} {group.title}
                        </Caption1>

                        <ExtendedViewOverflow
                            data={group.data}
                            isOverflow={groupState[indexGroup].isOverflow}
                            toggleOverflow={(): void =>
                                toggleOverflowGroup(indexGroup)
                            }
                            onSetHiddenCount={(count: number): void =>
                                onSetHiddenCount(count, indexGroup)
                            }
                            renderItem={(i): JSX.Element => (
                                <Tag
                                    item={i}
                                    isActive={i.value === activeItem?.value}
                                    onClick={(): void => {
                                        setActiveItem(i);
                                    }}
                                />
                            )}
                        />
                    </div>
                ) : null,
            )}

            {activeItem && activeItem.appearances && (
                <>
                    <ExtendedViewDescription
                        item={activeItem}
                        duration={props.duration}
                    />
                    <SeekBar
                        videoRef={props.videoRef}
                        duration={props.duration}
                        appearances={activeItem.appearances}
                    />
                </>
            )}
        </div>
    );
}

export default ExtendedViewGroupTags;
