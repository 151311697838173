import {
    Button,
    Input,
    makeStyles,
    mergeClasses,
    tokens,
} from "@fluentui/react-components";
import { Dismiss16Regular, SearchRegular } from "@fluentui/react-icons";
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Option } from "../../../domain/types/types";

const useStyles = makeStyles({
    searchContainer: {
        position: "relative",
    },
    search: {
        backgroundColor: tokens.colorNeutralBackground6,
        maxWidth: "610px",
        width: "100%",
        "::after": {
            content: "none",
        },
    },
    searchFocus: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
});

type SearchProps = {
    query: Option<string>;
    place: string;
    onSearch: (tempQuery: string) => void;
    onDismiss: () => void;
};

function Search(props: SearchProps): JSX.Element {
    const classes = useStyles();
    const focusInputClassName = mergeClasses(
        classes.search,
        classes.searchFocus,
    );

    const [isSearchFocus, setIsSearchFocus] = useState(false);
    const [tempQuery, setTempQuery] = useState("");

    const onInputFocus = useCallback((): void => {
        setIsSearchFocus(true);
    }, []);

    const onInputBlur = useCallback((): void => {
        setIsSearchFocus(false);
    }, []);

    const onSearch = useCallback(
        (e: SyntheticEvent): void => {
            e.stopPropagation();
            props.onSearch(tempQuery);
        },
        [props, tempQuery],
    );

    const onDismiss = useCallback(
        (e: SyntheticEvent): void => {
            e.stopPropagation();
            if (props.query === null) {
                setTempQuery("");
            } else {
                props.onDismiss();
            }
        },
        [props],
    );

    const onChange = useCallback(
        (e: SyntheticEvent, data: { value: string }): void => {
            e.stopPropagation();
            setTempQuery(data.value);
            if (
                data.value.length === 0 &&
                props.query !== null &&
                props.query !== undefined &&
                props.query.length > 0
            ) {
                onDismiss(e);
            }
        },
        [onDismiss, props.query],
    );

    const onKeyUp = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>): void => {
            if (e.key.toLowerCase() === "enter" && tempQuery.length > 0) {
                onSearch(e);
            }
        },
        [onSearch, tempQuery.length],
    );

    useEffect(() => {
        setTempQuery(props.query ?? "");
    }, [props.query]);

    return (
        <div className={classes.searchContainer}>
            <Input
                contentBefore={
                    <>{tempQuery.length === 0 ? <SearchRegular /> : null}</>
                }
                contentAfter={
                    <>
                        {tempQuery.length > 0 ? (
                            <>
                                <Button
                                    appearance="subtle"
                                    icon={<SearchRegular />}
                                    onClick={onSearch}
                                    disabled={tempQuery.length === 0}
                                />

                                <Button
                                    appearance="subtle"
                                    icon={<Dismiss16Regular />}
                                    onClick={onDismiss}
                                />
                            </>
                        ) : null}
                    </>
                }
                type="search"
                placeholder={
                    props.place.length > 0
                        ? `Search in ${props.place}`
                        : "Search"
                }
                aeia-aria-label="Search"
                className={
                    isSearchFocus || tempQuery.length > 0
                        ? focusInputClassName
                        : classes.search
                }
                onChange={onChange}
                value={tempQuery}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                onKeyUp={onKeyUp}
            />
        </div>
    );
}

export default Search;
