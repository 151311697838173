import React from "react";
import ExtendedViewSection from "../ExtendedViewSection/ExtendedViewSection";
import PeopleDetails from "../../../PeopleDetails/PeopleDetails";
import { ExtendedViewData, ExtendedViewSectionParams } from "../../../models";
import People from "../../../../People/People";

function ExtendedViewPeople(
    props: ExtendedViewSectionParams,
): JSX.Element | null {
    return (
        <ExtendedViewSection
            title={props.title}
            data={props.data}
            duration={props.duration}
            videoRef={props.videoRef}
            renderItem={(
                i: ExtendedViewData,
                activeItem: ExtendedViewData,
                setActiveItem: (i: ExtendedViewData) => void,
            ): JSX.Element => (
                <People
                    name={i.value}
                    image={i.image}
                    isActive={
                        activeItem !== undefined && i.value === activeItem.value
                    }
                    onClick={(): void => {
                        setActiveItem(i);
                    }}
                />
            )}
            btnMoreView="people"
            renderDetails={(activeItem: ExtendedViewData): JSX.Element => (
                <PeopleDetails item={activeItem} duration={props.duration} />
            )}
        />
    );
}

export default ExtendedViewPeople;
