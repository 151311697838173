import {
    Toolbar,
    ToolbarButton,
    Button,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Overflow,
    OverflowItem,
    useOverflowMenu,
    useIsOverflowItemVisible,
    MenuItemProps,
    makeStyles,
    tokens,
    ToggleButton,
    ToolbarRadioButton,
    ToolbarRadioGroup,
    MenuItemRadio,
    MenuItemCheckbox,
    MenuButton,
    MenuDivider,
    useIsOverflowGroupVisible,
    ToolbarGroup,
    shorthands,
    Text,
    SplitButton,
    mergeClasses,
} from "@fluentui/react-components";
import React, { SyntheticEvent, useCallback, useEffect, useRef } from "react";
import {
    MoreHorizontal20Filled,
    FilterRegular,
    GridRegular,
    ListRegular,
    AddRegular,
    AddSquareMultipleRegular,
    ArrowResetRegular,
    DismissRegular,
    PanelRightContractRegular,
    PanelLeftExpandRegular,
    ArrowDownloadRegular,
    DatabaseSwitchRegular,
    TextBulletListSquareEditRegular,
    CloudRegular,
    DocumentRegular,
    FolderRegular,
    CameraRegular,
} from "@fluentui/react-icons";
import { FilesViewModeKind } from "../../../../models";
import { state } from "../../../../state/stateAdapter";
import { useStorage } from "../../../../../application/useCases/useStorage";
import { router, routes } from "../../../../router";
import { useCollections } from "../../../../../application/useCases/useCollections";
import Loader from "../../../../components/Loader/Loader";
import "./fileExplorerCommandBar.css";
import { Tier, tiers } from "../../../../models/tiers";
import { AirFile, isFile } from "../../../../../domain/airFile/airFile";
import {
    hasUserChangeTierPermissions,
    hasUserDownloadPermissions,
} from "../../../../../domain/user/user";
import { DownloadLocation } from "../../../../../domain/downloadLocation/downloadLocation";
import { appManager } from "../../../../appManager/appManager";
import { useFiles } from "../../../../../application/useCases/useFiles";
import {
    ItemsStorageValue,
    SelectionsStorageValue,
} from "../../../../../application/storageValues";
import { openDownloadDialog } from "../../shared/DownloadDialog/DownloadDialog";
import { openCreateCollectionDialog } from "../../shared/CreateCollectionDialog/CreateCollectionDialog";
import fileExplorerFiltersTab from "../FileExplorerFilters/fileExplorerFiltersTab";
import { fileExplorerLocalState } from "../fileExplorerLocalState";
import { openBulkTaggingPanel } from "../../shared/BulkTaggingPanel/BulkTaggingPanel";
import { useJobs } from "../../../../../application/useCases/useJobs";
import { formatSize, normalizePath } from "../../../../../utility";
import { useEventBus } from "../../../../../application/useCases/useEventBus";
import { UIEvents } from "../../../../uiEvents";
import { ContainerAirFileType } from "../../../../../domain/airFile/airFileType";
import { useCommonFluentuiStyles } from "../../../../styles/griffel";
import ImportInputs from "../../shared/ImportData/ImportInputs";
import { useUploader } from "../../../../../application/useCases/useUploader";
import { AddDataMethod } from "../../../../../domain/userFile/userFile";
import { UploadLocation } from "../../../../../domain/uploadLocation/uploadLocation";
import appNotificationsTab from "../../../AppNotifications/appNotificationTab";
import CreateFolderDialog from "../../shared/CreateFolderDialog/CreateFolderDialog";
import { defaultDataParams } from "../../../../../domain/dataParams/dataParams";

const useStyles = makeStyles({
    toolBar: {
        ...shorthands.overflow("hidden"),
        display: "grid",
        ...shorthands.padding("4px", "0"),
        gridAutoFlow: "column",
        justifyContent: "space-between",
        gridAutoColumns: "max-content",
    },
    toggleButton: {
        "& span": {
            color: `${tokens.colorNeutralForeground1}`,
        },

        ":hover": {
            "& span": {
                color: `${tokens.colorBrandForeground1}`,
            },
        },
    },
    collectionsList: {
        display: "grid",
        height: "calc(6 * 32px)",
        ...shorthands.overflow("scroll"),
    },
});

function onToggleFilters(): void {
    appManager.toggleTab(fileExplorerFiltersTab);
}

function onSetFilesViewMode(viewMode: FilesViewModeKind): void {
    router.setSearchParams([{ key: "viewMode", value: viewMode.toString() }]);
}

function onSelectListView(): void {
    onSetFilesViewMode(FilesViewModeKind.List);
}

function onSelectTileView(): void {
    onSetFilesViewMode(FilesViewModeKind.Tiles);
}

// TODO: refactor; duplicates (CollectionsCommandBar.tsx)
async function onChangeStorageTier(
    tier: Tier,
    filesSelectionFiles: AirFile[],
    onRefresh: () => Promise<void>,
): Promise<void> {
    const res = await useFiles.changeTier({
        ids: filesSelectionFiles.map(i => i.id),
        target: tier.key,
    });

    if (res !== undefined && res !== null) {
        await onRefresh();

        useStorage.jobs.dataParams.set({ ...defaultDataParams });
        await useJobs.fetchAll({
            limit: defaultDataParams.pageSize,
            offset: 0,
        });
    }
}

type DestinationsListProps = {
    destinations: DownloadLocation[];
    selectionSize: number;
    filesSelectionFiles: AirFile[];
    filesSelectionFilesSize: number;
    onSelectDestination(
        dest: DownloadLocation,
        filesSelectionFiles: AirFile[],
        filesSelectionFilesSize: number,
    ): void;
};

function DestinationsList(props: DestinationsListProps): JSX.Element {
    return (
        <>
            {props.destinations.map(dest => {
                const disabled = dest.limit
                    ? dest.limit > 0 && props.selectionSize > dest.limit
                    : false;

                return (
                    <MenuItem
                        key={dest.id}
                        disabled={disabled}
                        onClick={(): void =>
                            props.onSelectDestination(
                                dest,
                                props.filesSelectionFiles,
                                props.filesSelectionFilesSize,
                            )
                        }
                    >
                        {disabled
                            ? `${dest.title} (Limit ${formatSize(dest.limit!)})`
                            : dest.title}
                    </MenuItem>
                );
            })}
        </>
    );
}

interface ToolbarOverflowMenuItemProps extends Omit<MenuItemProps, "id"> {
    id: string;
    isCollectionsMenuOpen: boolean;
    collections: ItemsStorageValue;
    isCollectionsQueryInProgress: boolean;
    onRefresh: () => Promise<void>;
    filesSelectionFiles: AirFile[];
}

const ToolbarOverflowMenuItem: React.FC<
    ToolbarOverflowMenuItemProps
> = props => {
    const user = state.useState(useStorage.authorization.user);
    const { id, ...rest } = props;
    const filesSelection = state.useState(
        useStorage.fileExplorerMiniApp.selection,
    );
    const filesViewMode = state.useState(
        state.fileExplorerMiniApp.filesViewMode,
    );
    const isFilesQueryInProgress = state.useState(
        useStorage.files.isQueryInProgress,
    );
    const tabs = state.useState(state.appPanel.tabs, {
        notifyCondition: s => s.size !== state.appPanel.tabs.get().size,
    });
    const isOpen = state.useState(state.appPanel.isOpen);

    const isVisible = useIsOverflowItemVisible(id);
    const classes = useStyles();

    const filesSelectionFiles = Array.from(filesSelection.values());
    const filesSelectionFilesSize = filesSelectionFiles.reduce(
        (sum, obj) => sum + (obj.fileSize ?? 0),
        0,
    );

    if (isVisible) {
        return null;
    }

    if (id.includes("refresh")) {
        return (
            <MenuItem
                aria-label="Refresh files"
                icon={<ArrowResetRegular />}
                onClick={props.onRefresh}
                disabled={props.disabled || isFilesQueryInProgress}
            >
                Refresh
            </MenuItem>
        );
    }

    if (id.includes("collection")) {
        return (
            <>
                {filesSelection.size > 0 ? (
                    <Menu
                        openOnHover={false}
                        onOpenChange={(_e, data): void => {
                            if (!data.open) {
                                fileExplorerLocalState.isCollectionsMenuOpen.set(
                                    false,
                                );
                            }
                        }}
                    >
                        <MenuTrigger disableButtonEnhancement>
                            <MenuButton
                                appearance="subtle"
                                icon={<AddSquareMultipleRegular />}
                                onClick={(): void => {
                                    if (!props.isCollectionsMenuOpen) {
                                        fileExplorerLocalState.isCollectionsMenuOpen.set(
                                            true,
                                        );
                                        useCollections.fetch({
                                            isSortDesc: false,
                                            sortKey: "title",
                                        });
                                    }
                                }}
                            >
                                Add to collection
                            </MenuButton>
                        </MenuTrigger>

                        <MenuPopover>
                            <MenuList>
                                <CollectionsList
                                    collections={props.collections}
                                    isCollectionsQueryInProgress={
                                        props.isCollectionsQueryInProgress
                                    }
                                    filesSelection={filesSelection}
                                />

                                <Button
                                    appearance="subtle"
                                    icon={<AddRegular />}
                                    onClick={(): void => {
                                        openCreateCollectionDialog(
                                            props.filesSelectionFiles,
                                        );
                                    }}
                                >
                                    Create new collection
                                </Button>
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                ) : null}
            </>
        );
    }

    if (id.includes("selection")) {
        return (
            <>
                {filesSelection.size > 0 ? (
                    <MenuItem
                        icon={<DismissRegular />}
                        onClick={(): void => {
                            router.removeSearchParam("selection");
                        }}
                    >
                        Selected {filesSelection.size}
                    </MenuItem>
                ) : null}
            </>
        );
    }

    if (id.includes("bulkTagging")) {
        return (
            <>
                {filesSelection.size > 1 ? (
                    <MenuItem
                        icon={<TextBulletListSquareEditRegular />}
                        onClick={(): void =>
                            openBulkTaggingPanel(filesSelection)
                        }
                    >
                        Bulk tagging
                    </MenuItem>
                ) : null}
            </>
        );
    }

    if (id.includes("downloads")) {
        return (
            <>
                {user !== null &&
                    user !== undefined &&
                    hasUserDownloadPermissions(filesSelectionFiles, user) && (
                        <Menu openOnHover={false}>
                            <MenuTrigger disableButtonEnhancement>
                                <MenuItem icon={<ArrowDownloadRegular />}>
                                    Download
                                </MenuItem>
                            </MenuTrigger>

                            <MenuPopover>
                                <MenuList>
                                    <DestinationsList
                                        destinations={user!.downloadLocations}
                                        selectionSize={filesSelectionFilesSize}
                                        filesSelectionFiles={
                                            filesSelectionFiles
                                        }
                                        filesSelectionFilesSize={
                                            filesSelectionFilesSize
                                        }
                                        onSelectDestination={
                                            onSelectDestination
                                        }
                                    />
                                </MenuList>
                            </MenuPopover>
                        </Menu>
                    )}
            </>
        );
    }

    if (id.includes("changeStorageTier")) {
        return (
            <>
                {user !== null &&
                    user !== undefined &&
                    hasUserChangeTierPermissions(filesSelectionFiles, user) && (
                        <Menu openOnHover={false}>
                            <MenuTrigger disableButtonEnhancement>
                                <MenuItem icon={<DatabaseSwitchRegular />}>
                                    Change storage tier
                                </MenuItem>
                            </MenuTrigger>

                            <MenuPopover>
                                <MenuList>
                                    {tiers.map(i => (
                                        <MenuItem
                                            key={i.key}
                                            icon={i.iconProps}
                                            onClick={async (): Promise<void> => {
                                                onChangeStorageTier(
                                                    i,
                                                    filesSelectionFiles,
                                                    props.onRefresh,
                                                );
                                            }}
                                        >
                                            {i.text}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </MenuPopover>
                        </Menu>
                    )}
            </>
        );
    }

    if (id.includes("viewMode")) {
        return (
            <MenuList
                checkedValues={{
                    viewModeBtn2:
                        filesViewMode == FilesViewModeKind.List
                            ? ["tableView"]
                            : ["tileView"],
                }}
            >
                <MenuItemRadio
                    className={classes.toggleButton}
                    aria-label="Table view"
                    name="viewModeBtn2"
                    value="tableView"
                    icon={<ListRegular />}
                    onClick={onSelectListView}
                >
                    Table view
                </MenuItemRadio>
                <MenuItemRadio
                    className={classes.toggleButton}
                    aria-label="Tile view"
                    name="viewModeBtn2"
                    value="tileView"
                    icon={<GridRegular />}
                    onClick={onSelectTileView}
                >
                    Tile view
                </MenuItemRadio>
            </MenuList>
        );
    }

    if (id.includes("filters")) {
        return (
            <MenuList
                checkedValues={{
                    toggleFilters: tabs.has("filters") ? ["toggleFilters"] : [],
                }}
            >
                <MenuItemCheckbox
                    onClick={onToggleFilters}
                    icon={<FilterRegular />}
                    name="toggleFilters"
                    value="toggleFilters"
                >
                    Toggle filters
                </MenuItemCheckbox>
            </MenuList>
        );
    }

    if (id.includes("toggleAppPanel")) {
        return (
            <MenuItem
                onClick={(): void => {
                    state.appPanel.isOpen.set(!state.appPanel.isOpen.get());
                }}
                disabled={tabs.size === 0}
                icon={
                    isOpen ? (
                        <PanelRightContractRegular />
                    ) : (
                        <PanelLeftExpandRegular style={{ rotate: "-180deg" }} />
                    )
                }
            >
                {isOpen ? "Close" : "Open"} app panel
            </MenuItem>
        );
    }

    return <MenuItem {...(rest as MenuItemProps)}>Item {id}</MenuItem>;
};

const ToolbarMenuOverflowDivider: React.FC<{
    id: string;
}> = props => {
    const isGroupVisible = useIsOverflowGroupVisible(props.id);

    if (isGroupVisible === "visible") {
        return null;
    }

    return <MenuDivider />;
};

const OverflowMenu: React.FC<{
    itemIds: Array<Array<string>>;
    disabled: boolean;
    isCollectionsMenuOpen: boolean;
    collections: ItemsStorageValue;
    isCollectionsQueryInProgress: boolean;
    onRefresh: () => Promise<void>;
    filesSelectionFiles: AirFile[];
}> = ({
    itemIds,
    disabled,
    isCollectionsMenuOpen,
    collections,
    isCollectionsQueryInProgress,
    onRefresh,
    filesSelectionFiles,
}) => {
    const { ref, isOverflowing } = useOverflowMenu<HTMLButtonElement>();

    if (!isOverflowing) {
        return null;
    }

    return (
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                <Button
                    ref={ref}
                    icon={<MoreHorizontal20Filled />}
                    aria-label="More items"
                    appearance="subtle"
                />
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    {itemIds.map((group, groupIndx) => {
                        const isLast = groupIndx === itemIds.length - 1;
                        return (
                            <React.Fragment key={group.join()}>
                                {group.map(i => (
                                    <ToolbarOverflowMenuItem
                                        disabled={disabled}
                                        key={i}
                                        id={i}
                                        isCollectionsMenuOpen={
                                            isCollectionsMenuOpen
                                        }
                                        collections={collections}
                                        isCollectionsQueryInProgress={
                                            isCollectionsQueryInProgress
                                        }
                                        onRefresh={onRefresh}
                                        filesSelectionFiles={
                                            filesSelectionFiles
                                        }
                                    />
                                ))}

                                {!isLast && (
                                    <ToolbarMenuOverflowDivider
                                        id={`${groupIndx + 1}`}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};

type ToolbarOverflowItemProps = {
    children: JSX.Element;
    overflowId: string;
    overflowGroupId?: string;
};

function ToolbarOverflowItem(props: ToolbarOverflowItemProps): JSX.Element {
    return (
        <OverflowItem id={props.overflowId} groupId={props.overflowGroupId}>
            {props.children}
        </OverflowItem>
    );
}

type CollectionsListProps = {
    collections: ItemsStorageValue;
    isCollectionsQueryInProgress: boolean;
    filesSelection: SelectionsStorageValue;
};

function CollectionsList(props: CollectionsListProps): JSX.Element {
    if (props.isCollectionsQueryInProgress) {
        return (
            <div className="collection-loader">
                <Loader size="tiny" text="Fetching collections..." />
            </div>
        );
    }

    if (
        props.collections === null ||
        props.collections === undefined ||
        props.collections.length === 0
    ) {
        return (
            <MenuItem>
                <Text>No collections</Text>
            </MenuItem>
        );
    }

    return (
        <div className="collection-items">
            {props.collections
                .filter(
                    i =>
                        i.userName ===
                        useStorage.authorization.user.get()!.name,
                )
                .map(i => (
                    <MenuItem
                        key={i.id}
                        onClick={(_e): void => {
                            useCollections.add({
                                assets: Array.from(
                                    props.filesSelection.values(),
                                ).map(i => i.id),
                                id: i.id,
                                userName:
                                    useStorage.authorization.user.get()!.name,
                            });
                        }}
                        className="collection-item"
                    >
                        <Text truncate block wrap={false}>
                            {i.title}
                        </Text>
                    </MenuItem>
                ))}
        </div>
    );
}

function onSelectDestination(
    dest: DownloadLocation,
    filesSelectionFiles: AirFile[],
    filesSelectionFilesSize: number,
): void {
    useStorage.fileExplorerMiniApp.selectedDownloadLocation.set(dest);
    openDownloadDialog(dest, filesSelectionFiles, filesSelectionFilesSize);
}

function checkIsUploadHereDisabled(): boolean {
    const breadcrumbs = fileExplorerLocalState.breadcrumbsPath.get();
    const locations = useStorage.uploaderMiniApp.locations.get();

    return (
        locations === null ||
        breadcrumbs.length === 0 ||
        locations.findIndex(i => i.id === breadcrumbs[0].locationId) === -1 ||
        breadcrumbs.some(
            i => i.fileType === ContainerAirFileType.ExtractedArchive,
        )
    );
}

function getUploadDestination(): (UploadLocation & { path: string }) | null {
    const breadcrumbs = fileExplorerLocalState.breadcrumbsPath.get();

    if (breadcrumbs && breadcrumbs.length > 0) {
        return {
            id: breadcrumbs[0].locationId,
            title: breadcrumbs[0].title,
            path: breadcrumbs[breadcrumbs.length - 1].localPath ?? "",
        };
    }

    return null;
}

enum UploadType {
    Files,
    Folder,
    CameraCard,
}

enum UploadMethod {
    Instant,
    NewSubfolder,
    WithMetadata,
}

function UploadMethodList(props: {
    type: UploadType;
    onSelectMethod: (type: UploadType, method: UploadMethod) => void;
}): JSX.Element {
    return (
        <>
            <MenuList>
                <MenuItem
                    onClick={(): void => {
                        props.onSelectMethod(props.type, UploadMethod.Instant);
                    }}
                >
                    Instant upload
                </MenuItem>
                <MenuItem
                    onClick={(): void => {
                        props.onSelectMethod(
                            props.type,
                            UploadMethod.NewSubfolder,
                        );
                    }}
                >
                    Upload to new subfolder
                </MenuItem>
                <MenuItem
                    onClick={(): void => {
                        props.onSelectMethod(
                            props.type,
                            UploadMethod.WithMetadata,
                        );
                    }}
                >
                    Upload with metadata
                </MenuItem>
            </MenuList>
        </>
    );
}

function FileExplorerCommandBar(): JSX.Element {
    const user = state.useState(useStorage.authorization.user);
    const locations = state.useState(useStorage.uploaderMiniApp.locations);
    const filesSelection = state.useState(
        useStorage.fileExplorerMiniApp.selection,
    );
    const filesViewMode = state.useState(
        state.fileExplorerMiniApp.filesViewMode,
    );
    const tabs = state.useState(state.appPanel.tabs);
    const isOpen = state.useState(state.appPanel.isOpen);
    const collections = state.useState(useStorage.collections.list);
    const isCollectionsQueryInProgress = state.useState(
        useStorage.collections.isQueryInProgress,
    );
    const isCollectionsMenuOpen = state.useState(
        fileExplorerLocalState.isCollectionsMenuOpen,
    );
    const isFilesQueryInProgress = state.useState(
        useStorage.files.isQueryInProgress,
    );
    const uploaderFeatures = state.useState(useStorage.config.config, {
        properties: ["features.uploader"],
    }).features.uploader;

    useEffect(() => {
        console.log(useStorage.config.config.get());
    });

    const classes = useStyles();
    const commonClasses = useCommonFluentuiStyles();

    const filesSelectionFiles = Array.from(filesSelection.values());
    const filesSelectionFilesSize = filesSelectionFiles.reduce(
        (sum, obj) => sum + (obj.fileSize ?? 0),
        0,
    );
    const selectionText: string | null =
        filesSelection.size === 0
            ? null
            : filesSelectionFiles.some(i => !isFile(i))
            ? `Selected ${filesSelection.size} item(s)`
            : `Selected ${filesSelection.size} item(s) (${formatSize(
                  filesSelectionFilesSize,
              )})`;
    const isUploadHereDisabled = checkIsUploadHereDisabled();

    const onRefresh = useCallback(async (): Promise<void> => {
        await useEventBus.emit(UIEvents.FileExplorerRefresh, {});
    }, []);

    const isDownloadDisabled = filesSelectionFiles.some(
        item => item.storageTier === null,
    );

    const typeRef = useRef<UploadType | null>(null);
    const methodRef = useRef<UploadMethod | null>(null);

    const handleAddData = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        const type = typeRef.current;
        const method = methodRef.current;
        const files = event.target.files;

        if (files && files.length > 0 && type !== null) {
            const destination = getUploadDestination();

            if (destination !== null) {
                switch (method) {
                    case UploadMethod.Instant:
                        useUploader.instantUpload(
                            files,
                            type === UploadType.CameraCard
                                ? AddDataMethod.CameraCard
                                : AddDataMethod.Files,
                            { id: destination.id, title: destination.title },
                            destination.path,
                        );

                        if (
                            state.appPanel.tabs
                                .get()
                                .has(appNotificationsTab.id)
                        ) {
                            appManager.activateTab(appNotificationsTab);
                        } else {
                            appManager.addTab(appNotificationsTab);
                        }

                        if (!state.appPanel.isOpen.get()) {
                            state.appPanel.isOpen.set(true);
                        }

                        event.target.value = "";

                        break;
                    case UploadMethod.NewSubfolder:
                        state.appDialog.set(() => (
                            <CreateFolderDialog
                                createFolderCb={(folderName: string): void => {
                                    const path = `${normalizePath(
                                        destination.path.length > 0
                                            ? `${destination.path}/${folderName}`
                                            : folderName,
                                    )}/`;

                                    useUploader.instantUpload(
                                        files,
                                        type === UploadType.CameraCard
                                            ? AddDataMethod.CameraCard
                                            : AddDataMethod.Files,
                                        {
                                            id: destination.id,
                                            title: destination.title,
                                        },
                                        path,
                                    );

                                    if (
                                        state.appPanel.tabs
                                            .get()
                                            .has(appNotificationsTab.id)
                                    ) {
                                        appManager.activateTab(
                                            appNotificationsTab,
                                        );
                                    } else {
                                        appManager.addTab(appNotificationsTab);
                                    }

                                    if (!state.appPanel.isOpen.get()) {
                                        state.appPanel.isOpen.set(true);
                                    }

                                    event.target.value = "";
                                }}
                            />
                        ));

                        break;
                    case UploadMethod.WithMetadata:
                        useUploader.addData(
                            files,
                            type === UploadType.CameraCard
                                ? AddDataMethod.CameraCard
                                : AddDataMethod.Files,
                        );
                        router.redirect(routes.uploader, {
                            id: destination.id.toString(),
                            title: destination.title,
                            path: destination.path,
                        });
                        event.target.value = "";

                        break;
                }
            }
        }
    };

    const handleAddCameraCard = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        const files = event.target.files;
        const type = typeRef.current;

        if (files && files.length > 0 && type !== null) {
            const destination = getUploadDestination();

            if (destination !== null) {
                useUploader
                    .addData(files, AddDataMethod.CameraCard)
                    .then(() => {
                        router.redirect(routes.uploader, {
                            id: destination.id.toString(),
                            title: destination.title,
                            path: destination.path,
                        });
                        event.target.value = "";
                    });
            }
        }
    };

    const onSelectMethod = (type: UploadType, method: UploadMethod): void => {
        typeRef.current = type;
        methodRef.current = method;

        switch (type) {
            case UploadType.Files:
                selectFilesLabelRef.current?.click();
                break;
            case UploadType.Folder:
                selectFoldersLabelRef.current?.click();
                break;
            case UploadType.CameraCard:
                selectCameraCardLabelRef.current?.click();
                break;
        }
    };

    const selectFilesLabelRef = useRef<HTMLInputElement>(null);
    const selectFoldersLabelRef = useRef<HTMLInputElement>(null);
    const selectCameraCardLabelRef = useRef<HTMLInputElement>(null);

    const primaryActionButtonPropsFiles = {
        onClick: (e: SyntheticEvent): void => {
            onSelectMethod(UploadType.Files, UploadMethod.Instant);
            e.stopPropagation();
            e.preventDefault;
        },
    };
    const primaryActionButtonPropsFolder = {
        onClick: (e: SyntheticEvent): void => {
            onSelectMethod(UploadType.Folder, UploadMethod.Instant);
            e.stopPropagation();
            e.preventDefault;
        },
    };

    return (
        <>
            <Overflow padding={90}>
                <Toolbar
                    aria-label="File explorer Command bar"
                    defaultCheckedValues={{
                        viewModeBtn:
                            filesViewMode == FilesViewModeKind.List
                                ? ["tableView"]
                                : ["tileView"],
                    }}
                    className={classes.toolBar}
                >
                    <ToolbarGroup className="command-bar__main-container">
                        {locations !== null &&
                        locations.length > 0 &&
                        fileExplorerLocalState.breadcrumbsPath.get().length >
                            0 ? (
                            <>
                                <ToolbarOverflowItem
                                    overflowId="upload"
                                    overflowGroupId="1"
                                >
                                    <Menu
                                        positioning="below-end"
                                        hoverDelay={10000}
                                    >
                                        <MenuTrigger disableButtonEnhancement>
                                            <SplitButton
                                                appearance="subtle"
                                                icon={<CloudRegular />}
                                                disabled={isUploadHereDisabled}
                                                primaryActionButton={
                                                    primaryActionButtonPropsFiles
                                                }
                                                title={
                                                    isUploadHereDisabled
                                                        ? `Current user has no permission to upload to current location`
                                                        : undefined
                                                }
                                            >
                                                Upload here
                                            </SplitButton>
                                        </MenuTrigger>

                                        <MenuPopover>
                                            <MenuList>
                                                <Menu
                                                    positioning="below-end"
                                                    hoverDelay={10000}
                                                >
                                                    <MenuTrigger
                                                        disableButtonEnhancement
                                                    >
                                                        <SplitButton
                                                            appearance="subtle"
                                                            icon={
                                                                <DocumentRegular />
                                                            }
                                                            className={mergeClasses(
                                                                commonClasses.splitBtnMenuItem,
                                                                "btn-stretch",
                                                            )}
                                                            primaryActionButton={
                                                                primaryActionButtonPropsFiles
                                                            }
                                                        >
                                                            File(s)
                                                        </SplitButton>
                                                    </MenuTrigger>

                                                    <MenuPopover>
                                                        <UploadMethodList
                                                            onSelectMethod={
                                                                onSelectMethod
                                                            }
                                                            type={
                                                                UploadType.Files
                                                            }
                                                        />
                                                    </MenuPopover>
                                                </Menu>

                                                <Menu
                                                    positioning="below-end"
                                                    hoverDelay={10000}
                                                >
                                                    <MenuTrigger
                                                        disableButtonEnhancement
                                                    >
                                                        <SplitButton
                                                            appearance="subtle"
                                                            icon={
                                                                <FolderRegular />
                                                            }
                                                            className={mergeClasses(
                                                                commonClasses.splitBtnMenuItem,
                                                                "btn-stretch",
                                                            )}
                                                            primaryActionButton={
                                                                primaryActionButtonPropsFolder
                                                            }
                                                        >
                                                            Folder
                                                        </SplitButton>
                                                    </MenuTrigger>

                                                    <MenuPopover>
                                                        <UploadMethodList
                                                            onSelectMethod={
                                                                onSelectMethod
                                                            }
                                                            type={
                                                                UploadType.Folder
                                                            }
                                                        />
                                                    </MenuPopover>
                                                </Menu>

                                                {uploaderFeatures !== null &&
                                                uploaderFeatures.parsingCameraCard ? (
                                                    <Button
                                                        appearance="subtle"
                                                        icon={<CameraRegular />}
                                                        className="btn-strech"
                                                        onClick={(): void => {
                                                            onSelectMethod(
                                                                UploadType.CameraCard,
                                                                UploadMethod.WithMetadata,
                                                            );
                                                        }}
                                                    >
                                                        Camera card
                                                    </Button>
                                                ) : null}
                                            </MenuList>
                                        </MenuPopover>
                                    </Menu>
                                </ToolbarOverflowItem>

                                <ImportInputs
                                    selectFilesLabelRef={selectFilesLabelRef}
                                    selectFoldersLabelRef={
                                        selectFoldersLabelRef
                                    }
                                    selectCameraCardLabelRef={
                                        selectCameraCardLabelRef
                                    }
                                    onAddData={handleAddData}
                                    onAddCameraCard={handleAddCameraCard}
                                />
                            </>
                        ) : null}

                        {filesSelection.size > 0 ? (
                            <>
                                <ToolbarOverflowItem
                                    overflowId="collection"
                                    overflowGroupId="1"
                                >
                                    <div>
                                        <Menu
                                            openOnHover={false}
                                            onOpenChange={(_e, data): void => {
                                                if (!data.open) {
                                                    fileExplorerLocalState.isCollectionsMenuOpen.set(
                                                        false,
                                                    );
                                                }
                                            }}
                                        >
                                            <MenuTrigger
                                                disableButtonEnhancement
                                            >
                                                <MenuButton
                                                    appearance="subtle"
                                                    icon={
                                                        <AddSquareMultipleRegular />
                                                    }
                                                    onClick={(): void => {
                                                        if (
                                                            !isCollectionsMenuOpen
                                                        ) {
                                                            fileExplorerLocalState.isCollectionsMenuOpen.set(
                                                                true,
                                                            );
                                                            useCollections.fetch(
                                                                {
                                                                    isSortDesc:
                                                                        false,
                                                                    sortKey:
                                                                        "title",
                                                                },
                                                            );
                                                        }
                                                    }}
                                                >
                                                    Add to collection
                                                </MenuButton>
                                            </MenuTrigger>

                                            <MenuPopover>
                                                <MenuList>
                                                    <CollectionsList
                                                        collections={
                                                            collections ?? null
                                                        }
                                                        isCollectionsQueryInProgress={
                                                            isCollectionsQueryInProgress
                                                        }
                                                        filesSelection={
                                                            filesSelection
                                                        }
                                                    />

                                                    <Button
                                                        appearance="subtle"
                                                        icon={<AddRegular />}
                                                        onClick={(): void => {
                                                            openCreateCollectionDialog(
                                                                filesSelectionFiles,
                                                            );
                                                        }}
                                                    >
                                                        Create new collection
                                                    </Button>
                                                </MenuList>
                                            </MenuPopover>
                                        </Menu>
                                    </div>
                                </ToolbarOverflowItem>

                                {user !== null &&
                                    user !== undefined &&
                                    hasUserDownloadPermissions(
                                        filesSelectionFiles,
                                        user,
                                    ) && (
                                        <ToolbarOverflowItem
                                            overflowId="downloads"
                                            overflowGroupId="1"
                                        >
                                            <div>
                                                <Menu>
                                                    <MenuTrigger
                                                        disableButtonEnhancement
                                                    >
                                                        <MenuButton
                                                            appearance="subtle"
                                                            icon={
                                                                <ArrowDownloadRegular />
                                                            }
                                                            disabled={
                                                                isDownloadDisabled
                                                            }
                                                            title={
                                                                isDownloadDisabled
                                                                    ? "You cannot restore files with the unknown storage tier."
                                                                    : undefined
                                                            }
                                                        >
                                                            Download
                                                        </MenuButton>
                                                    </MenuTrigger>

                                                    <MenuPopover>
                                                        <MenuList>
                                                            <DestinationsList
                                                                destinations={
                                                                    user!
                                                                        .downloadLocations
                                                                }
                                                                selectionSize={
                                                                    filesSelectionFilesSize
                                                                }
                                                                filesSelectionFiles={
                                                                    filesSelectionFiles
                                                                }
                                                                filesSelectionFilesSize={
                                                                    filesSelectionFilesSize
                                                                }
                                                                onSelectDestination={
                                                                    onSelectDestination
                                                                }
                                                            />
                                                        </MenuList>
                                                    </MenuPopover>
                                                </Menu>
                                            </div>
                                        </ToolbarOverflowItem>
                                    )}

                                {user !== null &&
                                    user !== undefined &&
                                    hasUserChangeTierPermissions(
                                        filesSelectionFiles,
                                        user,
                                    ) && (
                                        <ToolbarOverflowItem
                                            overflowId="changeStorageTier"
                                            overflowGroupId="1"
                                        >
                                            <div>
                                                <Menu>
                                                    <MenuTrigger
                                                        disableButtonEnhancement
                                                    >
                                                        <MenuButton
                                                            appearance="subtle"
                                                            icon={
                                                                <DatabaseSwitchRegular />
                                                            }
                                                        >
                                                            Change storage tier
                                                        </MenuButton>
                                                    </MenuTrigger>

                                                    <MenuPopover>
                                                        <MenuList>
                                                            {tiers.map(i => (
                                                                <MenuItem
                                                                    key={i.key}
                                                                    icon={
                                                                        i.iconProps
                                                                    }
                                                                    onClick={async (): Promise<void> => {
                                                                        onChangeStorageTier(
                                                                            i,
                                                                            filesSelectionFiles,
                                                                            onRefresh,
                                                                        );
                                                                    }}
                                                                >
                                                                    {i.text}
                                                                </MenuItem>
                                                            ))}
                                                        </MenuList>
                                                    </MenuPopover>
                                                </Menu>
                                            </div>
                                        </ToolbarOverflowItem>
                                    )}

                                <ToolbarOverflowItem
                                    overflowId="selection"
                                    overflowGroupId="2"
                                >
                                    <ToolbarButton
                                        appearance="subtle"
                                        icon={<DismissRegular />}
                                        onClick={(): void => {
                                            router.removeSearchParam(
                                                "selection",
                                            );
                                        }}
                                    >
                                        {selectionText}
                                    </ToolbarButton>
                                </ToolbarOverflowItem>
                            </>
                        ) : null}

                        {filesSelection.size > 1 ? (
                            <ToolbarOverflowItem
                                overflowId="bulkTagging"
                                overflowGroupId="2"
                            >
                                <ToolbarButton
                                    appearance="subtle"
                                    icon={<TextBulletListSquareEditRegular />}
                                    onClick={(): void =>
                                        openBulkTaggingPanel(filesSelection)
                                    }
                                >
                                    Bulk tagging
                                </ToolbarButton>
                            </ToolbarOverflowItem>
                        ) : null}

                        <ToolbarOverflowItem
                            overflowId="refresh"
                            overflowGroupId="1"
                        >
                            <ToolbarButton
                                aria-label="Refresh files"
                                appearance="subtle"
                                icon={<ArrowResetRegular />}
                                onClick={onRefresh}
                                disabled={isFilesQueryInProgress}
                            >
                                Refresh
                            </ToolbarButton>
                        </ToolbarOverflowItem>
                    </ToolbarGroup>

                    <ToolbarGroup className="command-bar__far-container">
                        <ToolbarOverflowItem
                            overflowId="viewMode"
                            overflowGroupId="2"
                        >
                            <ToolbarRadioGroup>
                                <ToolbarRadioButton
                                    className={classes.toggleButton}
                                    aria-label="Table view"
                                    name="viewModeBtn"
                                    value="tableView"
                                    icon={<ListRegular />}
                                    appearance="subtle"
                                    onClick={onSelectListView}
                                    title="List view"
                                />
                                <ToolbarRadioButton
                                    className={classes.toggleButton}
                                    aria-label="Tile view"
                                    name="viewModeBtn"
                                    value="tileView"
                                    icon={<GridRegular />}
                                    appearance="subtle"
                                    onClick={onSelectTileView}
                                    title="Tiles view"
                                />
                            </ToolbarRadioGroup>
                        </ToolbarOverflowItem>

                        <ToolbarOverflowItem
                            overflowId="filters"
                            overflowGroupId="2"
                        >
                            <ToggleButton
                                className={classes.toggleButton}
                                aria-label="Filters"
                                icon={<FilterRegular />}
                                name="filters"
                                value="filters"
                                appearance="subtle"
                                onClick={onToggleFilters}
                                checked={tabs.has("filters")}
                                title="Filters"
                            />
                        </ToolbarOverflowItem>

                        <ToolbarOverflowItem
                            overflowId="toggleAppPanel"
                            overflowGroupId="3"
                        >
                            <ToolbarButton
                                name="toggleAppPanel"
                                onClick={(): void => {
                                    state.appPanel.isOpen.set(
                                        !state.appPanel.isOpen.get(),
                                    );
                                }}
                                disabled={tabs.size === 0}
                                title={isOpen ? "Close panel" : "Open panel"}
                                icon={
                                    isOpen ? (
                                        <PanelRightContractRegular />
                                    ) : (
                                        <PanelLeftExpandRegular
                                            style={{ rotate: "-180deg" }}
                                        />
                                    )
                                }
                            />
                        </ToolbarOverflowItem>
                    </ToolbarGroup>
                    <OverflowMenu
                        disabled={isFilesQueryInProgress}
                        itemIds={[
                            [
                                "upload",
                                "collection",
                                "downloads",
                                "changeStorageTier",
                            ],
                            ["selection", "bulkTagging", "refresh"],
                            ["viewMode", "filters"],
                            ["toggleAppPanel"],
                        ]}
                        isCollectionsMenuOpen={isCollectionsMenuOpen}
                        collections={collections}
                        isCollectionsQueryInProgress={
                            isCollectionsQueryInProgress
                        }
                        filesSelectionFiles={filesSelectionFiles}
                        onRefresh={onRefresh}
                    />
                </Toolbar>
            </Overflow>
        </>
    );
}

export default FileExplorerCommandBar;
