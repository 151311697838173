import { AdminPort } from "../../application/admin/adminPort";
import { ConfigKey } from "../../application/config/config";
import { apiCall, validateRes } from "../api/api";

export class AdminAdapter implements AdminPort {
    async updateConfigByKey<T>(key: ConfigKey, value: string): Promise<T> {
        if (key === ConfigKey.Branding) {
            const res = await apiCall("/api/admin/config/", {
                method: "PUT",
                body: value,
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            });

            return await validateRes(res);
        } else {
            const res = await apiCall(`/api/admin/features/${key}`, {
                method: "PUT",
                body: value,
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            });

            return await validateRes(res);
        }
    }
}
