export enum ProviderValue {
    Ams = "ams",
    Bitmovin = "bitmovin",
    Ffmpeg = "ffmpeg",
}

export type Provider = { value: ProviderValue; title: string };

export type PropertiesQuota = {
    type: string;
    value: number;
};

export type Properties = {
    storageName?: string;
    containerName?: string;
    connectionString?: string;
    apiKey?: string;
    quota: PropertiesQuota;
};

export type PreviewBaseInfo = {
    provider: ProviderValue;
    properties: Properties;
};

export type PreviewInfo = {
    provider: Provider;
    properties: Properties;
};

export type PreviewUpdatePreviewParams = {
    provider: ProviderValue;
    properties?: Properties;
};

export interface PreviewPort {
    getPreview(): Promise<PreviewBaseInfo>;

    updatePreview(previewBaseInfo: PreviewUpdatePreviewParams): Promise<void>;
}
