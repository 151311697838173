import {
    AdminMiniAppStoragePort,
    AirErrorsStoragePort,
    AppCommonStoragePort,
    AuthorizationStoragePort,
    CognitiveMetadataStoragePort,
    CollectionsMiniAppStoragePort,
    CollectionsStoragePort,
    ConfigStoragePort,
    DirectoryObjectsStoragePort,
    DownloadLocationStoragePort,
    FacetsStoragePort,
    FileExplorerMiniAppStoragePort,
    FilesStoragePort,
    IndexedLocationStoragePort,
    JobsStoragePort,
    NotificationsStoragePort,
    PreviewStoragePort,
    SharepointStoragePort,
    StoragePort,
    TagsStoragePort,
} from "../../application/ports";
import { UploaderStoragePort } from "../../application/uploader/ports";
import { AirArchiveStoragePort } from "../../domain/airArchive/airArchiveStoragePort";
import {
    activeDirectoryState,
    activeFiltersState,
    activeItemState,
    activeMiniAppState,
    adminActiveMenuState,
    adminDataParamsState,
    airErrorsState,
    allJobsDataParamsState,
    allJobsState,
    azureFilesDownloadLocationsState,
    collectionsDataParamsState,
    collectionsSelectedDownloadLocationState,
    collectionsSelectionState,
    configState,
    directoryObjectUsersState,
    downloadLocationItemsState,
    downloadLocationsState,
    facetsState,
    fileDetailsState,
    fileExplorerDataParamsState,
    fileExplorerSelectedDownloadLocationState,
    fileExplorerSelectionState,
    filePermissionsState,
    filesState,
    filesToShowState,
    idAdminState,
    idState,
    indexedLocationActiveItemState,
    indexedLocationsState,
    isAddAzureLocationQueryInProgressState,
    isAdminMiniAppStateInitState,
    isAllJobsQueryInProgressState,
    isCognitiveMetadataQueryInProgressState,
    isCollectionsMiniAppDataInitState,
    isCollectionsMiniAppStateInitState,
    isCollectionsQueryInProgressState,
    isCreateServiceAccountInProgressState,
    isDeleteServiceAccountInProgressState,
    isDirectoryObjectsQueryInProgressState,
    isEditAzureLocationQueryInProgressState,
    isEditByTypeQueryInProgressState,
    isEditServiceAccountInProgressState,
    isFacetsQueryInProgressState,
    isFetchAzureLocationQueryInProgressState,
    isFetchAzureLocationsQueryInProgressState,
    isFetchByTypeQueryInProgressState,
    isFileDetailsQueryInProgressState,
    isFileExplorerMiniAppDataInitState,
    isFileExplorerMiniAppStateInitState,
    isFilesQueryInProgressState,
    isLatestJobsQueryInProgressState,
    isNeedSearchState,
    isQueryDownloadLocationsInProgressState,
    isQueryInProgressState,
    isQueryIndexedLocationsInProgressState,
    isQueryTagsInProgressState,
    isSharepointQueryInProgressState,
    isSharingLinkQueryInProgressState,
    isTagGroupsQueryInProgressState,
    isTagsQueryInProgressState,
    itemState,
    latestJobsState,
    listState,
    metadataState,
    notificationsState,
    searchQueryState,
    searchResultState,
    sessionState,
    sharingItemState,
    sharingLinkState,
    siteDataState,
    tagGroupsState,
    tagsGroupsState,
    tagsListState,
    tagsState,
    typeAdminState,
    userState,
    validFilesState,
    tagsTotalState,
    tagsSearchQueryState,
    selectedTagsState,
    isQueryTagsListInProgressState,
    isFetchLocationsQueryInProgressState,
    isFetchUploaderFeaturesQueryInProgressState,
    locationsState,
    userFilesState,
    isAddDataQueryInProgressState,
    uploadState,
    isFetchDestinationFoldersQueryInProgressState,
    ignoredUserFilesState,
    archiveConfigState,
    isFetchConfigQueryInProgressState,
    isUpdateConfigQueryInProgressState,
    fileExploereContextState,
    collectionContextState,
    uploaderContextState,
    isDownloadByLinkInProgressState,
    jobsMapState,
    excludeSignalRJobsState,
    isCreateQueryInProgressState,
    isGetSharingDetailsQueryInProgressState,
    isGetFilesQueryInProgressState,
    isGetDownloadLinkQueryInProgressState,
    isGetFilesDetailsQueryInProgressState,
    sharingDetailsState,
    sharingPasswordState,
    sharedFilesState,
    sharingFileDetailsState,
    sharingShareUrlState,
    sharingDownloadLinkState,
    sharingIsDownloadAllQueryInProgressState,
    sharingFilesToShowState,
    sharingIsAccessGrantedState,
    isGetTiersQueryInProgressState,
    isGetPreviewQueryInProgressState,
    isUpdatePreviewQueryInProgressState,
    adminIsUpdateShareLinkUIConfigurationQueryInProgressState,
    adminIsUpdateBrandingConfigQueryInProgressState,
    adminIsUpdateConfigQueryInProgressState,
} from "./storageService";
import { StorageItemPort } from "../../application/storageItemPort";
import { SharingStoragePort } from "../../application/sharing/sharingStoragePort";
import { AdminStoragePort } from "../../application/admin/adminStoragePort";

export class StorageAdapter implements StoragePort {
    readonly appCommon: AppCommonStoragePort = {
        activeMiniApp: activeMiniAppState,
    };

    readonly authorization: AuthorizationStoragePort = {
        session: sessionState,
        user: userState,
    };

    readonly cognitiveMetadata: CognitiveMetadataStoragePort = {
        isQueryInProgress: isCognitiveMetadataQueryInProgressState,
        metadata: metadataState,
    };

    readonly collections: CollectionsStoragePort = {
        id: idState,
        isQueryInProgress: isCollectionsQueryInProgressState,
        item: itemState,
        list: listState,
    };

    readonly collectionsMiniApp: CollectionsMiniAppStoragePort = {
        isDataInit: isCollectionsMiniAppDataInitState,
        isStateInit: isCollectionsMiniAppStateInitState,
        selectedDownloadLocation: collectionsSelectedDownloadLocationState,
        dataParams: collectionsDataParamsState,
        selection: collectionsSelectionState,
        context: collectionContextState,
    };

    readonly adminMiniApp: AdminMiniAppStoragePort = {
        id: idAdminState,
        type: typeAdminState,
        isStateInit: isAdminMiniAppStateInitState,
        activeMenu: adminActiveMenuState,
        dataParams: adminDataParamsState,
    };

    readonly directoryObjects: DirectoryObjectsStoragePort = {
        directoryObjectUsers: directoryObjectUsersState,
        isQueryInProgress: isDirectoryObjectsQueryInProgressState,
    };

    readonly airErrors: AirErrorsStoragePort = airErrorsState;

    readonly facets: FacetsStoragePort = {
        facets: facetsState,
        isQueryInProgress: isFacetsQueryInProgressState,
    };

    readonly fileExplorerMiniApp: FileExplorerMiniAppStoragePort = {
        activeDirectory: activeDirectoryState,
        activeItem: activeItemState,
        isDataInit: isFileExplorerMiniAppDataInitState,
        isNeedSearch: isNeedSearchState,
        isStateInit: isFileExplorerMiniAppStateInitState,
        selectedDownloadLocation: fileExplorerSelectedDownloadLocationState,
        dataParams: fileExplorerDataParamsState,
        selection: fileExplorerSelectionState,
        context: fileExploereContextState,
    };

    readonly files: FilesStoragePort = {
        activeFilters: activeFiltersState,
        fileDetails: fileDetailsState,
        filePermissions: filePermissionsState,
        files: filesState,
        filesToShow: filesToShowState,
        isFileDetailsQueryInProgress: isFileDetailsQueryInProgressState,
        isQueryInProgress: isFilesQueryInProgressState,
        isSharingLinkQueryInProgress: isSharingLinkQueryInProgressState,
        searchQuery: searchQueryState,
        searchResult: searchResultState,
        sharingItem: sharingItemState,
        sharingLink: sharingLinkState,
        validFiles: validFilesState,
        tagGroups: tagGroupsState,
        isTagGroupsQueryInProgress: isTagGroupsQueryInProgressState,
        tags: tagsState,
        isTagsQueryInProgress: isTagsQueryInProgressState,
        isGetTiersQueryInProgress: isGetTiersQueryInProgressState,
    };

    readonly jobs: JobsStoragePort = {
        latestJobs: latestJobsState,
        allJobs: allJobsState,
        isLatestJobsQueryInProgress: isLatestJobsQueryInProgressState,
        isAllJobsQueryInProgress: isAllJobsQueryInProgressState,
        dataParams: allJobsDataParamsState,
        isDownloadByLinkInProgress: isDownloadByLinkInProgressState,
        jobsMap: jobsMapState,
    };

    readonly sharepoint: SharepointStoragePort = {
        isQueryInProgress: isSharepointQueryInProgressState,
        siteData: siteDataState,
    };

    readonly notifications: NotificationsStoragePort = notificationsState;

    readonly config: ConfigStoragePort = {
        config: configState,
        isQueryInProgress: isQueryInProgressState,
    };

    readonly indexedLocation: IndexedLocationStoragePort = {
        activeItem: indexedLocationActiveItemState,
        isQueryInProgress: isQueryIndexedLocationsInProgressState,
        indexedLocations: indexedLocationsState,
    };

    readonly downloadLocation: DownloadLocationStoragePort = {
        isFetchDownloadLocationQueryInProgress:
            isQueryDownloadLocationsInProgressState,
        downloadLocationsList: downloadLocationsState,
        azureFilesDownloadLocations: azureFilesDownloadLocationsState,
        isFetchAzureLocationsQueryInProgress:
            isFetchAzureLocationsQueryInProgressState,
        isFetchAzureLocationQueryInProgress:
            isFetchAzureLocationQueryInProgressState,
        isEditAzureLocationQueryInProgress:
            isEditAzureLocationQueryInProgressState,
        isAddAzureLocationQueryInProgress:
            isAddAzureLocationQueryInProgressState,
        isFetchByTypeQueryInProgress: isFetchByTypeQueryInProgressState,
        isEditByTypeQueryInProgress: isEditByTypeQueryInProgressState,
        isCreateServiceAccountInProgress: isCreateServiceAccountInProgressState,
        isEditServiceAccountInProgress: isEditServiceAccountInProgressState,
        isDeleteServiceAccountInProgress: isDeleteServiceAccountInProgressState,
        downloadLocationItems: downloadLocationItemsState,
    };

    readonly tags: TagsStoragePort = {
        isQueryInProgress: isQueryTagsInProgressState,
        isQueryTagsInProgress: isQueryTagsListInProgressState,
        groups: tagsGroupsState,
        tags: tagsListState,
        totalTags: tagsTotalState,
        searchQuery: tagsSearchQueryState,
        selectedTags: selectedTagsState,
    };

    readonly uploaderMiniApp: UploaderStoragePort = {
        isFetchLocationsQueryInProgress: isFetchLocationsQueryInProgressState,
        locations: locationsState,
        isFetchUploaderFeaturesQueryInProgress:
            isFetchUploaderFeaturesQueryInProgressState,
        userFiles: userFilesState,
        isAddDataQueryInProgress: isAddDataQueryInProgressState,
        upload: uploadState,
        isFetchDestinationFoldersQueryInProgress:
            isFetchDestinationFoldersQueryInProgressState,
        ignoredUserFiles: ignoredUserFilesState,
        context: uploaderContextState,
    };

    readonly airArchive: AirArchiveStoragePort = {
        config: archiveConfigState,
        isFetchConfigQueryInProgress: isFetchConfigQueryInProgressState,
        isUpdateConfigQueryInProgress: isUpdateConfigQueryInProgressState,
    };

    // TODO: Temp solution (excludeSignalR)
    readonly excludeSignalRJobs: StorageItemPort<boolean> =
        excludeSignalRJobsState;

    readonly sharing: SharingStoragePort = {
        isCreateQueryInProgress: isCreateQueryInProgressState,
        isGetSharingDetailsQueryInProgress:
            isGetSharingDetailsQueryInProgressState,
        isGetFilesQueryInProgress: isGetFilesQueryInProgressState,
        isGetDownloadLinkQueryInProgress: isGetDownloadLinkQueryInProgressState,
        isGetFileDetailsQueryInProgress: isGetFilesDetailsQueryInProgressState,
        isDownloadAllQueryInProgress: sharingIsDownloadAllQueryInProgressState,
        sharingDetails: sharingDetailsState,
        password: sharingPasswordState,
        sharedFiles: sharedFilesState,
        fileDetails: sharingFileDetailsState,
        shareUrl: sharingShareUrlState,
        downloadLink: sharingDownloadLinkState,
        filesToShow: sharingFilesToShowState,
        isAccessGranted: sharingIsAccessGrantedState,
    };

    readonly preview: PreviewStoragePort = {
        isGetPreviewQueryInProgress: isGetPreviewQueryInProgressState,
        isUpdatePreviewQueryInProgress: isUpdatePreviewQueryInProgressState,
    };

    readonly admin: AdminStoragePort = {
        isUpdateShareLinkUIConfigQueryInProgress:
            adminIsUpdateShareLinkUIConfigurationQueryInProgressState,
        isUpdateBrandingConfigQueryInProgress:
            adminIsUpdateBrandingConfigQueryInProgressState,
        isUpdateConfigQueryInProgress: adminIsUpdateConfigQueryInProgressState,
    };
}
