import { StoragePort } from "../../../../application/ports";
import { useStorage } from "../../../../application/useCases/useStorage";
import { FilesViewModeKind } from "../../../models";
import { router, routes } from "../../../router";
import { state } from "../../../state/stateAdapter";
import { StatePort } from "../../../state/statePort";
import {
    DataParams,
    defaultDataParams,
} from "../../../../domain/dataParams/dataParams";
import { UIEvents } from "../../../uiEvents";
import { useEventBus } from "../../../../application/useCases/useEventBus";

type CollectionsUrlParams = DataParams & {
    id: string | null;
    viewMode: FilesViewModeKind;
    activeItem: string | null;
    selection: string[] | null;
};

class CollectionsManager {
    private readonly _storagePort: StoragePort;
    private readonly _statePort: StatePort;

    constructor(storagePort: StoragePort, statePort: StatePort) {
        this._storagePort = storagePort;
        this._statePort = statePort;
    }

    private _prevCollectionsUrlParams: CollectionsUrlParams = {
        pageSize: defaultDataParams.pageSize,
        isSortDesc: false,
        page: defaultDataParams.page,
        id: null,
        viewMode: FilesViewModeKind.List,
        activeItem: null,
        selection: null,
    };

    parseUrl(url: URL): CollectionsUrlParams {
        const searchParams = url.searchParams;

        const pageSize = searchParams.get("pageSize");
        const page = searchParams.get("page");
        const sortKey = searchParams.get("sortKey");
        const isSortDesc = searchParams.get("isSortDesc");
        const viewMode = searchParams.get("viewMode");
        const id = searchParams.get("id");
        const activeItem = searchParams.get("activeItem");
        const selection = searchParams.get("selection");

        return {
            pageSize: pageSize
                ? parseInt(pageSize)
                : defaultDataParams.pageSize,
            page: page ? parseInt(page) : defaultDataParams.page,
            sortKey: sortKey ?? undefined,
            isSortDesc: isSortDesc !== null ? isSortDesc === "true" : false,
            viewMode:
                viewMode !== null ? parseInt(viewMode) : FilesViewModeKind.List,
            id,
            activeItem: activeItem,
            selection: selection ? selection.split(",") : null,
        };
    }

    updateState(url: URL): void {
        const urlParams = this.parseUrl(url);

        if (url.pathname === routes.collections) {
            this.updateContext(url.href);

            this._storagePort.collectionsMiniApp.dataParams.update(s => {
                if (s.pageSize !== urlParams.pageSize) {
                    s.pageSize = urlParams.pageSize;
                }

                if (s.page !== urlParams.page) {
                    s.page = urlParams.page;
                }

                if (s.isSortDesc !== urlParams.isSortDesc) {
                    s.isSortDesc = urlParams.isSortDesc;
                }

                if (s.sortKey !== urlParams.sortKey) {
                    s.sortKey = urlParams.sortKey;
                }
            });

            if (
                urlParams.viewMode !==
                this._statePort.collectionsMiniApp.filesViewMode.get()
            ) {
                this._statePort.collectionsMiniApp.filesViewMode.set(
                    urlParams.viewMode,
                );
            }

            this._storagePort.collections.id.set(urlParams.id);

            this._storagePort.fileExplorerMiniApp.activeItem.set(
                urlParams.activeItem,
            );

            useEventBus.emit(UIEvents.CollectionsChangeSelection, {
                selection: urlParams.selection,
                files: useStorage.files.files.get(),
            });
        }
    }

    async init(): Promise<void> {
        this.updateState(router.url);
    }

    updateContext(href: string): void {
        this._storagePort.collectionsMiniApp.context.set(href);
    }
}

export const collectionsManager = new CollectionsManager(useStorage, state);
