import { PreviewAdapter } from "../../infrastructure/preview/previewAdapter";
import { PreviewService } from "../preview/previewService";
import { useError } from "./useError";
import { useNotification } from "./useNotification";
import { useStorage } from "./useStorage";

export const usePreview = new PreviewService(
    new PreviewAdapter(),
    useStorage,
    useError,
    useNotification,
);
