import { Option } from "../../domain/types/types";
import { AirErrorKind } from "../airError/airError";
import { AirErrorService } from "../airError/airErrorService";
import { NotificationService } from "../notification/notificationService";
import { StoragePort } from "../ports";
import {
    PreviewBaseInfo,
    PreviewPort,
    PreviewUpdatePreviewParams,
} from "./previewPort";

export class PreviewService {
    private readonly _previewAdapter: PreviewPort;
    private readonly _storageAdapter: StoragePort;
    private readonly _errorService: AirErrorService;
    private readonly _notificationService: NotificationService;

    constructor(
        previewAdapter: PreviewPort,
        storageAdapter: StoragePort,
        errorService: AirErrorService,
        notificationService: NotificationService,
    ) {
        this._previewAdapter = previewAdapter;
        this._storageAdapter = storageAdapter;
        this._errorService = errorService;
        this._notificationService = notificationService;
    }

    async getPreview(): Promise<Option<PreviewBaseInfo>> {
        try {
            this._errorService.clear(AirErrorKind.GetPreview);
            this._storageAdapter.preview.isGetPreviewQueryInProgress.set(true);

            const res = await this._previewAdapter.getPreview();

            this._storageAdapter.preview.isGetPreviewQueryInProgress.set(false);

            return res;
        } catch (error) {
            this._storageAdapter.preview.isGetPreviewQueryInProgress.set(false);

            const airError = this._errorService.createAirError(
                AirErrorKind.GetPreview,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }

    async updatePreview(
        previewBaseInfo: PreviewUpdatePreviewParams,
    ): Promise<boolean> {
        try {
            this._errorService.clear(AirErrorKind.UpdatePreview);
            this._storageAdapter.preview.isUpdatePreviewQueryInProgress.set(
                true,
            );

            await this._previewAdapter.updatePreview(previewBaseInfo);

            this._storageAdapter.preview.isUpdatePreviewQueryInProgress.set(
                false,
            );

            return true;
        } catch (error) {
            this._storageAdapter.preview.isUpdatePreviewQueryInProgress.set(
                false,
            );

            const airError = this._errorService.createAirError(
                AirErrorKind.UpdatePreview,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);

            return false;
        }
    }
}
