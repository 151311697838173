import { Theme } from "@fluentui/react-theme";

export enum AppPanelKind {
    Notifications,
    Settings,
    Filters,
    Details,
    None,
}

export enum FilesViewModeKind {
    List,
    Tiles,
}

export type AppTheme = {
    theme: Theme;
    isDark: boolean;
};

export type PanelTab = {
    id: string;
    title: string;
    content: JSX.Element;
    onClose?: () => void;
};

export enum AdminActiveMenuKind {
    Branding = "branding",
    IndexedLocations = "indexed-locations",
    DownloadLoactions = "download-locations",
    Tags = "tags",
    Uploader = "uploader",
    AirArchive = "air-archive",
    PublicAPI = "public-api",
    Preview = "preview",
    Sharing = "sharing",
}

export type CrossTabMessage = {
    windowID: number;
    message: string | null;
    isOk: boolean;
};

export enum CrossTabId {
    AddServiceAccount,
}
