import {
    DataGrid,
    DataGridHeader,
    DataGridRow,
    DataGridHeaderCell,
    DataGridBody,
    DataGridCell,
    Button,
    Switch,
    TableCellActions,
    TableCellLayout,
    createTableColumn,
    Text,
} from "@fluentui/react-components";
import React, { useEffect, useMemo, useState } from "react";
import { AdminActiveMenuKind } from "../../../../../models";
import { router } from "../../../../../router";
import { state } from "../../../../../state/stateAdapter";
import AdminLayout from "../../AdminLayout/AdminLayout";
import { EditRegular, DeleteRegular, AddRegular } from "@fluentui/react-icons";
import EditIgnoredPath from "../EditIgnoredPath/EditIgnoredPath";
import {
    ConfigKey,
    ConfigUploaderIgnoredPath,
    UploaderConfig,
} from "../../../../../../application/config/config";
import Loader from "../../../../../components/Loader/Loader";
import { useStorage } from "../../../../../../application/useCases/useStorage";
import { useAdmin } from "../../../../../../application/useCases/useAdmin";
import { uploaderSettingsState } from "../uploaderSettingsState";

function IgnoredPathsSettings(): JSX.Element {
    const uploaderConfig = state.useState(useStorage.config.config).features
        .uploader;
    const [tempUploaderSettings, setTempUploaderSettings] = useState(
        state.unwrap(useStorage.config.config).features.uploader,
    );

    const isGetConfigQueryInProgress = state.useState(
        useStorage.config.isQueryInProgress,
    );
    const isUpdateConfigQueryInProgress = state.useState(
        useStorage.admin.isUpdateConfigQueryInProgress,
    );

    const columns = useMemo(
        () => [
            createTableColumn<ConfigUploaderIgnoredPath>({
                columnId: "label",
                renderHeaderCell: () => {
                    return "Label";
                },
                renderCell: item => {
                    return (
                        <>
                            <TableCellLayout truncate>
                                <Text
                                    truncate
                                    block
                                    wrap={false}
                                    title={item.label}
                                >
                                    {item.label}
                                </Text>
                            </TableCellLayout>

                            <TableCellActions>
                                <Button
                                    icon={<EditRegular />}
                                    appearance="subtle"
                                    title="Edit"
                                    onClick={(): void => {
                                        state.appDialogPanel.set(() => (
                                            <EditIgnoredPath item={item} />
                                        ));
                                    }}
                                />
                                <Button
                                    title="Remove"
                                    icon={<DeleteRegular />}
                                    appearance="subtle"
                                    onClick={(): void => {
                                        tempUploaderSettings.ignoredPaths =
                                            tempUploaderSettings.ignoredPaths.filter(
                                                i => i.label !== item.label,
                                            );

                                        useAdmin
                                            .updateConfigByKey<UploaderConfig>(
                                                ConfigKey.Uploader,
                                                tempUploaderSettings,
                                            )
                                            .then(r => {
                                                if (r) {
                                                    useStorage.config.config.update(
                                                        s => {
                                                            if (s) {
                                                                s.features.uploader =
                                                                    JSON.parse(
                                                                        r.propertiesString,
                                                                    );
                                                            }
                                                        },
                                                    );
                                                }
                                            });

                                        const tempSettings =
                                            uploaderSettingsState.tempUploaderSettings.get() ||
                                            tempUploaderSettings;
                                        uploaderSettingsState.tempUploaderSettings.set(
                                            {
                                                ...tempSettings,
                                                ignoredPaths:
                                                    tempUploaderSettings.ignoredPaths,
                                            },
                                        );
                                    }}
                                />
                            </TableCellActions>
                        </>
                    );
                },
            }),
            createTableColumn<ConfigUploaderIgnoredPath>({
                columnId: "template",
                renderHeaderCell: () => {
                    return "Template";
                },
                renderCell: item => {
                    return (
                        <TableCellLayout truncate>
                            <Text
                                truncate
                                block
                                wrap={false}
                                title={item.template}
                            >
                                {item.template}
                            </Text>
                        </TableCellLayout>
                    );
                },
            }),
            createTableColumn<ConfigUploaderIgnoredPath>({
                columnId: "enabled",
                renderHeaderCell: () => {
                    return "Enabled";
                },
                renderCell: item => {
                    return (
                        <TableCellLayout>
                            <Switch
                                checked={item.isEnabled}
                                onChange={(_ev, data): void => {
                                    const newSettings = state.unwrap(
                                        useStorage.config.config,
                                    ).features.uploader;

                                    const itemIndex =
                                        newSettings.ignoredPaths.findIndex(
                                            i =>
                                                i.label === item.label &&
                                                i.template === i.template,
                                        );

                                    if (itemIndex !== -1) {
                                        newSettings.ignoredPaths[
                                            itemIndex
                                        ].isEnabled = data.checked;
                                    }

                                    useAdmin
                                        .updateConfigByKey<UploaderConfig>(
                                            ConfigKey.Uploader,
                                            newSettings,
                                        )
                                        .then(r => {
                                            if (r) {
                                                useStorage.config.config.update(
                                                    s => {
                                                        if (s) {
                                                            s.features.uploader =
                                                                JSON.parse(
                                                                    r.propertiesString,
                                                                );

                                                            setTempUploaderSettings(
                                                                {
                                                                    ...newSettings,
                                                                },
                                                            );
                                                        }
                                                    },
                                                );
                                            }
                                        });
                                }}
                            />
                        </TableCellLayout>
                    );
                },
            }),
        ],
        [tempUploaderSettings],
    );

    useEffect(() => {
        setTempUploaderSettings(
            state.unwrap(useStorage.config.config).features.uploader,
        );
    }, [uploaderConfig]);

    if (
        isGetConfigQueryInProgress ||
        isUpdateConfigQueryInProgress ||
        tempUploaderSettings === null
    ) {
        return (
            <div className="admin__loader">
                <Loader text="Loading..." />
            </div>
        );
    }

    return (
        <AdminLayout
            title={"Ignored paths"}
            backLink={{
                title: "Back to Uploader",
                href: router.createRoute({
                    search: {
                        items: [
                            {
                                key: "activeMenu",
                                value: AdminActiveMenuKind.Uploader,
                            },
                        ],
                        protect: [],
                    },
                }),
            }}
            buttons={[
                {
                    title: "Create new",
                    onClick: (): void => {
                        state.appDialogPanel.set(() => (
                            <EditIgnoredPath item={null} />
                        ));
                    },
                    icon: <AddRegular />,
                    appearance: "primary",
                },
            ]}
        >
            {tempUploaderSettings.ignoredPaths.length === 0 ? (
                <div>
                    <Text>No ignored paths</Text>
                </div>
            ) : (
                <DataGrid
                    items={tempUploaderSettings.ignoredPaths}
                    columns={columns}
                >
                    <DataGridHeader>
                        <DataGridRow>
                            {({ renderHeaderCell }): JSX.Element => (
                                <DataGridHeaderCell>
                                    {renderHeaderCell()}
                                </DataGridHeaderCell>
                            )}
                        </DataGridRow>
                    </DataGridHeader>
                    <DataGridBody<ConfigUploaderIgnoredPath>>
                        {({ item, rowId }): JSX.Element => (
                            <DataGridRow<ConfigUploaderIgnoredPath> key={rowId}>
                                {({ renderCell }): JSX.Element => (
                                    <DataGridCell>
                                        {renderCell(item)}
                                    </DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                    </DataGridBody>
                </DataGrid>
            )}
        </AdminLayout>
    );
}

export default IgnoredPathsSettings;
