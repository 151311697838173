import React, { useCallback, useEffect, useState } from "react";
import {
    Text,
    useTableFeatures,
    Table,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    createTableColumn,
    TableCellLayout,
    Button,
    Toolbar,
    ToolbarButton,
    ToolbarGroup,
    makeStyles,
    tokens,
    Link,
    Tooltip,
    ProgressBar,
    Caption1,
} from "@fluentui/react-components";
import { useCommonFluentuiStyles } from "../../../styles/griffel";
import {
    JobStatus,
    JobType,
    Job,
    JobDetails,
    jobStatusMap,
} from "../../../../domain/job/job";
import { onDownloadFilesByLink } from "../../../utility/utility";
import { state } from "../../../state/stateAdapter";
import { useStorage } from "../../../../application/useCases/useStorage";
import {
    ArrowDownloadRegular,
    ArrowResetRegular,
    ArrowSyncCircleRegular,
    CheckmarkCircleRegular,
    ClockArrowDownloadRegular,
    ClockPauseRegular,
    DismissCircleRegular,
    SquareArrowForwardRegular,
} from "@fluentui/react-icons";
import { useJobs } from "../../../../application/useCases/useJobs";
import { formatDate } from "../../../utility/formatters";
import "./jobs.css";
import Loader from "../../../components/Loader/Loader";
import Pagination from "../../../components/Pagination/Pagination";
import { calculateOffset, formatSize } from "../../../../utility";
import { Jobs } from "../../../../application/jobs/jobsPort";
import { Option } from "../../../../domain/types/types";
import {
    notifyUserAboutNewCompletedJobs,
    updateLastSync,
} from "../../shared/jobs";

const useStyles = makeStyles({
    job: {
        boxShadow: tokens.shadow8,
    },
    entity: {
        color: tokens.colorBrandBackground,
    },
    icon: {
        color: tokens.colorPalettePeachBorderActive,
        fontSize: "21px",
    },
    iconDefault: { color: tokens.colorPaletteRedBorderActive },
    iconError: { color: tokens.colorPaletteRedBorderActive },
    iconSuccess: { color: tokens.colorPaletteGreenBorderActive },
});

function getJobStatusIcon(status: string): JSX.Element | null {
    switch (status) {
        case JobStatus.Enqueued:
            return <ClockArrowDownloadRegular />;
        case JobStatus.PreProcessing:
        case JobStatus.InProgress:
            return <ArrowSyncCircleRegular />;
        case JobStatus.Completed:
            return <CheckmarkCircleRegular />;
        case JobStatus.Pending:
            return <ClockPauseRegular />;
        case JobStatus.Error:
        case JobStatus.Failed:
            return <DismissCircleRegular />;
        default:
            return null;
    }
}

function renderJobTypeTitle(jobType: string): string {
    switch (jobType) {
        case JobType.ReHydration:
            return "Rehydrating files";
        case JobType.RePermission:
            return "Re-permission files";
        case JobType.DownloadByLink:
            return "Restoring files by link";
        default:
            return "Restoring files";
    }
}

function getPercentComplete(job: Job): number | undefined {
    if (
        job.status === JobStatus.Pending ||
        job.status === JobStatus.PreProcessing ||
        job.status === JobStatus.Enqueued
    ) {
        return;
    }

    if (
        job.status === JobStatus.Error ||
        job.status === JobStatus.Failed ||
        !job.details
    ) {
        return 0.05;
    }

    if (
        (job.jobType === JobType.RePermission &&
            job.status === JobStatus.Completed) ||
        job.jobType !== JobType.ReHydration
    ) {
        return job.details.filesCompleted / job.details.filesTotal;
    }

    if (job.details.sizeCompleted > 0 && job.details.sizeTotal > 0) {
        return job.details.sizeCompleted / job.details.sizeTotal;
    }

    return 0;
}

function calculateEta(job: Job): string | null {
    if (
        job.status === JobStatus.InProgress &&
        job.dateCreated &&
        job.details &&
        job.details.filesCompleted &&
        job.details.filesTotal
    ) {
        const elapsedTime =
            (Date.now() - new Date(job.dateCreated).getTime()) / 1000;
        const averageTimePerFile = elapsedTime / job.details.filesCompleted;
        const filesRemaining =
            job.details.filesTotal - job.details.filesCompleted;
        const eta = averageTimePerFile * filesRemaining;

        const etaMinutes = Math.floor(eta / 60);
        const etaSeconds = Math.floor(eta % 60);

        if (etaMinutes === 0 && etaSeconds === 0) {
            return "About a minute";
        }

        return `${etaMinutes}m ${etaSeconds}s`;
    }

    return null;
}

function formatMessage(details: JobDetails): string {
    if (details.sizeTotal === 0) {
        return "";
    }

    const result = `${details.filesCompleted} of ${details.filesTotal} files (${
        formatSize(details.sizeCompleted) || 0
    } of ${formatSize(details.sizeTotal)}) completed`;

    if (details.avgSpeed > 0) {
        return `${result} - ${formatSize(details.avgSpeed)}/s`;
    }

    return result;
}

function getProgressBarColor(
    job: Job,
): "brand" | "success" | "warning" | "error" {
    if (job.status === JobStatus.Error || job.status === JobStatus.Failed) {
        return "error";
    }

    if (job.status === JobStatus.Completed) {
        return "success";
    }

    return "brand";
}

function Title({ item }: { item: Job }): JSX.Element {
    const classes = useStyles();

    return (
        <>
            <TableCellLayout
                truncate
                className="cell-layout jobs-table__title"
                media={getJobStatusIcon(item.status)}
            >
                <Text block>
                    {renderJobTypeTitle(item.jobType)}
                    {item.source && item.source.title && (
                        <>
                            <span> from </span>
                            <span className={classes.entity}>
                                {item.source.title}
                            </span>
                        </>
                    )}
                    <span> to </span>
                    {item.destination && item.destination.title ? (
                        <span className={classes.entity}>
                            {item.destination.title}
                        </span>
                    ) : (
                        <span className={classes.entity}>desktop</span>
                    )}
                </Text>

                <div className="cell-action">
                    {item.jobType === JobType.DownloadByLink ? (
                        <Button
                            appearance="subtle"
                            title={
                                item.status !== JobStatus.Completed
                                    ? "job is not completed yet"
                                    : "download"
                            }
                            disabled={item.status !== JobStatus.Completed}
                            onClick={(): Promise<void> =>
                                onDownloadFilesByLink(item)
                            }
                            icon={<ArrowDownloadRegular />}
                        />
                    ) : null}

                    {item.url && (
                        <Link
                            href={item.url}
                            target="_blank"
                            rel="noreferrer"
                            as="a"
                        >
                            <SquareArrowForwardRegular />
                        </Link>
                    )}
                </div>
            </TableCellLayout>
        </>
    );
}

const columns = [
    createTableColumn<Job>({
        columnId: "Title",
        compare: (a, b): number => {
            if (a.title === null || b.title === null) {
                return 0;
            }

            return a.title.localeCompare(b.title);
        },
        renderHeaderCell: () => (
            <TableCellLayout>
                <Text>Title</Text>
            </TableCellLayout>
        ),
        renderCell: item => {
            return <Title item={item} />;
        },
    }),
    createTableColumn<Job>({
        columnId: "Status",
        compare: (a, b): number => {
            if (a.title === null || b.title === null) {
                return 0;
            }

            return a.title.localeCompare(b.title);
        },
        renderHeaderCell: () => (
            <TableCellLayout>
                <Text>Status</Text>
            </TableCellLayout>
        ),
        renderCell: item => {
            const eta = calculateEta(item);

            return (
                <TableCellLayout truncate className="cell-layout">
                    <div className="jobs__status-cell">
                        <Text>{jobStatusMap[item.status]}</Text>

                        <div>
                            <ProgressBar
                                style={{ marginBottom: "4px" }}
                                value={getPercentComplete(item)}
                                color={getProgressBarColor(item)}
                            />

                            <div className="jobs__prgogress-info">
                                {item.jobType !== JobType.ReHydration &&
                                    item.jobType !== JobType.RePermission &&
                                    item.details && (
                                        <Caption1 className="job__details txt_muted">
                                            {formatMessage(item.details)}
                                        </Caption1>
                                    )}

                                {eta !== null ? (
                                    <Caption1 className="txt_muted">
                                        ETA: {eta}
                                    </Caption1>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </TableCellLayout>
            );
        },
    }),
    createTableColumn<Job>({
        columnId: "Message",
        compare: (a, b): number => {
            if (a.title === null || b.title === null) {
                return 0;
            }

            return a.title.localeCompare(b.title);
        },
        renderHeaderCell: () => (
            <TableCellLayout>
                <Text>Message</Text>
            </TableCellLayout>
        ),
        renderCell: item => {
            if (item.message.length < 60) {
                return <Text>{item.message}</Text>;
            }

            return (
                <Tooltip
                    content={
                        <div className="media-tooltip">
                            <Text>{item.message}</Text>
                        </div>
                    }
                    showDelay={1000}
                    relationship="description"
                >
                    <div className="jobs__message-container">
                        <Text className="jobs__message">{item.message}</Text>
                    </div>
                </Tooltip>
            );
        },
    }),
    createTableColumn<Job>({
        columnId: "Created",
        compare: (a, b): number => {
            if (a.title === null || b.title === null) {
                return 0;
            }

            return a.title.localeCompare(b.title);
        },
        renderHeaderCell: () => (
            <TableCellLayout>
                <Text>Created</Text>
            </TableCellLayout>
        ),
        renderCell: item => {
            return (
                <TableCellLayout truncate className="cell-layout">
                    {item.dateCreated && (
                        <Text>{formatDate(item.dateCreated)}</Text>
                    )}
                </TableCellLayout>
            );
        },
    }),
];

async function onRefresh(): Promise<void> {
    const { pageSize, page } = useStorage.jobs.dataParams.get();

    const jobs = await useJobs.fetchAll({
        limit: pageSize,
        offset: calculateOffset(page, pageSize),
    });

    updateLastSync();

    if (jobs !== undefined && jobs !== null) {
        checkNewCompletedJobs(jobs);
    }
}

function JobsCommandBar(): JSX.Element {
    const isAllJobsQueryInProgress = state.useState(
        useStorage.jobs.isAllJobsQueryInProgress,
    );

    return (
        <>
            <Toolbar aria-label="Jobs Command bar">
                <ToolbarGroup className="command-bar__main-container">
                    <ToolbarButton
                        aria-label="Refresh jobs"
                        appearance="subtle"
                        icon={<ArrowResetRegular />}
                        onClick={onRefresh}
                        disabled={isAllJobsQueryInProgress}
                    >
                        Refresh
                    </ToolbarButton>
                </ToolbarGroup>
            </Toolbar>
        </>
    );
}

const columnSizes: Record<string, number> = {
    Title: 500,
    Status: 300,
    Message: 400,
    Created: 240,
};

async function checkNewCompletedJobs(jobs: Jobs): Promise<void> {
    const viewResponse = await useJobs.view(
        jobs.results,
        jobs.newCompletedJobsIds,
    );

    if (viewResponse !== null && viewResponse !== undefined) {
        notifyUserAboutNewCompletedJobs(viewResponse.countNewCompletedJobs);
    } else {
        notifyUserAboutNewCompletedJobs(jobs.newCompletedJobsIds.length);
    }
}

function JobsTable(): JSX.Element {
    const allJobs = state.useState(useStorage.jobs.allJobs);
    const isAllJobsQueryInProgress = state.useState(
        useStorage.jobs.isAllJobsQueryInProgress,
    );
    const [isInit, setIsInit] = useState(false);
    const { pageSize, page } = state.useState(useStorage.jobs.dataParams);

    const commonClasses = useCommonFluentuiStyles();
    const pageSizes = [10, 25, 50, 100];

    const {
        getRows,
        sort: { sort },
    } = useTableFeatures(
        {
            columns: columns,
            items: allJobs.results,
        },
        [],
    );

    const rows = sort(
        getRows(row => {
            return {
                ...row,
            };
        }),
    );

    const onPrevPage = (): void => {
        if (page > 1) {
            useStorage.jobs.dataParams.update(s => {
                s.page -= 1;
            });
        }
    };

    const onNextPage = (total: number): void => {
        if (page < total) {
            useStorage.jobs.dataParams.update(s => {
                s.page += 1;
            });
        }
    };

    const onPageChange = (page: number): void => {
        useStorage.jobs.dataParams.update(s => {
            s.page = page;
        });
    };

    const onPageSizeChange = (pageSize: number): void => {
        useStorage.jobs.dataParams.update(s => {
            s.page = 1;
            s.pageSize = pageSize;
        });
    };

    const updateJobs = useCallback(async (): Promise<Option<Jobs>> => {
        const jobs = await useJobs.fetchAll({
            limit: pageSize,
            offset: calculateOffset(page, pageSize),
        });
        updateLastSync();
        setIsInit(true);

        return jobs;
    }, [page, pageSize]);

    useEffect(() => {
        updateJobs().then(jobs => {
            if (jobs !== undefined && jobs !== null) {
                checkNewCompletedJobs(jobs);
            }
        });
    }, [updateJobs]);

    const total = Math.ceil(allJobs.total / pageSize);

    if (isAllJobsQueryInProgress || !isInit) {
        return <Loader text="Fetching jobs..." />;
    }

    if (allJobs.total === 0) {
        return (
            <div className="layout__container">
                <Text>No jobs found</Text>
            </div>
        );
    }

    return (
        <>
            <div className="layout__container">
                <div className="table-container">
                    <Table
                        sortable
                        aria-label="user file table"
                        className="table"
                    >
                        <TableHeader className={commonClasses.tableHeader}>
                            <TableRow>
                                {columns.map(i => (
                                    <TableHeaderCell
                                        key={`header-row-${i.columnId}`}
                                        style={
                                            columnSizes[i.columnId] !==
                                            undefined
                                                ? {
                                                      width: `${
                                                          columnSizes[
                                                              i.columnId
                                                          ]
                                                      }px`,
                                                  }
                                                : undefined
                                        }
                                    >
                                        {i.renderHeaderCell()}
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {rows.map(({ item }, rowIndex) => (
                                <TableRow
                                    key={`row-${rowIndex}-${item.id}`}
                                    appearance="none"
                                    className={commonClasses.tableRow}
                                >
                                    {columns.map((column, columnIndex) => (
                                        <TableCell
                                            key={`cell-${columnIndex}-${item.id}`}
                                        >
                                            {column.renderCell(item)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <footer className="layout__container">
                <Pagination
                    total={total}
                    page={page}
                    pageSizes={pageSizes}
                    pageSize={pageSize}
                    onPageSizeChange={onPageSizeChange}
                    onPageChange={onPageChange}
                    onPrevPage={onPrevPage}
                    onNextPage={onNextPage}
                />
            </footer>
        </>
    );
}

function LastSync(): JSX.Element {
    const lastSync = state.useState(state.jobsMiniApp.lastSyncState);

    if (lastSync.length === 0) {
        return <></>;
    }

    return (
        <Text className="txt_muted jobs__sync-txt">Last sync: {lastSync}</Text>
    );
}

function JobsHeader(): JSX.Element {
    return (
        <header className="layout__container jobs__header">
            <LastSync />
        </header>
    );
}

function Jobs(): JSX.Element {
    // INIT COMMANDBAR ACTIONS
    useEffect(() => {
        state.commandBar.actionsFactory.set(() => <JobsCommandBar />);

        return () => {
            state.commandBar.actionsFactory.set(null);
        };
    }, []);

    return (
        <div className="layout">
            <JobsHeader />

            <JobsTable />
        </div>
    );
}

export default Jobs;
